import React from 'react'
import { getIconBuzzColor } from 'Helpers'
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom'
import { Map, MapPopup, MapToolTip } from 'Components'
import './ProspectActivityPage.scss'
import {
  ProspectFinderSelection,
  MapMarker,
  ProspectActivitySplash,
} from './components'
import {
  AreaSearchLens,
  ComparableListingsLens,
  FindYourProspectsLens,
  DigitalCanvassingLens,
  MoverLifecycleLens,
  MyListingsLens,
} from './lenses'
import { LensSidebar } from './lenses/components'

export const ProspectActivityPage = () => {
  const { path } = useRouteMatch()

  return (
    <div className="ProspectActivityPage">
      <Switch>
        <Route exact path={path}>
          <LensSidebar>
            <ProspectFinderSelection />
          </LensSidebar>
          <div className="theMap">
            <div className="theMap__splash-screen">
              <ProspectActivitySplash />
            </div>
            <Map
              features={[]}
              locations={[]}
              marker={(f) => <MapMarker color={getIconBuzzColor(f)} />}
              popup={(f) => <MapPopup feature={f} />}
              tooltip={(f) => <MapToolTip feature={f} />}
            />
          </div>
        </Route>
        <Route path={`${path}/my-listings`}>
          <MyListingsLens />
        </Route>
        <Route path={`${path}/area-search`}>
          <AreaSearchLens />
        </Route>
        <Route path={`${path}/comparable-listings`}>
          <ComparableListingsLens />
        </Route>
        <Route path={`${path}/find-your-prospects`}>
          <FindYourProspectsLens />
        </Route>
        <Route path={`${path}/digital-canvassing`}>
          <DigitalCanvassingLens />
        </Route>
        <Route path={`${path}/mover-lifecycle`}>
          <MoverLifecycleLens />
        </Route>
        <Route>
          <Redirect to="/404" />
        </Route>
      </Switch>
    </div>
  )
}
