import React, { useEffect, useRef, useState } from 'react'
import { renderToString } from 'react-dom/server'
import 'leaflet/dist/leaflet.css'
import './Map.scss'
import L from 'leaflet'
import 'leaflet-providers'
import { APIFeatureType, MapLocationType } from 'types'
import { getBounds } from 'Helpers'
import { Button } from '@eltoro-ui/components'

const center: L.LatLngExpression = [28.538336, -81.379234]

export const Map: React.FC<{
  tileProvider?: string
  features: APIFeatureType[]
  locations: MapLocationType[]
  marker: (f: APIFeatureType) => JSX.Element
  popup: (f: APIFeatureType) => JSX.Element
  tooltip: (f: APIFeatureType) => JSX.Element
}> = ({ tileProvider, features, locations, marker, popup, tooltip }) => {
  const [bounds, setBounds] = useState(L.latLngBounds([0, 0], [0, 0]))

  // init map
  const mapRef = useRef<L.Map>()
  useEffect(() => {
    const provider = tileProvider || 'CartoDB.VoyagerLabelsUnder'
    mapRef.current = L.map('leaflet-map').setView(center, 13)
    L.tileLayer.provider(provider).addTo(mapRef.current)
    // When Dragging maps, close all open popups
    mapRef.current.on('dragstart', () => mapRef.current?.closePopup())
  }, [])

  // init layer
  const layerRef = useRef<any>()
  useEffect(() => {
    if (mapRef.current) {
      layerRef.current = L.layerGroup().addTo(mapRef.current)
    }
  }, [])

  // if there are locations, set bounds
  useEffect(() => {
    if (locations.length > 0) {
      const locationsBounds = {
        lat: locations.reduce((acc: number[], location) => {
          const north = parseFloat(location.bounds[0][0])
          const south = parseFloat(location.bounds[1][0])
          return [...acc, north, south]
        }, []),
        long: locations.reduce((acc: number[], location) => {
          const east = parseFloat(location.bounds[0][1])
          const west = parseFloat(location.bounds[1][1])
          return [...acc, east, west]
        }, []),
      }
      setBounds(getBounds(locationsBounds.lat, locationsBounds.long))
    }
  }, [locations])

  // add features to layer
  useEffect(() => {
    layerRef.current?.clearLayers()
    if (layerRef.current) {
      features?.forEach((feature) => {
        const featureToolTip = (currentFeatureMarker: L.Marker) => {
          return currentFeatureMarker.bindTooltip(
            renderToString(tooltip(feature)),
            { direction: 'top', offset: [4, -27] },
          )
        }
        const featureMarker = L.marker([feature.latitude, feature.longitude], {
          icon: L.divIcon({
            html: renderToString(marker(feature)),
          }),
          title: feature.street,
        })
          .bindPopup(renderToString(popup(feature)))
          .addTo(layerRef.current)
        featureMarker.on('mouseover', () =>
          featureToolTip(featureMarker).openTooltip(),
        )
        featureMarker.on('click', () => featureMarker.unbindTooltip())
      })

      // if there are features, set bounds
      if (features.length > 0) {
        const featureBounds = {
          lats: features.map((feature) => feature.latitude),
          longs: features.map((feature) => feature.longitude),
        }
        setBounds(getBounds(featureBounds.lats, featureBounds.longs))
      }
    }
  }, [features])

  // fit map to bounds when bounds are updated
  const fitMapToBounds = () => {
    if (mapRef.current) {
      mapRef.current.fitBounds(bounds)
    }
  }

  // fit to bounds when bounds are updated via location/feature changes
  useEffect(() => {
    if (features.length > 0 || locations.length > 0) {
      fitMapToBounds()
    }
  }, [bounds])

  return (
    <div className="Map" id="leaflet-map">
      {features.length > 0 && (
        <div className="Map__fit-to-bounds-button">
          <Button onClick={fitMapToBounds} rounded>
            Zoom to results
          </Button>
        </div>
      )}
    </div>
  )
}
