import React from 'react'
import { Table, Text } from '@eltoro-ui/components'
import './AttachedCreatives.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImages, faVideo, faEnvelope } from '@fortawesome/pro-solid-svg-icons'
import { AttachedCreativeType } from 'types'
import { ClickthroughInput, CreativeThumb } from './_components'
import { creatives } from './sampleCreatives'

const creativeTypes = {
  banner: {
    name: 'Digital Banner',
    icon: faImages,
  },
  video: {
    name: 'Video',
    icon: faVideo,
  },
  directMail: {
    name: 'Direct Mail',
    icon: faEnvelope,
  },
}

export const AttachedCreatives = () => {
  return (
    <div className="AttachedCreatives">
      <Text tag="div" on="white" size="xxl" weight="bold">
        Your Creatives
      </Text>
      <Text tag="div" on="white" size="s">
        These are your creatives. Please add a clickthrough URL if applicable.
      </Text>
      <Table
        rows={creatives}
        columns={[
          {
            path: 'src',
            label: 'Image',
            RowCell: (creative: AttachedCreativeType) => {
              return <CreativeThumb src={creative.src} alt={creative.name} />
            },
          },
          { path: 'name', label: 'Creative Name' },
          {
            path: 'type',
            label: 'Type',
            RowCell: (creative: AttachedCreativeType) => {
              const type = creativeTypes[creative.type]
              return (
                <div>
                  <FontAwesomeIcon
                    icon={type.icon}
                    className="AttachedCreatives__icon"
                  />
                  {type.name}
                </div>
              )
            },
          },
          {
            path: 'clickthrough',
            label: 'Clickthrough URL',
            RowCell: (creative) =>
              creative.type !== 'directMail' && <ClickthroughInput value="" />,
          },
        ]}
      />
    </div>
  )
}
