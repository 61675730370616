import React, { useState, useCallback, useEffect } from 'react'
import ReactDOM from 'react-dom'
import {
  Button,
  MaxHeightContainer,
  Spacer,
  Checkbox,
  FlexBox,
} from '@eltoro-ui/components'
import { useLocations } from 'Hooks'
import { getMapListings } from 'Requests'
import { getIconBuzzColor, removeKeyFromObject } from 'Helpers'
import { APIDataType, APIFeatureType, ProspectFilterType } from 'types'
import { Map, MapPopup, MapSearch, MapToolTip } from 'Components'
import { browserLog } from 'Tools'
import { filterFixtures } from 'Requests/Fixtures/findYourProspects'
import {
  AudienceResultsCard,
  AudienceResultsCount,
  LocationLabels,
  MapMarker,
  SaveAudienceWizard,
} from 'Pages/ProspectActivity/components'
import { faWalking } from '@fortawesome/free-solid-svg-icons'
import { ProspectsFilter } from '../../components/FindYourProspectsComponents'
import {
  LensFilterContainer,
  LensSectionHeading,
  LensSidebar,
  LensSidebarFooter,
  LensSidebarHeader,
} from '../components'
import './FindYourProspectsLens.scss'

const defaultFilters = ['price', 'beds', 'baths', 'size'].reduce(
  (acc: { [key: string]: ProspectFilterType }, curr: string) => {
    acc[curr] = filterFixtures[curr]
    return acc
  },
  {},
)

export const FindYourProspectsLens = () => {
  const {
    locations,
    addLocation,
    removeLocation,
    hasLocations,
  } = useLocations()
  const [features, setFeatures] = useState<APIFeatureType[]>([])
  const [showFilters, setShowFilters] = useState(false)
  const [filters, setFilters] = useState<{
    [key: string]: ProspectFilterType
  }>(defaultFilters)

  // Filters dropdown positioning
  const [searchRect, setSearchRect] = useState<DOMRect>()
  const measuredFiltersRef = useCallback(
    (node: HTMLButtonElement) => {
      if (node !== null) {
        setSearchRect(node.getBoundingClientRect())
      }
    },
    [showFilters],
  )

  // used for onChange in Filter component
  const updateFilters = (filter: { [key: string]: ProspectFilterType }) => {
    setFilters((prev) => ({
      ...prev,
      ...filter,
    }))
  }

  // add or remove filter object
  const handleFilterCheck = (key: string, checked: boolean) => {
    if (checked) {
      setFilters((prev) => ({
        ...prev,
        [key]: filterFixtures[key],
      }))
    } else {
      setFilters((prev) => removeKeyFromObject(key, prev))
    }
  }

  const isCheckedFilter = (k: string) =>
    Object.keys(filters).some((filter: string) => filter === k)

  useEffect(() => {
    if (hasLocations) {
      getMapListings(locations).then((res: APIDataType) => {
        setFeatures(res.data.listings)
      })
    } else {
      setFeatures([])
    }
  }, [hasLocations, locations])

  const totalTargets = features.reduce(
    (total, listing) => total + listing.unique_observations,
    0,
  )

  return (
    <div className="FindYourProspectsLens">
      <LensSidebar>
        <MaxHeightContainer
          fullHeight
          header={
            <LensSidebarHeader
              heading="Find Your Prospects"
              subHeading="Use filters to view prospects seen in listings that match your criteria."
            />
          }
          footer={
            <LensSidebarFooter>
              <AudienceResultsCard>
                Potentially advertise to
                <AudienceResultsCount faIcon={faWalking}>
                  {`${totalTargets.toLocaleString()} targets`}
                </AudienceResultsCount>
                seen around <b>{features.length}</b> listings
                <Spacer />
                <SaveAudienceWizard
                  onSaveAndClose={(audienceDetails) =>
                    browserLog.info(audienceDetails)
                  }
                />
              </AudienceResultsCard>
            </LensSidebarFooter>
          }
        >
          <div className="FindYourProspectsLens__sidebar-content">
            <MapSearch onSelect={addLocation} />
            {hasLocations && (
              <>
                <Spacer height="2rem" />
                <LensSectionHeading
                  heading={`Locations: ${locations.length}`}
                />
                <LocationLabels
                  locations={locations}
                  onRemove={removeLocation}
                />
                <Spacer height="2rem" />
                <LensSectionHeading heading="Filters:" />
                <FlexBox justifyContent="flex-end">
                  <Button
                    buttonRef={measuredFiltersRef}
                    kind="text"
                    onClick={() => setShowFilters(!showFilters)}
                  >
                    Select filters
                  </Button>
                </FlexBox>
                <Spacer height=".5rem" />
                <LensFilterContainer>
                  {Object.keys(filters).map((key: string) => (
                    <ProspectsFilter
                      key={filters[key].label}
                      filter={filters[key]}
                      onChange={updateFilters}
                    />
                  ))}
                </LensFilterContainer>
              </>
            )}
          </div>
        </MaxHeightContainer>
      </LensSidebar>
      <div className="FindYourProspectsLens__map">
        <Map
          features={features}
          locations={locations}
          marker={(f) => <MapMarker color={getIconBuzzColor(f)} />}
          popup={(f) => <MapPopup feature={f} />}
          tooltip={(f) => <MapToolTip feature={f} />}
        />
      </div>
      {showFilters &&
        ReactDOM.createPortal(
          <div
            className="FindYourProspectsLens__filter-selection"
            style={{
              top: `${searchRect?.bottom}px`,
              left: `${searchRect?.left}px`,
            }}
          >
            {Object.keys(filterFixtures).map((key: string) => (
              <Checkbox
                key={filterFixtures[key].label}
                checked={isCheckedFilter(key)}
                onChange={(checked) => handleFilterCheck(key, checked)}
                label={filterFixtures[key].label}
              />
            ))}
          </div>,
          document.body,
        )}
    </div>
  )
}
