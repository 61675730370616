import { AudienceType, getDataType, RequestType } from 'types'
import requests from '../http'

export const getAudiences = async (
  skip?: number | null,
  limit?: number | null,
): Promise<RequestType<getDataType<AudienceType[]>>> => {
  const req =
    skip && limit ? `/audiences/?skip=${skip}&limit=${limit}` : '/audiences'
  return requests.get(req)
}

export const getAudienceById = async (
  audience_id: string,
): Promise<RequestType<AudienceType>> => {
  return requests.get(`/audience/${audience_id}`)
}

export const updateAudience = async (
  audience_id: string,
  body: Partial<AudienceType>,
): Promise<RequestType<AudienceType>> => {
  return requests.patch(`/audience/${audience_id}`, body)
}

export const createAudience = async (
  body: Partial<AudienceType>,
): Promise<RequestType<AudienceType>> => requests.post(`/audience`, body)
