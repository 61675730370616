import {
  MyListings,
  DashboardPage,
  ProspectActivityPage,
  MyProfilePage,
  Login,
  MyContacts,
  CampaignDashboard,
  Campaigns,
  SavedAudiences,
  AudienceView,
  CampaignCreation,
  CampaignView,
  ListingView,
} from './Pages'

type RouteType = {
  path: string
  component: any
  public: boolean
}

const routes: RouteType[] = [
  { path: '/login', component: Login, public: true },
  { path: '/logout', component: Login, public: true },
  { path: '/dashboard', component: DashboardPage, public: false },
  { path: '/', component: DashboardPage, public: false },
  { path: '/contacts', component: MyContacts, public: false },
  { path: '/listings', component: MyListings, public: false },
  { path: '/listings/:listingId', component: ListingView, public: false },
  { path: '/prospects', component: ProspectActivityPage, public: false },
  { path: '/create-campaign', component: CampaignCreation, public: false },
  { path: '/campaign-dashboard', component: CampaignDashboard, public: false },
  { path: '/campaigns', component: Campaigns, public: false },
  { path: '/campaigns/:campaignId', component: CampaignView, public: false },
  { path: '/saved-audiences', component: SavedAudiences, public: false },
  {
    path: '/saved-audiences/:audienceId',
    component: AudienceView,
    public: false,
  },
  { path: '/profile', component: MyProfilePage, public: false },
]

export default routes
