import { setAuthHeader } from '../../Tools'
import requests from '../http'

export const getAuth = async (email: string, password: string) => {
  const auth = await requests.post('/token', {
    email,
    password,
  })
  setAuthHeader(auth?.data?.token)

  return auth
}

export const getNotifications = async () => requests.get(`/notifications`)
