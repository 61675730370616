import React, { FC, useState } from 'react'
import {
  Select,
  Button,
  Spacer,
  Text,
  SelectOptionType,
} from '@eltoro-ui/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fixturePaymentProfiles } from 'Requests/Fixtures'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { PaymentProfileType } from 'types'
import './SelectCard.scss'

type SelectCardType = {
  onCardSubmit: (profile: PaymentProfileType) => void
  onAddCard: () => void
}

export const SelectCard: FC<SelectCardType> = ({ onCardSubmit, onAddCard }) => {
  const [profileSelected, setProfileSelected] = useState<
    SelectOptionType | undefined
  >()

  const handleCardSubmit = () => {
    if (profileSelected) {
      // TODO: get payment profile
      const card = fixturePaymentProfiles.find(
        (profile) => profile.cc_details.nick_name === profileSelected.label,
      )
      if (card) onCardSubmit(card)
    }
  }

  return (
    <div className="SelectCard">
      <div className="SelectCard__options">
        <Button
          kind="primary"
          onClick={onAddCard}
          iconLeft={<FontAwesomeIcon icon={faPlus} />}
        >
          Add new card
        </Button>
        <Spacer />
        <Text on="white" kind="subdued" size="l">
          or
        </Text>
        <Spacer />
        <Select
          placeholder="Select card"
          selectedOption={profileSelected}
          options={fixturePaymentProfiles.map((profile) => {
            return { label: profile.cc_details.nick_name }
          })}
          onChange={(profile) => setProfileSelected(profile)}
          maxHeight={200}
        />
      </div>
      <Spacer />
      <div className="SelectCard__footer">
        <Button
          type="submit"
          kind="primary"
          onClick={() => handleCardSubmit()}
          disabled={!profileSelected}
        >
          Confirm
        </Button>
      </div>
    </div>
  )
}
