import React from 'react'
import { Button, FlexBox, GridBox, Text } from '@eltoro-ui/components'
import './UploadMailerModal.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/pro-solid-svg-icons'
import { Uploader } from 'Components/Uploader'
import { browserLog } from 'Tools'
import mailerPlaceholders from './MailerPlaceholders.json'
import { UploadAreaLayout, UploadTypes } from '../UploadAreaLayout'

export const UploadMailerModal = ({
  onClickOutside,
}: {
  onClickOutside: () => void
}) => {
  return (
    <div className="UploadMailerModal">
      <GridBox justifyItems="flex-start" gap="0.5rem">
        <Text tag="div" on="white" size="xxl">
          STEP 1: <b>Download Our Mailer Templates</b>
        </Text>
        <Text tag="div" on="white">
          <b>Notice: </b>Direct Mail pieces that do not meet{' '}
          <b>size, resolution, and legal</b> requirements will not print. <br />{' '}
          If you are at all concerned that your creative will not pass,{' '}
          <b>submit a support ticket</b> or use our <b>Ad Builder</b>
        </Text>
        <Button kind="text" iconLeft={<FontAwesomeIcon icon={faDownload} />}>
          Download Mailer Template
        </Button>
      </GridBox>
      <div className="UploadMailerModal__hl" />
      <GridBox gap="0.5rem">
        <Text tag="div" on="white" size="xxl">
          STEP 2: <b>Consider A Mailer Size</b>
        </Text>
        <GridBox gridTemplateColumns="1fr 1fr" gap="3rem">
          <FlexBox justifyContent="space-between" alignItems="flex-end">
            {mailerPlaceholders.map((mailer) => (
              <GridBox
                key={mailer.id}
                justifyItems="center"
                alignItems="center"
                gap="0.5rem"
              >
                <div
                  className="UploadMailerModal__creative-placeholder"
                  style={{ height: mailer.height, width: mailer.width }}
                />
                <Text tag="div" on="white">
                  <b>{mailer.actualDimensions}</b>
                </Text>
              </GridBox>
            ))}
          </FlexBox>
          <GridBox
            alignSelf="center"
            gridTemplateRows="repeat(3, min-content)"
            gap="1rem"
          >
            <Text on="white">
              <b>Bleed: </b>
              1/16&quot; (.0625&quot;)
            </Text>
            <Text on="white">
              <b>Margin: </b>
              1/8&quot; (.125&quot;)
            </Text>
            <Text on="white">
              <b>Resolution: </b>
              400ppi (minimum)
              <br />
              800ppi (preferred)
            </Text>
          </GridBox>
        </GridBox>
      </GridBox>
      <div className="UploadMailerModal__hl" />
      <GridBox gap="0.5rem">
        <Text tag="div" on="white" size="xxl">
          STEP 3: <b>Upload Your Mailer Creative</b>
        </Text>
        <GridBox gap="2rem" gridTemplateColumns="1fr 3fr">
          <GridBox gap="0.5rem" gridTemplateRows="min-content">
            <Text on="white" weight="bold">
              Examples
            </Text>
            <GridBox
              gap="0.5rem"
              gridTemplateRows="min-content auto min-content auto"
            >
              <Text on="white" size="s">
                Front
              </Text>
              <div className="UploadMailerModal__creative-placeholder" />
              <Text on="white" size="s">
                Back
              </Text>
              <div className="UploadMailerModal__creative-placeholder" />
            </GridBox>
          </GridBox>
          <GridBox gap="0.5rem" gridTemplateRows="min-content">
            <Text on="white" weight="bold">
              Upload
            </Text>
            <GridBox gap="0.5rem">
              <Text on="white" size="s">
                Front
              </Text>
              <Uploader
                type="banner"
                onDropFile={(files) => browserLog.info(files)}
              >
                <UploadAreaLayout uploadType={UploadTypes.mailerFront} />
              </Uploader>
              <Text on="white" size="s">
                Back
              </Text>
              <Uploader
                type="banner"
                onDropFile={(files) => browserLog.info(files)}
              >
                <UploadAreaLayout uploadType={UploadTypes.mailerBack} />
              </Uploader>
            </GridBox>
          </GridBox>
        </GridBox>
      </GridBox>
      <div className="UploadBannerModal__footer">
        <Button
          kind="text"
          UNSAFE_className="UploadBannerModal__cancel-button"
          onClick={onClickOutside}
        >
          Cancel
        </Button>
        <Button kind="primary">Confirm</Button>
      </div>
    </div>
  )
}
