import { dataType, RequestType, UserType } from 'types'
import requests from '../http'

export const getUser = async (): Promise<dataType<UserType>> =>
  requests.get(`/user`)

export const updateUser = async (
  body: Partial<UserType>,
): Promise<RequestType<UserType>> => requests.patch(`/user`, body)

export const createUser = async (
  body: UserType,
): Promise<RequestType<UserType>> => requests.post(`/user`, body)
