import React from 'react'
import { useParams } from 'react-router-dom'
import {
  PageHeader,
  CampaignDetails,
  Section,
  CompactSavedAudiences,
} from 'Components'
import { Button, Spacer } from '@eltoro-ui/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import './CampaignView.scss'
import { CampaignReport } from 'Components/CampaignReport'

export const CampaignView = () => {
  const { campaignId } = useParams<{ campaignId: string }>()

  return (
    <div className="CampaignView">
      <PageHeader
        title={campaignId}
        subTitle="Last Updated 11/04/2020 - 1:45 PM"
      />
      <div className="CampaignView__body">
        <div className="CampaignView__left-column">
          <CampaignDetails
            campaign={{
              _id: '5678',
              title: 'Campaign 2',
              prospects: 3000,
              created: '2/13/21 at 10:31AM',
              state: 'In Review',
              length: 14,
              days_in: 13,
              impressions: 392,
              budget: 120,
              spent: 70,
              user_id: '580fuej9320n1ji2552d',
              creatives: [],
              audiences: [],
            }}
          />
          <Spacer height=".15rem" />
          <Section
            title="Saved Audience"
            dark
            actions={[
              <Button iconLeft={<FontAwesomeIcon icon={faPlus} />}>
                Add Saved Audience
              </Button>,
            ]}
          >
            <CompactSavedAudiences />
          </Section>
        </div>
        <div className="CampaignView__right-column">
          <CampaignReport
            campaign={{
              _id: '5678',
              title: 'Campaign 2',
              prospects: 3000,
              created: '2/13/21 at 10:31AM',
              state: 'In Review',
              length: 14,
              days_in: 13,
              impressions: 392,
              budget: 120,
              spent: 70,
              user_id: '580fuej9320n1ji2552d',
              creatives: [],
              audiences: [],
            }}
          />
        </div>
      </div>
    </div>
  )
}
