import { ProspectFilterType } from 'types'

export const filterFixtures: { [key: string]: ProspectFilterType } = {
  price: {
    id: 'price',
    label: 'Current price',
    options: { min: 0, max: 5000000 },
  },
  beds: {
    id: 'beds',
    label: 'Number of bedrooms',
    options: [1, 2, 3, 4, 5],
  },
  baths: {
    id: 'baths',
    label: 'Number of bathrooms',
    options: [1, 2, 3, 4, 5],
  },
  size: {
    id: 'size',
    label: 'Square footage',
    options: { min: 100, max: 10000 },
  },
  homeType: {
    id: 'homeType',
    label: 'Property sub type',
    options: [
      'Houses',
      'Manufactured',
      'Condos/co-ops',
      'Multi-family',
      'Apartments',
      'Lots/Land',
      'Townhomes',
    ],
  },
  associationFee: {
    id: 'associationFee',
    label: 'Association fee requirement',
    options: false,
  },
  parking: {
    id: 'parking',
    label: 'Parking options',
    options: ['Street', 'Driveway', 'Alley'],
  },
  garage: {
    id: 'garage',
    label: 'Has garage',
    options: false,
  },
  cooling: {
    id: 'cooling',
    label: 'Cooling options',
    options: ['Central Air', 'Window unit'],
  },
  heating: {
    id: 'heating',
    label: 'Heating options',
    options: [
      'Central heat',
      'Floor heaters',
      'Radiators',
      'Wood burning heater',
      'boiler',
    ],
  },
  exterior: {
    id: 'exterior',
    label: 'Exterior features',
    options: ['Deck', 'Fenced in yard', 'Fire pit', 'Pond'],
  },
  interior: {
    id: 'interior',
    label: 'Interior features',
    options: ['Fireplace', 'Eat-in kitchen', 'Open concept'],
  },
  pool: {
    id: 'pool',
    label: 'Has pool',
    options: false,
  },
  appliances: {
    id: 'appliances',
    label: 'Appliances',
    options: ['Washer', 'Dryer', 'Oven', 'Refridgerator', 'Microwave'],
  },
  foundation: {
    id: 'foundation',
    label: 'Foundation',
    options: ['Concrete Slab', 'Gravel'],
  },
  pets: {
    id: 'pets',
    label: 'Pets allowed',
    options: ['Dogs', 'Cats', 'Fish', 'Exotic'],
  },
  daysOnMarket: {
    id: 'daysOnMarket',
    label: 'Days on market',
    options: ['1 Day', '2-5 Days', '6-15 Days', '16-30', '1+ Months'],
  },
}
