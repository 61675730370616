import { MapLocationType, APIDataType, APIFeatureType } from 'types'
import requests from '../http'

export const getListingById = async (
  mls_id: string,
): Promise<APIFeatureType> => {
  const res: any = await requests.get(`/listings/by-id/${mls_id}`)
  if (res.data) return res.data
  return res
}

export const getListings = async (
  skip?: number | null,
  limit?: number | null,
): Promise<APIFeatureType[] | {}> => {
  const req =
    skip && limit ? `/listings/?skip=${skip}&limit=${limit}` : '/listings'
  const res = await requests.get(req)
  if (res.data) return res.data
  return res
}

export const getMapListings = async (
  locations: MapLocationType[],
  skip: number | null = null,
  limit: number | null = null,
): Promise<APIDataType> =>
  locations.reduce(async (promiseChain: any, location: MapLocationType) => {
    // get bounds
    const south = parseFloat(location.bounds[0][0])
    const north = parseFloat(location.bounds[1][0])
    const east = parseFloat(location.bounds[1][1])
    const west = parseFloat(location.bounds[0][1])
    const geoParams = `/listings/layer?south=${south}&north=${north}&east=${east}&west=${west}`
    const paginationParams = `&skip=${skip}&limit=${limit}`
    const req = skip && limit ? geoParams + paginationParams : geoParams
    const features = await requests.get(req)
    return features
    // not sure what this was doing?
    // return promiseChain.then((chainResults: any) => [
    //   ...chainResults,
    //   ...features?.data?.listings,
    // ])
  }, Promise.resolve([]))
