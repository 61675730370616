import React, { useState } from 'react'
import './CampaignCreatives.scss'
import { faEnvelope, faImages, faVideo } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  FlexBox,
  GridBox,
  MaxHeightContainer,
  Spacer,
  Text,
} from '@eltoro-ui/components'
import { PageHeader, SummaryCart } from 'Components'
import { browserLog } from 'Tools'
import { fixtureAudiences } from 'Requests/Fixtures'
import { CampaignCreationFooter, CampaignCreationWrapper } from '..'
import {
  UploadOrBuild,
  CreativeTypeBadge,
  CreativeCampaignModal,
  AttachedCreatives,
} from './_components'

// sample campaign to give to summary cart
const campaign = {
  prospects: fixtureAudiences.map((audience) => ({
    name: audience.name,
    prospects: audience.prospects,
  })),
  creatives: {
    // video: [{ name: 'string', cost: 456, impressions: 768, households: 456 }],
    // banner: [
    //   { name: 'string', cost: 456, impressions: 768, households: 456 },
    // ],
    // directMail: {
    //   name: 'string',
    //   cost: 456,
    //   impressions: 768,
    //   households: 456,
    // },
  },
}

export const CampaignCreatives = () => {
  const [selectedCreativeType, setSelectedCreativeType] = useState<
    'banner' | 'directMail' | 'video' | null
  >(null)
  const [uploadModalOpen, setUploadModalOpen] = useState(false)

  return (
    <div className="CampaignCreatives">
      <MaxHeightContainer
        fullHeight
        header={<PageHeader title="Create Campaign" subTitle="Setup" />}
        footer={
          <CampaignCreationFooter>
            <Button size="l" onClick={() => browserLog.info('back')}>
              Back
            </Button>
            <div className="CampaignSetup__next-button">
              <Button
                size="l"
                kind="primary"
                onClick={() => browserLog.info('hi')}
                disabled={false}
              >
                Next
              </Button>
            </div>
          </CampaignCreationFooter>
        }
      >
        <CampaignCreationWrapper>
          <GridBox gap="1rem">
            {/* Conditionals to open modals for specific creative types */}
            {uploadModalOpen && (
              <CreativeCampaignModal
                onClickOutside={() => setUploadModalOpen(false)}
                type={selectedCreativeType}
              />
            )}
            <div className="CampaignCreatives__choose-creatives">
              <FlexBox justifyContent="space-between">
                <div>
                  <Text tag="div" on="white" size="xxl" weight="bold">
                    Choose your ad type
                  </Text>
                  <Text tag="div" on="white" size="s">
                    Your campaign needs style. Create digital banners, direct
                    mail pieces, or both! You may also upload your own files,
                    including audio and video.
                  </Text>
                </div>
                {selectedCreativeType !== null && (
                  <Button onClick={() => setSelectedCreativeType(null)}>
                    Back
                  </Button>
                )}
              </FlexBox>
              <div className="CampaignCreatives__types">
                {/* Renders the buttons for each creative type based on which one you click */}
                {selectedCreativeType !== 'banner' && (
                  <CreativeTypeBadge
                    onClick={() => setSelectedCreativeType('directMail')}
                    icon={<FontAwesomeIcon icon={faEnvelope} size="4x" />}
                    text="Direct Mail*"
                  />
                )}
                {selectedCreativeType !== 'directMail' && (
                  <CreativeTypeBadge
                    onClick={() => setSelectedCreativeType('banner')}
                    icon={<FontAwesomeIcon icon={faImages} size="4x" />}
                    text="Banner"
                  />
                )}
                {selectedCreativeType !== 'banner' &&
                  selectedCreativeType !== 'directMail' && (
                    <CreativeTypeBadge
                      onClick={() => setUploadModalOpen(true)}
                      icon={<FontAwesomeIcon icon={faVideo} size="4x" />}
                      text="Video"
                    />
                  )}
                {/* Renders the options to upload or use the ad builder when you click direct mail or banner */}
                {(selectedCreativeType === 'directMail' ||
                  selectedCreativeType === 'banner') && (
                  <div className="CampaignCreatives__upload">
                    <UploadOrBuild
                      onClickUpload={() => setUploadModalOpen(true)}
                      onClickBuild={() => browserLog.info('open ad builder')}
                    />
                  </div>
                )}
              </div>
              {/* Display disclaimer about direct mail when direct mail is selected or no selection has been made yet */}
              {(selectedCreativeType === 'directMail' ||
                selectedCreativeType === null) && (
                <>
                  <Spacer />
                  <Text on="white" size="s">
                    *The number of prospects may differ from the displayed
                    amount
                  </Text>
                </>
              )}
            </div>
            <AttachedCreatives />
          </GridBox>
          <SummaryCart campaign={campaign} />
        </CampaignCreationWrapper>
      </MaxHeightContainer>
    </div>
  )
}
