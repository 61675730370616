import React from 'react'
import './MapMarker.scss'

export const MapMarker: React.FC<{ color?: string }> = ({ color = 'red' }) => (
  <div className="MapMarker">
    <svg className="MapMarker__svg-icon" viewBox="0 0 424 552">
      <path
        fill={color}
        className="MapMarker__svg-marker-path"
        d="M192.27,521.67C46.97,311.03,20,289.41,20,212c0-106.04,85.96-192,192-192s192,85.96,192,192 c0,77.41-26.97,99.03-172.27,309.67C222.2,535.44,201.8,535.44,192.27,521.67L192.27,521.67z"
      />
      <circle className="MapMarker__svg-circle" cx="212" cy="212" r="80" />
    </svg>
  </div>
)
