import React, { useState } from 'react'
import { Modal, Text } from '@eltoro-ui/components'
import { PaymentProfileType } from 'types'
import { browserLog } from 'Tools'
import { creditBundles } from 'Requests/Fixtures/CreditBundles'
import { SelectBundle } from './SelectBundle'
import { PurchaseBundle } from './PurchaseBundle'
import './CreditModal.scss'

type CreditModalType = {
  offClick: () => void
  pointsNeeded?: number
}

export type CreditBundleType = {
  name: string
  stars: number
  credits: number
  price: number
  subText?: string
}

export const CreditModal: React.FC<CreditModalType> = ({
  offClick,
  pointsNeeded,
}) => {
  const [
    selectedBundle,
    setSelectedBundle,
  ] = useState<CreditBundleType | null>()

  const handleSubmitPayment = (profile: PaymentProfileType) => {
    // Logging payment details for now
    browserLog.log({ paymentProfile: profile, bundle: selectedBundle })
    offClick()
  }

  return (
    <Modal
      header={
        <div className="CreditModal__header">
          Buy Credits
          <Text on="white" kind="subdued" weight="light">
            Buy in bulk to save money
          </Text>
        </div>
      }
      offClick={offClick}
      className="CreditModal"
    >
      <div className="CreditModal__content">
        {selectedBundle ? (
          <PurchaseBundle
            bundle={selectedBundle}
            onPurchaseExit={() => setSelectedBundle(null)}
            onSubmitPayment={handleSubmitPayment}
          />
        ) : (
          <SelectBundle
            bundles={creditBundles}
            pointsNeeded={pointsNeeded}
            onChange={(bundle) => setSelectedBundle(bundle)}
          />
        )}
      </div>
    </Modal>
  )
}
