import React, { ReactNode } from 'react'
import { Text } from '@eltoro-ui/components'
import './TableSelectionWrapper.scss'

type TableSelectionWrapperType<T> = {
  children: ReactNode
  selectionType: string
  selections: T[]
  actions: ReactNode
}

export const TableSelectionWrapper = <T extends {}>({
  children,
  selectionType,
  selections,
  actions,
}: TableSelectionWrapperType<T>) => {
  return (
    <div className="TableSelectionWrapper">
      {selections.length > 0 ? (
        <div className="TableSelectionWrapper__selection-header">
          <Text on="tertiary-300" weight="bold">
            {`${selections.length} ${selectionType}${
              selections.length > 1 ? 's' : ''
            } selected`}
          </Text>
          <div className="TableSelectionWraper__actions">
            {Array.isArray(actions)
              ? actions.map((action, i) => (
                  <React.Fragment key={i}>{action}</React.Fragment>
                ))
              : actions}
          </div>
        </div>
      ) : (
        <div className="TableSelectionWrapper__selection-placeholder" />
      )}
      <div
        className={`TableSelectionWrapper__table-wrapper ${
          selections?.length > 0
            ? 'TableSelectionWrapper__table-wrapper--selection'
            : ''
        }`}
      >
        {children}
      </div>
    </div>
  )
}
