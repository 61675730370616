import React, { useState } from 'react'
import {
  Button,
  Modal,
  Form,
  FormItem,
  TagInput,
  TextInput,
} from '@eltoro-ui/components'
import { useForm } from '@eltoro-ui/hooks'
import './SaveAudienceModal.scss'

export type AudienceDetailsType = { name: string; tags?: string[] }

type SaveAudienceModalType = {
  onClickOutside: () => void
  onSave: (audienceDetails: AudienceDetailsType) => void
}

export const SaveAudienceModal: React.FC<SaveAudienceModalType> = ({
  onClickOutside,
  onSave,
}) => {
  const [name, setName] = useState('')
  const [tags, setTags] = useState<string[]>()

  const { isValidForm, touched } = useForm({ name })
  const required = ['name']
  const { validForm, missingFields } = isValidForm([...required])

  const handleSubmit = () => {
    if (tags && tags.length > 0) onSave({ name, tags })
    else onSave({ name })
  }

  return (
    <Modal
      header="Name and Tag your Target Audience"
      offClick={onClickOutside}
      className="SaveAudienceModal"
    >
      <div className="SaveAudienceModal__content">
        <Form
          onSubmit={handleSubmit}
          required={required}
          valid={validForm}
          touched={touched}
          missingFields={missingFields}
        >
          <FormItem
            htmlFor="name"
            label="Name"
            valid={!missingFields.includes('name')}
            errorMessage="Please enter a name"
            required
          >
            <TextInput value={name} onChange={(e) => setName(e.target.value)} />
          </FormItem>
          <FormItem htmlFor="tags" label="Tags">
            <TagInput onChange={(tagArray) => setTags(tagArray)} />
          </FormItem>
          <Button kind="primary" type="submit" disabled={!validForm}>
            Save
          </Button>
        </Form>
      </div>
    </Modal>
  )
}
