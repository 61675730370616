import React from 'react'
import { APIFeatureType } from 'types'
import { GridBox, Text } from '@eltoro-ui/components'
import './MapPopup.scss'
import {
  getAbbreviatedNumber,
  getIconBuzzColor,
  hexToRgb,
  luminance,
} from 'Helpers'

export const MapPopup: React.FC<{ feature: APIFeatureType }> = ({
  feature,
}) => {
  const getTextHeaderColor = () => {
    // trim 'var(' & ')' from returned string
    const trimmedColor = getIconBuzzColor(feature).slice(4, -1)
    // get css var property and remove '#' from beginning
    const hexColor = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue(trimmedColor)
      .replace(/[#]/g, '')
    // convert hex to rgb and get luminance
    const lumins = luminance(hexToRgb(hexColor))
    if (lumins > 0.6) {
      return 'black'
    }
    return 'white'
  }

  return (
    <div className="MapPopup">
      <div
        style={{ background: getIconBuzzColor(feature) }}
        className="MapPopup__header"
      >
        <span style={{ color: getTextHeaderColor() }}>
          <b>{feature.street}</b>
        </span>
      </div>
      <GridBox gridTemplateColumns="max-content" gap=".5rem" padding="1rem">
        <Text on="white">
          Listing Price:{' '}
          <b>${getAbbreviatedNumber(feature.listing_price.toString())}</b>
        </Text>
        <Text on="white">
          <b>{feature.beds}</b> beds / <b>{feature.baths}</b> baths
        </Text>
        <Text on="white">
          Listed on <b>{new Date(feature.created).toLocaleDateString()}</b>
        </Text>
      </GridBox>
    </div>
  )
}
