import React, { FC, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import { Button } from '@eltoro-ui/components'
import { Section, CreditModal } from 'Components'
import { SubscriptionCard } from '..'
import './SubscriptionSection.scss'

export const SubscriptionSection: FC = () => {
  const [creditModalOpen, setCreditModalOpen] = useState(false)

  return (
    <>
      <Section title="Subscription" noBackground>
        <div className="SubscriptionSection">
          <div className="SubscriptionSection__plans">
            <SubscriptionCard
              header="Current plan"
              name="Basic"
              description="Description of basic subscription"
              showButton={false}
            />
            <div className="SubscriptionSection__arrow">
              <FontAwesomeIcon icon={faLongArrowAltRight} size="3x" />
            </div>
            <SubscriptionCard
              header="Upgraded plan"
              name="Best"
              description="Description of best subscription"
              locked
            />
          </div>
          <div className="SubscriptionSection__button">
            <Button onClick={() => setCreditModalOpen(true)}>
              Buy Credits
            </Button>
          </div>
        </div>
      </Section>
      {creditModalOpen && (
        <CreditModal offClick={() => setCreditModalOpen(false)} />
      )}
    </>
  )
}
