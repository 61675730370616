import * as misc from './Request_Methods/miscMethods'
import * as prospects from './Request_Methods/prospectMethods'
import * as listings from './Request_Methods/listingMethods'
import * as users from './Request_Methods/userMethods'
import * as campaigns from './Request_Methods/campaignMethods'
import * as audiences from './Request_Methods/audienceMethods'
import * as creatives from './Request_Methods/creativeMethods'

const methods = {
  ...listings,
  ...users,
  ...misc,
  ...prospects,
  ...campaigns,
  ...audiences,
  ...creatives,
}

export const {
  getAuth,
  getUser,
  updateUser,
  getListings,
  getListingById,
  getMapListings,
} = methods
