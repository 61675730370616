import React from 'react'
import { CampaignType } from 'types'
import { FlexBox, GridBox, Text } from '@eltoro-ui/components'
import {
  Section,
  ProgressIndicator,
  CampaignStatusBadge,
  LabelValuePair,
} from 'Components'

export const CampaignDetails = ({ campaign }: { campaign: CampaignType }) => {
  return (
    <div className="CampaignDetails">
      <Section title="Campaign Details" dark>
        <div className="CampaignDetails__body">
          <FlexBox flexDirection="row" justifyContent="space-around">
            <GridBox gap="2rem">
              <LabelValuePair label="CREATED" value={campaign.created} />
              <LabelValuePair label="FLIGHT DATES" value="1/03/20 - 3/23/20" />
              <div>
                <Text on="white" size="l" weight="bold">
                  DAYS
                </Text>
                <ProgressIndicator
                  current={campaign.days_in || 0}
                  end={campaign.length || 0}
                />
              </div>
              <LabelValuePair label="MATCHED" value="302" />
            </GridBox>
            <GridBox gap="2rem">
              <LabelValuePair
                label="CREATIVE"
                value="Direct Mailer, Digital Banner"
              />
              <LabelValuePair
                label="CLICK THROUGH LINK"
                value="https://myrealestatewebsite.com"
              />
              <LabelValuePair
                label="STATUS"
                value={
                  <div style={{ width: '50%' }}>
                    <CampaignStatusBadge
                      status={campaign.state}
                      color="green"
                    />
                  </div>
                }
              />
              <LabelValuePair
                label="# OF IMPRESSIONS"
                value={campaign?.impressions?.toString()}
              />
            </GridBox>
          </FlexBox>
        </div>
      </Section>
    </div>
  )
}
