import React, { useState } from 'react'
import { useForm, useValidatedState, FormObjectType } from '@eltoro-ui/hooks'
import {
  Form,
  FormItem,
  Button,
  Text,
  RadioButton,
  RadioButtonGroup,
  TextInput,
} from '@eltoro-ui/components'
import './PersonalInfoForm.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/pro-solid-svg-icons'
import { UserInfoType } from 'types'
import { ProgressBar } from '../ProgressBar'

export const PersonalInfoForm = ({
  onSubmit,
  user,
}: {
  onSubmit: (value: FormObjectType) => void
  user: UserInfoType
}) => {
  const [name, setName] = useState(user.personalInfo.name)
  const [email, setEmail, isValidEmail] = useValidatedState(
    user.personalInfo.email,
    'email',
  )
  const [agency, setAgency] = useState<React.ReactText>(
    user.personalInfo.agency,
  )

  const { formData, isValidForm, touched } = useForm({
    name,
    email,
    agency,
  })

  const required = ['name', 'email', 'agency']
  const { validForm, missingFields } = isValidForm([...required, isValidEmail])

  return (
    <div className="PersonalInfoForm">
      <div className="PersonalInfoForm__description">
        <FontAwesomeIcon
          icon={faUser}
          size="4x"
          className="PersonalInfoForm__icon"
        />
        <div className="PersonalInfoForm__progress">
          <ProgressBar totalSteps={2} activeStep={1} />
        </div>
        <Text on="white" size="xl" weight="bold">
          Personal Info
        </Text>
        <Text on="white">
          We are here to get you the most tailored experience. All we need from
          you is to answer a couple of simple questions.
        </Text>
      </div>
      <div className="PersonalInfoForm__inputs">
        <Form
          onSubmit={() => onSubmit(formData)}
          required={required}
          valid={validForm}
          missingFields={missingFields}
          touched={touched}
        >
          <FormItem
            htmlFor="name"
            label="Full Name*"
            errorMessage="Please enter a valid name"
            valid={name.length > 1}
          >
            <TextInput value={name} onChange={(e) => setName(e.target.value)} />
          </FormItem>
          <FormItem
            htmlFor="email"
            label="Work Email*"
            errorMessage="Please enter a valid email"
            valid={isValidEmail}
          >
            <TextInput
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormItem>
          <FormItem
            htmlFor="agency"
            label="Do you work for a real estate agency, or are you self represented?*"
            errorMessage="Please enter a selection"
            valid={!!agency}
          >
            <RadioButtonGroup
              name="agency"
              valueSelected={agency}
              onChange={(value) => setAgency(value)}
            >
              <RadioButton key={1} value="agency" label="Real Estate Agency" />
              <RadioButton key={2} value="self" label="Self Represented" />
            </RadioButtonGroup>
          </FormItem>
          <div className="PersonalInfoForm__submit">
            <Text on="white">*required</Text>
            <Button type="submit">Next</Button>
          </div>
        </Form>
      </div>
    </div>
  )
}
