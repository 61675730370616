import React, { useContext } from 'react'
import './ProfileDropdown.scss'
import { Text, Button, Avatar } from '@eltoro-ui/components'
import { useKeycloak } from '@react-keycloak/web'
import { AppContext } from 'AppContext'
import { clearSession } from '../../Tools/sessionManager'

export const ProfileDropdown = () => {
  const { keycloak } = useKeycloak()
  const { currentUser } = useContext(AppContext)
  return (
    <div className="ProfileDropdown">
      <div className="ProfileDropdown__body">
        <div className="ProfileDropdown__avatar">
          <Avatar name="Test User" rounded size="m" />
        </div>
        <div className="ProfileDropdown__name">
          <Text size="l" on="white">
            {currentUser?.firstName}
          </Text>
        </div>
        <div className="ProfileDropdown__email">
          <Text size="s" on="white">
            {currentUser?.email}
          </Text>
        </div>
      </div>
      <div className="ProfileDropdown__footer">
        <Button to="/profile" size="s" kind="primary">
          Profile
        </Button>
        <Button
          size="s"
          kind="primary"
          onClick={() => {
            keycloak.logout()
            clearSession()
          }}
        >
          Logout
        </Button>
      </div>
    </div>
  )
}
