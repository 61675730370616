import React from 'react'
import { Section, ProgressIndicator } from 'Components'
import { FlexBox, Text, Button, GridBox } from '@eltoro-ui/components'
import { CampaignType } from 'types'
import './CampaignReport.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImagePolaroid, faPlus } from '@fortawesome/pro-solid-svg-icons'
import { fakeCreatives, FakeCreativeType } from './fakeCreatives'

const CampaignDetailsText = ({
  title,
  body,
}: {
  title: string
  body: string
}) => {
  return (
    <div>
      <Text on="white" size="m" weight="bold" tag="div">
        {title}
      </Text>
      <Text on="white" size="xl" weight="bold" tag="div">
        {body}
      </Text>
    </div>
  )
}

const DevicesServedContainer = () => {
  return (
    <FlexBox
      flexGrow={1}
      flexDirection="column"
      justifyContent="space-around"
      alignContent="center"
    >
      <Text on="white" size="m" weight="bold" tag="div">
        Devices Served
      </Text>
      <div className="CampaignReport__devices-served">
        <Text on="white" size="m" weight="bold" tag="div">
          Mobile - 25%
        </Text>
        <Text on="white" size="m" weight="bold" tag="div">
          Desktop - 35%
        </Text>
        <Text on="white" size="m" weight="bold" tag="div">
          Tablet - 35%
        </Text>
        <Text on="white" size="m" weight="bold" tag="div">
          Other - 5%
        </Text>
      </div>
    </FlexBox>
  )
}

const HighestPreformingContainer = ({
  creative,
}: {
  creative: FakeCreativeType
}) => {
  return (
    <FlexBox flexDirection="column" flexGrow={3}>
      <Text on="white" size="m" weight="bold" tag="div">
        Highest Performing Creative
      </Text>
      <div className="CampaignReport__highest-preforming-container">
        <FlexBox flexDirection="column">
          <FontAwesomeIcon
            icon={faImagePolaroid}
            className="CampaignReport__big-image"
          />
          <Text on="white" size="m" tag="div">
            {creative.name}
          </Text>
          <Text on="white" size="m" tag="div">
            Banner - 300x250
          </Text>
        </FlexBox>
        <FlexBox flexDirection="column" justifyContent="center">
          <GridBox gap="1rem">
            <CampaignDetailsText
              title="CLICKS"
              body={creative.clicks ? creative.clicks.toString() : ''}
            />
            <CampaignDetailsText title="CTR" body="7.90%" />
            <CampaignDetailsText
              title="IMPRESSIONS"
              body={creative.impressions ? creative.impressions.toString() : ''}
            />
          </GridBox>
        </FlexBox>
      </div>
    </FlexBox>
  )
}

export const CampaignReport = ({ campaign }: { campaign: CampaignType }) => {
  const fakeCreative: FakeCreativeType = {
    name: 'Best Creative Ever',
    cost: 1000,
    impressions: 371,
    clicks: 789,
  }
  return (
    <div className="CampaignReport">
      <Section title="Report">
        <div className="CampaignReport__body">
          <FlexBox justifyContent="space-around">
            <CampaignDetailsText title="CLICKS" body="7899" />
            <CampaignDetailsText title="CTR" body="7.90%" />
            <div>
              <Text on="white" size="m" weight="bold" tag="div">
                IMPRESSIONS
              </Text>
              <ProgressIndicator
                current={campaign.impressions || 0}
                end={campaign.impressions || 0}
              />
            </div>
            <div>
              <Text on="white" size="m" weight="bold" tag="div">
                SPENT
              </Text>
              <ProgressIndicator
                current={campaign.spent || 0}
                end={campaign.budget || 0}
              />
            </div>
          </FlexBox>
          <div className="CampaignReport__creative-grid">
            <HighestPreformingContainer creative={fakeCreative} />
            <DevicesServedContainer />
          </div>
        </div>
      </Section>
      <Section
        title="Creatives"
        actions={[
          <Button iconLeft={<FontAwesomeIcon icon={faPlus} />}>
            Add Creative
          </Button>,
        ]}
      >
        <GridBox gap="2rem" gridTemplateColumns="1fr 1fr 1fr 1fr">
          {fakeCreatives.map((creative) => {
            return (
              <div
                className="CampaignReport__creative-grid-box"
                key={creative.name}
              >
                <FontAwesomeIcon icon={faImagePolaroid} size="8x" />
                <Text on="white" size="m" tag="div">
                  {creative.name}
                </Text>
                <Text on="white" size="m" tag="div">
                  Banner - 300x250
                </Text>
              </div>
            )
          })}
        </GridBox>
      </Section>
    </div>
  )
}
