import React, { FC, useState } from 'react'
import { Text, Spacer, Button, FlexBox } from '@eltoro-ui/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import './Notes.scss'

type NotesType = {
  notes?: string
  onSaveNotes: (note: string) => void
}

export const Notes: FC<NotesType> = ({ notes, onSaveNotes }) => {
  const [editing, setEditing] = useState(false)
  const [value, setValue] = useState(notes)

  const handleSaveNotes = () => {
    if (value) {
      onSaveNotes(value)
      setEditing(false)
    }
  }

  const canBeSaved =
    editing && notes !== value && ((notes && value === '') || value)

  return (
    <div className="Notes">
      <FlexBox
        justifyContent="space-between"
        alignItems="center"
        paddingRight="0.5rem"
      >
        <Text on="white" kind="subdued" size="m" weight="bold">
          NOTES
        </Text>
        <Button
          size="s"
          onClick={handleSaveNotes}
          iconLeft={<FontAwesomeIcon icon={faCheck} />}
          UNSAFE_className="Notes__edit-btn"
          disabled={!canBeSaved}
        >
          Save
        </Button>
      </FlexBox>
      <Spacer height="0.5rem" />
      <textarea
        className="Notes__textarea"
        name="notes"
        value={value}
        rows={8}
        placeholder="Write any notes you want to remember here"
        onChange={(e) => {
          setEditing(true)
          setValue(e.target.value)
        }}
      >
        {notes}
      </textarea>
    </div>
  )
}
