import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Text } from '@eltoro-ui/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faQuestion } from '@fortawesome/free-solid-svg-icons'
import './PageNotFound.scss'
import logo from '../../Images/Icon_color@2x.png'

export const PageNotFound = () => {
  const history = useHistory()

  return (
    <div className="PageNotFound">
      <div className="PageNotFound__graphic">
        <div className="PageNotFound__question">
          <FontAwesomeIcon icon={faQuestion} size="9x" />
        </div>
        <img src={logo} alt="beewo-logo" />
      </div>
      <div className="PageNotFound__content">
        <Text on="white" kind="warning" size="xxxl" weight="bold">
          404: Page not found
        </Text>
        <Text on="white" kind="primary">
          Oops, the page you are looking for does not exist!
        </Text>
        <Button
          kind="primary"
          iconLeft={<FontAwesomeIcon icon={faArrowLeft} />}
          onClick={() => history.goBack()}
          UNSAFE_className="PageNotFound__button"
        >
          Go back
        </Button>
      </div>
    </div>
  )
}
