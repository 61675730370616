import React, { useState, useEffect } from 'react'
import './AreaSearchLens.scss'
import {
  MapMarker,
  SaveAudienceWizard,
  AudienceResultsCard,
  AudienceResultsCount,
  LocationLabels,
} from 'Pages/ProspectActivity/components'
import { MaxHeightContainer, Spacer } from '@eltoro-ui/components'
import { APIFeatureType, APIDataType } from 'types'
import { faWalking } from '@fortawesome/free-solid-svg-icons'
import {
  BuzzScoreFilters,
  filterFeaturesByBuzz,
  MapSearch,
  Map,
  MapPopup,
  MapToolTip,
} from 'Components'
import { useLocations } from 'Hooks'
import { getMapListings } from 'Requests'
import { browserLog } from 'Tools'
import { getIconBuzzColor } from 'Helpers'
import { buzzFilters } from '../../configs'
import {
  LensSidebar,
  LensSidebarHeader,
  LensSidebarFooter,
  LensSectionHeading,
  LensFilterContainer,
} from '../components'

export const AreaSearchLens = () => {
  const {
    locations,
    addLocation,
    removeLocation,
    hasLocations,
  } = useLocations()
  const [features, setFeatures] = useState<APIFeatureType[]>([])
  const [activeBuzzFilters, setActiveBuzzFilters] = useState(buzzFilters)

  useEffect(() => {
    if (hasLocations) {
      getMapListings(locations).then((res: APIDataType) => {
        setFeatures(res.data.listings)
      })
    } else {
      setFeatures([])
    }
  }, [hasLocations, locations])

  const filteredFeatures =
    activeBuzzFilters.length < buzzFilters.length
      ? filterFeaturesByBuzz(features, activeBuzzFilters)
      : features
  const totalProspects = filteredFeatures.reduce(
    (count, currFeature) => count + currFeature.unique_observations,
    0,
  )

  return (
    <div className="AreaSearchLens">
      <LensSidebar>
        <MaxHeightContainer
          fullHeight
          header={
            <LensSidebarHeader
              heading="Area Search"
              subHeading="View all houses for sale and the prospects seen in them."
            />
          }
          footer={
            hasLocations ? (
              <LensSidebarFooter>
                <AudienceResultsCard>
                  Potentially advertise to
                  <AudienceResultsCount faIcon={faWalking}>
                    {`${totalProspects.toLocaleString()} prospects`}
                  </AudienceResultsCount>
                  seen around <b>{filteredFeatures.length}</b> listings
                  <Spacer />
                  <SaveAudienceWizard
                    onSaveAndClose={(audienceDetails) =>
                      browserLog.info(audienceDetails)
                    }
                  />
                </AudienceResultsCard>
              </LensSidebarFooter>
            ) : null
          }
        >
          <div className="AreaSearchLens__sidebar-content">
            <MapSearch onSelect={addLocation} />

            {hasLocations && (
              <>
                <Spacer height="2rem" />
                <LensSectionHeading
                  heading={`Locations: ${locations.length}`}
                />
                <LocationLabels
                  locations={locations}
                  onRemove={removeLocation}
                />
                <Spacer height="2rem" />
                <LensSectionHeading heading="Filters:" />
                <LensFilterContainer>
                  <BuzzScoreFilters
                    filters={buzzFilters}
                    activeFilters={activeBuzzFilters}
                    onChange={setActiveBuzzFilters}
                  />
                </LensFilterContainer>
              </>
            )}
          </div>
        </MaxHeightContainer>
      </LensSidebar>
      <div className="AreaSearchLens__map">
        <Map
          features={filteredFeatures}
          locations={locations}
          marker={(f) => <MapMarker color={getIconBuzzColor(f)} />}
          popup={(f) => <MapPopup feature={f} />}
          tooltip={(f) => <MapToolTip feature={f} />}
        />
      </div>
    </div>
  )
}
