import { TextInput } from '@eltoro-ui/components'
import React, { useState } from 'react'
import './ClickthroughInput.scss'

export const ClickthroughInput = ({ value }: { value: string }) => {
  const [clickthroughUrl, setClickthroughUrl] = useState(value)
  return (
    <div className="ClickthroughInput">
      <TextInput
        value={clickthroughUrl}
        onChange={(e) => setClickthroughUrl(e.target.value)}
        size="l"
      />
    </div>
  )
}
