import React from 'react'
import './Loading.scss'

export const Loading = () => {
  return (
    <div className="Loading">
      <span className="Loading__dot" />
      <span className="Loading__dot" />
      <span className="Loading__dot" />
    </div>
  )
}
