import React, { FC } from 'react'
import './CampaignStatusBadge.scss'

type CampaignStatusBadgeType = {
  status: string
  color: string
}

export const CampaignStatusBadge: FC<CampaignStatusBadgeType> = ({
  status,
  color,
}) => {
  return (
    <div className="CampaignStatusBadge">
      <div
        className="CampaignStatusBadge__indicator"
        style={{ backgroundColor: color }}
      />
      <div className="CampaignStatusBadge__text-container">{status}</div>
    </div>
  )
}
