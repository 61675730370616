import React from 'react'
import { FlexBox, Text } from '@eltoro-ui/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'

export const SummaryTotal: React.FC<{ total: number }> = ({ total }) => {
  return (
    <FlexBox alignItems="center" justifyContent="space-between">
      <Text size="xl" on="white" weight="bold">
        TOTAL
      </Text>
      <Text size="xl" on="white" weight="bold">
        <FontAwesomeIcon icon={faStar} /> {total.toLocaleString()}
      </Text>
    </FlexBox>
  )
}
