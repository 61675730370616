import React from 'react'
import { Modal } from '@eltoro-ui/components'
import { UploadBannerModal, UploadMailerModal, UploadVideoModal } from '..'
import './CreativeCampaignModal.scss'

export const CreativeCampaignModal = ({
  type,
  onClickOutside,
}: {
  type: 'banner' | 'directMail' | 'video' | null
  onClickOutside: () => void
}) => {
  const displayModal = () => {
    if (type === 'banner') {
      return <UploadBannerModal onClickOutside={onClickOutside} />
    }
    if (type === 'directMail') {
      return <UploadMailerModal onClickOutside={onClickOutside} />
    }
    return <UploadVideoModal onClickOutside={onClickOutside} />
  }
  return <Modal offClick={onClickOutside}>{displayModal()}</Modal>
}
