import React, { FC } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Avatar, Button, Text } from '@eltoro-ui/components'
import './ManageAvatar.scss'

type ManageAvatarType = {
  user: { [key: string]: any } // UserType
}

export const ManageAvatar: FC<ManageAvatarType> = ({ user }) => {
  return (
    <div className="ManageAvatar">
      <Text on="white" weight="bold">
        Avatar
      </Text>
      <div className="ManageAvatar__avatar">
        <Avatar name={`${user.firstName} ${user.lastName}`} rounded size="xl" />
      </div>
      <Button disabled iconLeft={<FontAwesomeIcon icon={faPlus} />}>
        Upload Photo
      </Button>
      <Button disabled kind="text">
        Remove Photo
      </Button>
    </div>
  )
}
