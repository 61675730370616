import React from 'react'
import { Button, Text } from '@eltoro-ui/components'
import './UploadVideoModal.scss'
import { Uploader } from 'Components/Uploader'
import { browserLog } from 'Tools'
import { UploadAreaLayout, UploadTypes } from '../UploadAreaLayout'

export const UploadVideoModal = ({
  onClickOutside,
}: {
  onClickOutside: () => void
}) => {
  return (
    <div className="UploadVideoModal">
      <Text tag="div" on="white" size="xxl" weight="bold">
        Upload Your Video
      </Text>
      <Text tag="div" on="white">
        We recommend video <b>no longer than 30 seconds</b>
      </Text>
      <Uploader type="video" onDropFile={(files) => browserLog.info(files)}>
        <UploadAreaLayout uploadType={UploadTypes.video} />
      </Uploader>
      <div className="UploadVideoModal__footer">
        <Button
          kind="text"
          UNSAFE_className="UploadVideoModal__cancel-button"
          onClick={onClickOutside}
        >
          Cancel
        </Button>
        <Button kind="primary">Confirm</Button>
      </div>
    </div>
  )
}
