import React, { ReactNode } from 'react'
import { Text } from '@eltoro-ui/components'

type LabelValuePairType = {
  label: string
  value: ReactNode
}

export const LabelValuePair = ({ label, value }: LabelValuePairType) => {
  return (
    <div>
      <Text on="white" kind="subdued" size="m" weight="bold">
        {label}
      </Text>
      <Text on="white" size="xl" tag="div">
        {value}
      </Text>
    </div>
  )
}
