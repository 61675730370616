import React from 'react'
import { faMap } from '@fortawesome/pro-solid-svg-icons'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GridBox } from '@eltoro-ui/components'
import { MapLocationType } from 'types'
import './LocationLabel.scss'

export const LocationLabel: React.FC<{
  location: MapLocationType
  onRemove: (id: number) => void
}> = ({ location, onRemove }) => {
  return (
    <div className="LocationLabel">
      <GridBox
        gridTemplateColumns="min-content auto min-content"
        gap="0.5rem"
        padding="0.25rem"
      >
        <div className="LocationLabel__icon-circle LocationLabel__icon-circle--grey-bg">
          <FontAwesomeIcon icon={faMap} />
        </div>

        <div className="LocationLabel__label">{location.label}</div>

        <div className="LocationLabel__icon-circle">
          <button
            area-aria-label={`Click to remove location: ${location.label}`}
            className="LocationLabel__delete-button"
            type="button"
            onClick={() => location && onRemove(location.raw.place_id)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      </GridBox>
    </div>
  )
}
