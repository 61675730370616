import React, { useState } from 'react'
import {
  PageHeader,
  CampaignStatusBadge,
  ProgressIndicator,
  TableSelectionWrapper,
} from 'Components'
import { Button, Table, Text, TextInput } from '@eltoro-ui/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faSearch } from '@fortawesome/pro-solid-svg-icons'
import './Campaigns.scss'
import { CampaignType } from 'types'
import { fixtureCampaigns } from 'Requests/Fixtures'

export const getStatusColor = (status: string) => {
  if (status === 'In Review') return 'var(--color-warning-300)'
  if (status === 'Active' || status === 'Complete')
    return 'var(--color-success-400)'
  return 'var(--color-grey-300)'
}

export const Campaigns = () => {
  const [selections, setSelections] = useState<CampaignType[]>([])
  const [search, setSearch] = useState('')

  return (
    <div className="Campaigns">
      <PageHeader
        title="Campaigns"
        subTitle="Last Updated 11/04/2020 - 1:45 PM"
        actions={[
          <TextInput
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            icon={<FontAwesomeIcon icon={faSearch} />}
          />,
          <Button
            kind="primary"
            to="/create-campaign/setup"
            iconLeft={<FontAwesomeIcon icon={faPlus} />}
          >
            New Campaign
          </Button>,
        ]}
      />
      <TableSelectionWrapper
        selectionType="campaign"
        selections={selections}
        actions={[
          <button type="button" className="Campaigns__select-action">
            <Text on="tertiary-300" weight="bold">
              Download
            </Text>
          </button>,
          <button type="button" className="Campaigns__select-action">
            <Text on="tertiary-300" weight="bold">
              Archive
            </Text>
          </button>,
          <button type="button" className="Campaigns__select-action">
            <Text on="tertiary-300" weight="bold">
              Delete
            </Text>
          </button>,
        ]}
      >
        <Table
          rows={fixtureCampaigns}
          columns={[
            { path: 'title', label: 'Name', width: '20%' },
            {
              path: 'days_in',
              label: 'Days',
              RowCell: (campaign) => (
                <ProgressIndicator
                  current={campaign.days_in || 0}
                  end={campaign.length || 0}
                />
              ),
            },
            {
              path: 'budget',
              label: 'Budget',
              RowCell: (campaign) => (
                <ProgressIndicator
                  current={campaign.spent || 0}
                  end={campaign.budget || 0}
                  prefix="$"
                />
              ),
            },
            { path: 'prospects', label: 'Audience Size' },
            {
              path: 'impressions',
              label: 'Impressions',
              RowCell: (campaign) =>
                campaign.impressions ? campaign.impressions : '---',
            },
            {
              path: 'state',
              label: 'Status',
              RowCell: (campaign) => (
                <CampaignStatusBadge
                  status={campaign.state}
                  color={getStatusColor(campaign.state)}
                />
              ),
            },
            {
              path: '',
              RowCell: (campaign) => (
                <Button to={`campaigns/${campaign._id}`}>View</Button>
              ),
              alignment: 'right',
            },
          ]}
          onSelect={setSelections}
          fixedCellSize
        />
      </TableSelectionWrapper>
    </div>
  )
}
