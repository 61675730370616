import React from 'react'
import {
  MaxHeightContainer,
  LargeIconButton,
  Text,
  GridBox,
} from '@eltoro-ui/components'
import './ProspectFinderSelection.scss'
import { useRouteMatch } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBullhorn,
  // faLock,
  faWalking,
} from '@fortawesome/free-solid-svg-icons'

const ProspectFinderHeader: React.FC<{
  mainTitle: string
  subTitle?: string
  icon?: JSX.Element
}> = ({ mainTitle, subTitle, icon }) => {
  return (
    <div className="ProspectFinderHeader">
      <GridBox alignItems="center" gridTemplateColumns="auto 1fr" gap="1rem">
        {icon && (
          <Text on="white" size="xxl">
            {icon}
          </Text>
        )}
        <Text on="white" size="xxl" weight="bold">
          {mainTitle}
        </Text>
      </GridBox>
      {subTitle && <Text on="white">{subTitle}</Text>}
    </div>
  )
}

export const ProspectFinderSelection = () => {
  const { url } = useRouteMatch()
  return (
    <div className="ProspectFinderSelection">
      <MaxHeightContainer
        header={
          <div className="ProspectFinderSelection__header">
            <Text on="primary-100" size="xxxl" weight="bold">
              Prospect Finder
            </Text>
          </div>
        }
      >
        <div className="ProspectFinderSelection__content">
          <div className="ProspectFinderSelection__section">
            <ProspectFinderHeader
              mainTitle="Observe footfall traffic in market."
              icon={<FontAwesomeIcon icon={faWalking} />}
            />
            <LargeIconButton
              title="My Listings"
              subTitle="View prospects that have been seen in one or all of your listings."
              to={`${url}/my-listings`}
              // icon={<FontAwesomeIcon icon={faLock} />}
            />
            <LargeIconButton
              title="Area Search"
              subTitle="View all houses for sale and the prospects seen in them."
              to={`${url}/area-search`}
              // icon={<FontAwesomeIcon icon={faLock} />}
            />
            <LargeIconButton
              title="Comparable Listings"
              subTitle="View prospects seen in comparable listings to one of your listings."
              to={`${url}/comparable-listings`}
              // icon={<FontAwesomeIcon icon={faLock} />}
              // disabled
            />
            <LargeIconButton
              title="Find Your Prospects"
              subTitle="Use filters to view prospects seen in listings that match your criteria."
              to={`${url}/find-your-prospects`}
              // icon={<FontAwesomeIcon icon={faLock} />}
              // disabled
            />
          </div>
          <div className="ProspectFinderSelection__section">
            <ProspectFinderHeader
              mainTitle="Find other prospects"
              icon={<FontAwesomeIcon icon={faBullhorn} />}
            />
            <LargeIconButton
              title="Digital Canvassing"
              subTitle="Identify prospects that reside around a specific area."
              to={`${url}/digital-canvassing`}
              // icon={<FontAwesomeIcon icon={faLock} />}
              // disabled
            />
            <LargeIconButton
              title="Mover Lifecycle"
              subTitle="View prospects in any stage of the moving lifecycle."
              to={`${url}/mover-lifecycle`}
              // icon={<FontAwesomeIcon icon={faLock} />}
              // disabled
            />
          </div>
        </div>
      </MaxHeightContainer>
    </div>
  )
}
