import React, { useState, useEffect } from 'react'
import { MaxHeightContainer, Spacer } from '@eltoro-ui/components'
import { faWalking } from '@fortawesome/pro-solid-svg-icons'
import {
  Map,
  MapPopup,
  MapSearch,
  MapToolTip,
  MoverLifecycleFilters,
} from 'Components'
import { useLocations } from 'Hooks'
import { APIDataType, APIFeatureType } from 'types'
import { moverLifecycleFilters } from 'Pages/ProspectActivity/configs'
import { getMapListings } from 'Requests'
import { getIconBuzzColor } from 'Helpers'
import { browserLog } from 'Tools'
import { filterMoverLifecycle } from 'Components/MoverLifecycleFilters/utils'
import {
  AudienceResultsCard,
  AudienceResultsCount,
  MapMarker,
  LocationLabels,
  SaveAudienceWizard,
} from 'Pages/ProspectActivity/components'
import {
  LensFilterContainer,
  LensSectionHeading,
  LensSidebar,
  LensSidebarFooter,
  LensSidebarHeader,
} from '../components'
import './MoverLifecycleLens.scss'

export const MoverLifecycleLens = () => {
  const [features, setFeatures] = useState<APIFeatureType[]>([])
  const {
    locations,
    addLocation,
    removeLocation,
    hasLocations,
  } = useLocations()
  const [
    activeMoverLifecycleFilters,
    setActiveMoverLifecycleFilters,
  ] = useState(moverLifecycleFilters)

  const filteredFeatures =
    activeMoverLifecycleFilters.length < moverLifecycleFilters.length
      ? filterMoverLifecycle(features, activeMoverLifecycleFilters)
      : features
  const totalProspects = filteredFeatures.reduce(
    (count, currFeature) => count + currFeature.unique_observations,
    0,
  )

  useEffect(() => {
    if (hasLocations) {
      getMapListings(locations).then((res: APIDataType) =>
        setFeatures(res.data.listings),
      )
    } else {
      setFeatures([])
    }
  }, [locations, activeMoverLifecycleFilters])

  return (
    <div className="MoverLifecycleLens">
      <LensSidebar>
        <MaxHeightContainer
          fullHeight
          header={
            <LensSidebarHeader
              heading="Mover Lifecycle"
              subHeading="View targets in any stage of the moving lifecycle."
            />
          }
          footer={
            hasLocations && (
              <LensSidebarFooter>
                <AudienceResultsCard>
                  Potentially advertise to
                  <AudienceResultsCount faIcon={faWalking}>
                    {`${totalProspects.toLocaleString()} targets`}
                  </AudienceResultsCount>
                  seen around <b>{filteredFeatures.length}</b> listings
                </AudienceResultsCard>
                <Spacer />
                <SaveAudienceWizard
                  onSaveAndClose={(audienceDetails) =>
                    browserLog.info(audienceDetails)
                  }
                />
              </LensSidebarFooter>
            )
          }
        >
          <div className="MoverLifecycleLens__sidebar-content">
            <MapSearch onSelect={addLocation} />
            {hasLocations && (
              <>
                <Spacer height="2rem" />
                <LensSectionHeading
                  heading={`Locations: ${locations.length}`}
                />
                <LocationLabels
                  locations={locations}
                  onRemove={removeLocation}
                />
                <Spacer height="2rem" />
                <LensSectionHeading heading="Lifecycle selection:" />
                <LensFilterContainer>
                  <MoverLifecycleFilters
                    filters={moverLifecycleFilters}
                    activeFilters={activeMoverLifecycleFilters}
                    onChange={setActiveMoverLifecycleFilters}
                  />
                </LensFilterContainer>
              </>
            )}
          </div>
        </MaxHeightContainer>
      </LensSidebar>
      <div className="MoverLifecycleLens__map">
        <Map
          features={filteredFeatures}
          locations={locations}
          marker={(f) => <MapMarker color={getIconBuzzColor(f)} />}
          popup={(f) => <MapPopup feature={f} />}
          tooltip={(f) => <MapToolTip feature={f} />}
        />
      </div>
    </div>
  )
}
