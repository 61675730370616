import React from 'react'
import { Text } from '@eltoro-ui/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/pro-solid-svg-icons'
import { CreditBundleType } from 'Components/CreditModal'
import './CreditBundleCard.scss'

export const CreditBundleCard: React.FC<{
  bundle: CreditBundleType
  onClick?: () => void
}> = ({ bundle, onClick }) => {
  const getStars = () => {
    const stars = []
    for (let i = 0; i < bundle.stars; i += 1) {
      stars.push(
        <FontAwesomeIcon
          key={bundle.name + bundle.stars + i}
          icon={faStar}
          className="CreditBundleCard__star"
          size="2x"
        />,
      )
    }
    return stars
  }

  return (
    <div className="CreditBundleCard">
      <div className="CreditBundleCard__title">
        <Text on="white" kind="subdued" weight="bold">
          {bundle.name}
        </Text>
      </div>

      <button
        type="button"
        onClick={onClick}
        className="CreditBundleCard__button"
        style={onClick ? { cursor: 'pointer' } : {}}
      >
        <div className="CreditBundleCard__star-container">{getStars()}</div>
        <div>
          <Text on="grey-100" tag="div" weight="bold">
            {`${bundle.credits} credits = $${bundle.price}`}
          </Text>
          <Text on="grey-100" size="xs">
            {bundle.subText}
          </Text>
        </div>
      </button>
    </div>
  )
}
