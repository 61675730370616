import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button, FlexBox, Spacer, Text } from '@eltoro-ui/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { PageHeader, LabelValuePair, Loading } from 'Components'
import { getLocalDate, getLocalTime } from 'Helpers'
import { getListingById } from 'Requests/Request_Methods/listingMethods'
import { useAsync } from '@eltoro-ui/hooks'
import { APIFeatureType } from 'types'
import { ListingDetails, ListingPhotosMap } from './components'
import './ListingView.scss'

export const ListingView = () => {
  const { listingId } = useParams<{ listingId: string }>()
  const [listing, setListing] = useState<APIFeatureType>()

  const { status, value } = useAsync(() => getListingById(listingId))

  useEffect(() => {
    if (value) setListing(value)
  }, [value])

  if (status === 'error')
    return (
      <div className="ListingView__error">
        <Text on="white" kind="warning">
          Error retrieving this listing
        </Text>
      </div>
    )
  if (!listing || status === 'pending') return <Loading />

  const { updated, street, city, zip, sub_divison } = listing

  return (
    <div className="ListingView">
      <div className="ListingView__go-back">
        <Button
          size="s"
          kind="text"
          to="/listings"
          iconLeft={<FontAwesomeIcon icon={faChevronLeft} />}
          UNSAFE_className="ListingView__go-back-btn"
        >
          Go back
        </Button>
      </div>
      <PageHeader
        title={
          <FlexBox>
            {street}
            <Spacer />
            <div className="ListingView__status">{listing.status}</div>
          </FlexBox>
        }
        subTitle={`Last Updated ${getLocalDate(updated)} - ${getLocalTime(
          updated,
        )}`}
        actions={[
          <FlexBox flexWrap="wrap">
            <LabelValuePair label="CITY" value={city} />
            <Spacer width="2rem" />
            <LabelValuePair label="ZIPCODE" value={zip} />
            <Spacer width="2rem" />
            <LabelValuePair label="SUBDIVISION" value={sub_divison} />
            <Spacer width="3rem" />
          </FlexBox>,
          <Button size="l" iconLeft={<FontAwesomeIcon icon={faTrash} />} />,
        ]}
      />
      <div className="ListingView__body">
        <div className="ListingView__left-column">
          <ListingDetails listing={listing} />
        </div>
        <div className="ListingView__right-column">
          <ListingPhotosMap listing={listing} />
        </div>
      </div>
    </div>
  )
}
