export const verticalStackingOptions = {
  legend: {
    display: false,
  },
  tooltips: {
    enabled: false,
  },
  events: [],
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        stacked: true,
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          display: false,
        },
        stacked: true,
      },
    ],
  },
}

export const multiOverlappingOptions = {
  legend: {
    position: 'bottom',
  },
  scales: {
    xAxes: [{ stacked: true }],
    yAxes: [
      {
        stacked: false,
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
}
