import React from 'react'
import './AudienceResultsCount.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

export const AudienceResultsCount = ({
  faIcon,
  children,
}: {
  faIcon?: IconDefinition
  children: string
}) => {
  return (
    <div className="AudienceResultsCount">
      {faIcon && (
        <FontAwesomeIcon icon={faIcon} className="AudienceResultsCount__icon" />
      )}
      {children}
    </div>
  )
}
