import React, { useState, useEffect } from 'react'
import { PageHeader, TableSelectionWrapper, Loading } from 'Components'
import { Button, Table, TextInput, Text } from '@eltoro-ui/components'
import { useAsync } from '@eltoro-ui/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHouse,
  faHeart,
  faPlus,
  faSearch,
  faHome,
} from '@fortawesome/pro-solid-svg-icons'
/* import { mockLocation } from './mockLocation.ts' */
import mockLocation from 'Requests/Fixtures/Unused Fixtures/Listings.json'
import { APIDataType, APIFeatureType } from 'types'
import { getMapListings } from 'Requests'
import './MyListings.scss'

export const MyListings = () => {
  const [selections, setSelections] = useState<APIFeatureType[]>([])
  const [listings, setListings] = useState<APIFeatureType[] | null>([])
  const [search, setSearch] = useState('')

  const { status, value } = useAsync<APIDataType>(() =>
    getMapListings(mockLocation.data.listings),
  )

  useEffect(() => {
    setListings(value?.data.listings || [])
  }, [value])

  return (
    <div className="MyListings">
      <PageHeader
        icon={<FontAwesomeIcon icon={faHouse} />}
        title="My Listings"
        subTitle={
          <span>
            You have <b>{listings?.length} Listings</b>
          </span>
        }
        actions={[
          <TextInput
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            icon={<FontAwesomeIcon icon={faSearch} />}
          />,
          <Button iconLeft={<FontAwesomeIcon icon={faHeart} />}>
            Advertise Your Listings
          </Button>,
          <Button kind="primary" iconLeft={<FontAwesomeIcon icon={faPlus} />}>
            Add Listing
          </Button>,
        ]}
      />
      {status === 'pending' && <Loading />}
      {!listings && status === 'success' && (
        <div className="MyListings__empty">
          <FontAwesomeIcon icon={faHome} size="10x" />
          <Text on="grey-050">No listings to display.</Text>
        </div>
      )}
      {listings && status === 'success' && (
        <TableSelectionWrapper
          selectionType="listing"
          selections={selections}
          actions={[
            <button type="button" className="Campaigns__select-action">
              <Text on="tertiary-300" weight="bold">
                Delete
              </Text>
            </button>,
          ]}
        >
          <Table
            rows={listings || []}
            columns={[
              {
                path: 'street',
                label: 'Address',
                width: '20%',
                RowCell: (listing) => <b>{listing.street}</b>,
              },
              { path: 'city', label: 'City' },
              { path: 'zip', label: 'Zip' },
              { path: 'sub_divison', label: 'Subdivision Name', width: '20%' },
              {
                path: 'listing_price',
                label: 'Current Price',
                RowCell: (listing) => (
                  <b>{`$${listing.listing_price.toLocaleString()}`}</b>
                ),
              },
              {
                path: 'size',
                label: 'Size',
                RowCell: (listing) =>
                  listing.size ? `${listing.size} SQFT` : '',
              },
              { path: 'beds', label: 'Be' },
              { path: 'baths', label: 'Ba' },
              { path: 'year_built', label: 'Year' },
              {
                path: 'status',
                label: 'Status',
                RowCell: (listing) => (
                  <div className="MyListings__status-badge">
                    {listing.status}
                  </div>
                ),
              },
              {
                path: '',
                RowCell: (listing) => (
                  <Button to={`/listings/${listing.mls_id}`}>View</Button>
                ),
              },
            ]}
            onSelect={setSelections}
          />
        </TableSelectionWrapper>
      )}
    </div>
  )
}
