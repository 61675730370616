import axios, { AxiosResponse } from 'axios'
import { ParsedResType } from 'types'
import { getAuthHeader } from 'Tools'
import { mockApi } from './Mock/mockApi'

const root =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_PROXY
    : process.env.REACT_APP_API

const baseUrl = `${root}/api/v1`

const resParser = (res: AxiosResponse<string | Object>): ParsedResType => {
  const parsedData =
    typeof res.data === 'string' ? JSON.parse(res.data) : res.data
  return { data: parsedData }
}
if (process.env.NODE_ENV !== 'production' && !process.env.REACT_APP_IS_LIVE) {
  mockApi()
}

const requests = {
  get: async (path: string) => {
    return axios
      .get(`${baseUrl}${path}`)
      .then((res) => {
        return resParser(res)
      })
      .catch((err) => err.response.data)
  },
  post: async (path: string, body: object) => {
    const url = `${baseUrl}${path}`

    return axios
      .post(url, body)
      .then((res) => {
        return resParser(res)
      })
      .catch((err) => err.response.data)
  },
  patch: async (path: string, body: object) => {
    const url = `${baseUrl}${path}`
    return axios
      .patch(url, body)
      .then((res) => {
        return resParser(res)
      })
      .catch((err) => err.response.data)
  },
}

const http = requests

export default http
