import React from 'react'
import { Spacer } from '@eltoro-ui/components'
import { Section } from 'Components'
import { APIFeatureType } from 'types'
import { ListingMap } from '../ListingMap'
import './ListingPhotosMap.scss'

export const ListingPhotosMap = ({ listing }: { listing?: APIFeatureType }) => {
  if (!listing) return null

  // Generate temporary photo thumbnails
  const getPhotoThumbnails = () => {
    const photos = []
    for (let i = 0; i < 6; i += 1) {
      photos.push(
        <div className="ListingPhotosMap__photo-thumbnail" key={i}>
          <img
            src={`https://via.placeholder.com/640x480?text=Listing+Photo+${
              i + 1
            }`}
            alt="listing"
            className="ListingPhotosMap__img"
          />
        </div>,
      )
    }
    return photos
  }

  return (
    <div className="ListingPhotosMap">
      <Section title="Photos and Map">
        <div className="ListingPhotosMap__body">
          <ListingMap listing={listing} />
          <Spacer />
          <div className="ListingPhotosMap__photos">{getPhotoThumbnails()}</div>
        </div>
      </Section>
    </div>
  )
}
