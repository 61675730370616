import React, { useState } from 'react'
import './OnboardingModal.scss'
import { Modal, Button, Text } from '@eltoro-ui/components'
import { PersonalInfoForm, RealEstateInfoForm } from 'Components'
import { UserInfoType } from 'types'

export const OnboardingModal = ({
  setModalOpen,
}: {
  setModalOpen: (value: boolean) => void
}) => {
  const emptyUser = {
    personalInfo: {
      name: '',
      email: '',
      agency: '',
    },
    realEstateInfo: {
      territory: '',
      experience: '',
      status: '',
    },
  }
  const [currentUser, setCurrentUser] = useState<UserInfoType>(emptyUser)
  const [modalStep, setModalStep] = useState<number>(1)

  const populatePersonalInfo = (
    name: string,
    email: string,
    agency: string,
  ) => {
    setCurrentUser({
      ...currentUser,
      personalInfo: {
        name,
        email,
        agency,
      },
    })
  }

  const populateRealEstateInfo = (
    territory: string,
    experience: string,
    status: string,
  ) => {
    setCurrentUser({
      ...currentUser,
      realEstateInfo: {
        territory,
        experience,
        status,
      },
    })
  }
  return (
    <Modal>
      {modalStep === 1 && (
        <PersonalInfoForm
          user={currentUser}
          onSubmit={(value) => {
            populatePersonalInfo(value.name, value.email, value.agency)
            setModalStep(2)
          }}
        />
      )}
      {modalStep === 2 && (
        <RealEstateInfoForm
          user={currentUser}
          onSubmit={(value) => {
            populateRealEstateInfo(
              value.territory,
              value.experience,
              value.workStatus,
            )
            setModalStep(3)
          }}
          onBack={(value) => {
            populateRealEstateInfo(
              value.territory,
              value.experience,
              value.workStatus,
            )
            setModalStep(1)
          }}
        />
      )}
      {modalStep === 3 && (
        <div className="OnboardingModal__welcome">
          <div className="OnboardingModal__welcomeHeader">
            <div>
              <Text on="white" weight="bold" size="l">
                Congratulations!
              </Text>
            </div>
            <div>
              <Text on="white" weight="bold" size="xxxl">
                Welcome to Beewo
              </Text>
            </div>
          </div>
          <Button
            kind="primary"
            onClick={() => {
              setModalOpen(false)
              setModalStep(1)
            }}
          >
            Get Started
          </Button>
        </div>
      )}
    </Modal>
  )
}
