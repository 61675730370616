import React, { useRef, useState, useEffect } from 'react'
import { Modal, Text } from '@eltoro-ui/components'
import { useDebouncedCallback } from '@eltoro-ui/hooks'
import { MapLocationType } from 'types'
import './MapSearch.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { getLocationSearchResults } from 'Helpers'

export const MapSearch = ({
  onSelect,
}: {
  onSelect: (location: MapLocationType) => void
}) => {
  const [isActive, setIsActive] = useState(false)

  return (
    <div className="MapSearch">
      <button
        type="button"
        className="MapSearch__button"
        onClick={() => setIsActive(!isActive)}
      >
        <FontAwesomeIcon icon={faSearch} />
        Add a location
      </button>

      {isActive && (
        <MapSearchModal
          onSelect={onSelect}
          onClose={() => setIsActive(false)}
        />
      )}
    </div>
  )
}

const MapSearchModal = ({
  onSelect,
  onClose,
}: {
  onSelect: (location: MapLocationType) => void
  onClose: () => void
}) => {
  const [suggestions, setSuggestions] = useState<MapLocationType[]>()
  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => inputRef.current?.focus(), [])

  const getLocationsDebounced = useDebouncedCallback(async (query: string) => {
    const results = await getLocationSearchResults(query)
    setSuggestions(results)
  }, 200)

  return (
    <Modal positionFromTop="6rem" offClick={onClose}>
      <div className="MapSearch__model-content">
        <div className="MapSearch__search">
          <FontAwesomeIcon icon={faSearch} className="MapSearch__icon" />
          <input
            placeholder="Search for a location"
            ref={inputRef}
            className="MapSearch__input"
            type="text"
            onChange={(e) => getLocationsDebounced(e.target.value)}
          />
        </div>
        <Text on="white" kind="subdued" size="xs">
          Search by city, zip, etc.
        </Text>

        {!!suggestions && suggestions.length > 0 && (
          <ul className="MapSearch__suggestions">
            {suggestions.map((suggestion) => (
              <li key={suggestion.raw.place_id}>
                <button
                  className="MapSearch__suggestion-button"
                  type="button"
                  onClick={() => {
                    onSelect(suggestion)
                    onClose()
                  }}
                >
                  {suggestion.label}
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
    </Modal>
  )
}
