import React, { useState } from 'react'
import { Button, Spacer } from '@eltoro-ui/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons'
import { PaymentProfileType } from 'types'
import { AddCardForm } from 'Components'
import { FormObjectType } from '@eltoro-ui/hooks'
import { SelectCard, ConfirmPurchase } from './components'
import { CreditBundleType } from '..'
import './PurchaseBundle.scss'

export const PurchaseBundle: React.FC<{
  bundle: CreditBundleType
  onPurchaseExit: () => void
  onSubmitPayment: (profile: PaymentProfileType) => void
}> = ({ bundle, onPurchaseExit, onSubmitPayment }) => {
  const [profileSelected, setProfileSelected] = useState<PaymentProfileType>()
  const [step, setStep] = useState<
    'select_card' | 'add_card' | 'confirm_purchase'
  >('select_card')

  const GoBackButton = () => {
    const handleGoBack = () => {
      if (step === 'add_card' || step === 'confirm_purchase')
        return () => setStep('select_card')

      return () => onPurchaseExit()
    }

    return (
      <Button
        onClick={handleGoBack()}
        kind="text"
        iconLeft={<FontAwesomeIcon icon={faChevronLeft} />}
      >
        Go back
      </Button>
    )
  }

  const handleSubmitCard = (profile: PaymentProfileType) => {
    setProfileSelected(profile)
    setStep('confirm_purchase')
  }

  const handleOnSaveCardForm = (formData: FormObjectType) => {
    const {
      card_number,
      city,
      expiry,
      firstName,
      lastName,
      nick_name,
      state,
      street_address,
      zip,
    } = formData

    // TODO: Submit card details to create profile
    // Temporary new profile
    const newPaymentProfile: PaymentProfileType = {
      _id: 'kadsjfkl',
      cc_details: {
        nick_name,
        firstName,
        lastName,
        last_four: card_number.slice(-4),
        exp_date: expiry,
      },
      cc_address: {
        address: street_address,
        city,
        state,
        zip,
      },
    }

    handleSubmitCard(newPaymentProfile)
  }

  return (
    <div className="PurchaseBundle">
      <GoBackButton />
      <Spacer />
      {step === 'select_card' && (
        <SelectCard
          onCardSubmit={(profile) => handleSubmitCard(profile)}
          onAddCard={() => setStep('add_card')}
        />
      )}
      {step === 'add_card' && <AddCardForm onSave={handleOnSaveCardForm} />}
      {step === 'confirm_purchase' && profileSelected && (
        <ConfirmPurchase
          paymentProfile={profileSelected}
          bundle={bundle}
          onSubmitPayment={() => onSubmitPayment(profileSelected)}
        />
      )}
    </div>
  )
}
