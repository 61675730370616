import React from 'react'
import { MapLocationType } from 'types'
import './LocationLabels.scss'
import { LocationLabel } from '..'

export const LocationLabels = ({
  locations,
  onRemove,
}: {
  locations: MapLocationType[]
  onRemove: (id: number) => void
}) => {
  return (
    <div className="LocationLabels">
      {locations.map((selectedLocation) => (
        <LocationLabel
          key={selectedLocation.raw.place_id}
          location={selectedLocation}
          onRemove={onRemove}
        />
      ))}
    </div>
  )
}
