import React from 'react'
import './CreativeTypeBadge.scss'
import { Text } from '@eltoro-ui/components'

export const CreativeTypeBadge = ({
  onClick,
  icon,
  text,
}: {
  onClick: () => void
  icon: JSX.Element
  text: string
}) => {
  return (
    <button type="button" className="CreativeTypeBadge" onClick={onClick}>
      <div className="CreativeTypeBadge__icon">{icon}</div>
      <Text on="grey-100">{text}</Text>
    </button>
  )
}
