import React from 'react'
import './BuzzScoreFilters.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

export type BuzzFilterType = {
  label: string
  color: string
  test: (buzzScore: number) => boolean
}

export const BuzzScoreFilters = ({
  filters,
  activeFilters,
  onChange,
}: {
  filters: BuzzFilterType[]
  activeFilters: BuzzFilterType[]
  onChange: (newActiveFilters: BuzzFilterType[]) => void
}) => {
  const isActive = (filter: BuzzFilterType) => {
    return activeFilters.some(
      (activeFilter) => activeFilter.label === filter.label,
    )
  }

  const handleFilterClick = (clickedFilter: BuzzFilterType) => {
    if (isActive(clickedFilter)) {
      onChange(
        activeFilters.filter((filter) => filter.label !== clickedFilter.label),
      )
    } else {
      onChange([...activeFilters, clickedFilter])
    }
  }

  return (
    <div className="BuzzScoreFilters">
      <div className="BuzzScoreFilters__filters">
        {filters.map((filter) => (
          <div
            className={`BuzzScoreFilters__filter${
              isActive(filter) ? ' BuzzScoreFilters__filter--is-active' : ''
            }`}
            key={filter.label}
          >
            <button
              className="BuzzScoreFilters__button"
              type="button"
              style={{
                backgroundColor: isActive(filter) ? filter.color : 'white',
                borderColor: filter.color,
              }}
              onClick={() => handleFilterClick(filter)}
            >
              <FontAwesomeIcon
                className="BuzzScoreFilters__check"
                icon={faCheck}
              />
            </button>
          </div>
        ))}
      </div>
      <div className="BuzzScoreFilters__labels">
        <span>{filters[0].label}</span>
        <span>{filters.slice(-1)[0].label}</span>
      </div>
    </div>
  )
}
