import React from 'react'
import { Button, GridBox, Text } from '@eltoro-ui/components'
import './UploadBannerModal.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/pro-solid-svg-icons'
import { Uploader } from 'Components/Uploader'
import { browserLog } from 'Tools'
import creativePlaceholders from './CreativePlaceholders.json'
import { UploadAreaLayout, UploadTypes } from '../UploadAreaLayout'

export const UploadBannerModal = ({
  onClickOutside,
}: {
  onClickOutside: () => void
}) => {
  return (
    <div className="UploadBannerModal">
      <GridBox justifyItems="flex-start" gap="0.5rem">
        <Text tag="div" on="white" size="xxl">
          STEP 1: <b>Download Our Banner Templates</b>
        </Text>
        <Button kind="text" iconLeft={<FontAwesomeIcon icon={faDownload} />}>
          Download Banner Template
        </Button>
      </GridBox>
      <div className="UploadBannerModal__hl" />
      <GridBox gap="0.5rem">
        <Text tag="div" on="white" size="xxl">
          STEP 2: <b>Consider Using Multiple Banner Sizes</b>
        </Text>
        <GridBox gap="2rem" gridTemplateColumns="1fr 1fr">
          {creativePlaceholders.map((creative) => (
            <GridBox
              key={creative.id}
              gridTemplateColumns="min-content auto"
              alignItems="center"
              gap="1rem"
            >
              <div
                className="UploadBannerModal__creative-placeholder"
                style={{ height: creative.height, width: creative.width }}
              />
              <Text on="white">
                <b>{creative.actualDimensions}</b>
                {creative.text}
                {!!creative.textSecondary && <br />}
                {!!creative.textSecondary && creative.textSecondary}
              </Text>
            </GridBox>
          ))}
        </GridBox>
      </GridBox>
      <div className="UploadBannerModal__hl" />
      <GridBox justifyItems="flex-start" gap="0.5rem">
        <Text tag="div" on="white" size="xxl">
          STEP 3: <b>Upload Your Banner Creatives</b>
        </Text>
      </GridBox>
      <Uploader type="banner" onDropFile={(files) => browserLog.info(files)}>
        <UploadAreaLayout uploadType={UploadTypes.banner} />
      </Uploader>
      <div className="UploadBannerModal__footer">
        <Button
          kind="text"
          UNSAFE_className="UploadBannerModal__cancel-button"
          onClick={onClickOutside}
        >
          Cancel
        </Button>
        <Button kind="primary">Confirm</Button>
      </div>
    </div>
  )
}
