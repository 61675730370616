export const savedAudiences = [
  {
    title: 'audience1',
  },
  {
    title: 'audience2',
  },
  {
    title: 'audience3',
  },
  {
    title: 'audience4',
  },
]

export const finishedCampaigns = [
  {
    title: 'finishedcampaign1',
  },
  {
    title: 'finishedcampaign2',
  },
  {
    title: 'finishedcampaign3',
  },
  {
    title: 'finishedcampaign4',
  },
  {
    title: 'finishedcampaign5',
  },
  {
    title: 'finishedcampaign6',
  },
  {
    title: 'finishedcampaign7',
  },
  {
    title: 'finishedcampaign8',
  },
]

export const inProgressCampaigns = [
  {
    status: 'Draft',
  },
  {
    status: 'Draft',
  },
  {
    status: 'Review',
  },
  {
    status: 'Serving',
  },
  {
    status: 'Review',
  },
  {
    status: 'Serving',
  },
  {
    status: 'Serving',
  },
  {
    status: 'Draft',
  },
  {
    status: 'Serving',
  },
  {
    status: 'Serving',
  },
  {
    status: 'Review',
  },
  {
    status: 'Review',
  },
  {
    status: 'Review',
  },
  {
    status: 'Draft',
  },
  {
    status: 'Serving',
  },
  {
    status: 'Serving',
  },
]

export const verticalData = {
  datasets: [
    {
      label: 'Draft',
      data: [13],
      backgroundColor: '#D6E9C6', // green
      hoverBackgroundColor: '#D6E9C6',
    },
    {
      label: 'Review',
      data: [34],
      backgroundColor: '#FAEBCC', // yellow
      hoverBackgroundColor: '#FAEBCC',
    },
    {
      label: 'Serving',
      data: [53],
      backgroundColor: '#EBCCD1', // red
      hoverBackgroundColor: '#EBCCD1',
    },
  ],
}

export const multiData = {
  labels: ['Q2-20', 'Q3-20', 'Q4-20', 'Q1-21'],
  datasets: [
    {
      label: 'Clicks',
      data: [3400, 750, 2400, 4000],
      backgroundColor: 'white',
      hoverBackgroundColor: 'white',
    },
    {
      label: 'Impressions',
      data: [4900, 4000, 10000, 5500],
      backgroundColor: 'black',
      hoverBackgroundColor: 'black',
    },
  ],
}
