import React from 'react'
import { Button, FlexBox, GridBox, Text } from '@eltoro-ui/components'
import { faEdit, faUpload } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './UploadOrBuild.scss'

export const UploadOrBuild = ({
  onClickUpload,
  onClickBuild,
}: {
  onClickUpload: () => void
  onClickBuild: () => void
}) => {
  return (
    <div className="UploadOrBuild">
      <GridBox
        gridTemplateColumns="1fr 1fr"
        gap="1rem"
        alignItems="center"
        justifyItems="center"
      >
        <GridBox gap="0.25rem">
          <Text on="white" weight="bold">
            Upload Your Own
          </Text>
          <Text on="white" size="s">
            Download our template and upload your creative
          </Text>
        </GridBox>
        <div className="UploadOrBuild__action-button">
          <Button
            kind="primary"
            onClick={onClickUpload}
            iconLeft={<FontAwesomeIcon icon={faUpload} />}
          >
            Upload Creative
          </Button>
        </div>
      </GridBox>
      <FlexBox justifyContent="center">
        <Text on="white">OR</Text>
      </FlexBox>
      <GridBox gridTemplateColumns="1fr 1fr" gap="1rem" alignItems="center">
        <GridBox gap="0.25rem">
          <Text on="white" weight="bold">
            Use Our Ad Builder
          </Text>
          <Text on="white" size="s">
            Use our builder to build your creative
          </Text>
        </GridBox>
        <div className="UploadOrBuild__action-button">
          <Button
            kind="primary"
            onClick={onClickBuild}
            iconLeft={<FontAwesomeIcon icon={faEdit} />}
          >
            Use Ad Builder
          </Button>
        </div>
      </GridBox>
    </div>
  )
}
