import React from 'react'
import './CreativeThumb.scss'

export const CreativeThumb = ({ src, alt }: { src: string; alt: string }) => {
  return (
    <div className="CreativeThumb">
      <img className="CreativeThumb__image" src={src} alt={alt} />
    </div>
  )
}
