import React from 'react'
import { PageHeader, Section } from 'Components'
import { Button, GridBox, Text } from '@eltoro-ui/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faSearch, faLock } from '@fortawesome/pro-solid-svg-icons'
import './CampaignDashboard.scss'
import { PieChart } from 'react-minimal-pie-chart'
import { Bar } from 'react-chartjs-2'
import {
  savedAudiences,
  finishedCampaigns,
  verticalData,
  multiData,
} from './fakeData'
import { multiOverlappingOptions, verticalStackingOptions } from './barOptions'

export const CampaignDashboard = () => {
  return (
    <div className="CampaignDashboard">
      <PageHeader
        title="Dashboard"
        subTitle="Last Updated 11/04/20 - 1:45 PM"
        actions={[
          <Button iconLeft={<FontAwesomeIcon icon={faSearch} />} />,
          <Button
            kind="primary"
            iconLeft={<FontAwesomeIcon icon={faPlus} />}
            to="/create-campaign/setup"
          >
            New Campaign
          </Button>,
        ]}
      />
      <div className="CampaignDashboard__body">
        <div className="CampaignDashboard__left-column">
          <GridBox gridTemplateColumns="1fr 1fr" gap="1rem" flexGrow={1}>
            <Section title="Campaign Type">
              <div className="CampaignDashboard__campaign-type">
                <div className="CampaignDashboard__campaign-type-text">
                  <Text size="xl" on="white">
                    20% - Video
                  </Text>
                  <br />
                  <Text size="xl" on="white">
                    25% - Direct Mail
                  </Text>
                  <br />
                  <Text size="xl" on="white">
                    55% - Banner Ad
                  </Text>
                </div>
                <PieChart
                  data={[
                    { title: 'Video', value: 20, color: '#E38627' },
                    { title: 'Direct Mail', value: 25, color: '#C13C37' },
                    { title: 'Banner Ad', value: 55, color: '#6A2135' },
                  ]}
                />
              </div>
            </Section>
            <Section title="# Of Saved Audiences">
              <div className="CampaignDashboard__faded-box">
                <Text size="xxxl" on="white">
                  {savedAudiences.length}
                </Text>
              </div>
            </Section>
            <Section title="In-Progress Campaign">
              <div className="CampaignDashboard__in-progress-campaign-body">
                <div className="CampaignDashboard__in-progress-campaign-titles">
                  {verticalData.datasets.map((value) => {
                    return (
                      <div key={value.label}>
                        <div
                          className="CampaignDashboard__in-progress-campaign-circle"
                          style={{
                            backgroundColor: value.backgroundColor,
                            marginRight: '1rem',
                          }}
                        />
                        <Text size="xl" on="white">
                          {value.data} - {value.label}
                        </Text>
                      </div>
                    )
                  })}
                </div>
                <div className="CampaignDashboard__in-progress-campaign-bar">
                  <Bar
                    data={verticalData}
                    options={verticalStackingOptions}
                    height={200}
                    width={150}
                  />
                </div>
              </div>
            </Section>
            <Section title="# Of Finished Campaigns">
              <div className="CampaignDashboard__faded-box">
                <Text size="xxxl" on="white">
                  {finishedCampaigns.length}
                </Text>
              </div>
            </Section>
          </GridBox>
          <br />
          <Section title="Current Balance">
            <div className="CampaignDashboard__balance-section">
              <Text size="xxl" on="white">
                3000
              </Text>
              <Button iconLeft={<FontAwesomeIcon icon={faLock} />} disabled>
                Buy Credits
              </Button>
            </div>
          </Section>
        </div>
        <div className="CampaignDashboard__right-column">
          <Section title="Campaign Performance">
            <div className="CampaignDashboard__campaign-performance">
              <Bar
                data={multiData}
                options={multiOverlappingOptions}
                height={320}
              />
            </div>
          </Section>
        </div>
      </div>
    </div>
  )
}
