import {
  CampaignType,
  AudienceType,
  CreativeType,
  RequestType,
  getDataType,
} from 'types'
import requests from '../http'

export const createCampaign = async (
  body: Partial<CampaignType>,
): Promise<RequestType<CampaignType>> => requests.post(`/campaign`, body)

export const getCampaigns = async (
  skip?: number | null,
  limit?: number | null,
): Promise<RequestType<getDataType<CampaignType[]>>> => {
  const req =
    skip && limit ? `/campaigns?skip=${skip}&limit=${limit}` : '/campaigns'
  return requests.get(req)
}

export const getCampaignById = async (
  campaign_id: string,
): Promise<RequestType<CampaignType>> => {
  return requests.get(`/campaign/${campaign_id}`)
}

export const updateCampaign = async (
  campaign_id: string,
  body: Partial<CampaignType>,
): Promise<RequestType<CampaignType>> => {
  return requests.patch(`/campaign/${campaign_id}`, body)
}

export const attachAudience = async (
  campaign_id: string,
  body: AudienceType[],
): Promise<RequestType<CampaignType>> => {
  return requests.patch(`/campaign/attach-audience/${campaign_id}`, body)
}

export const detachAudience = async (
  campaign_id: string,
  body: AudienceType[],
): Promise<RequestType<CampaignType>> => {
  return requests.patch(`/campaign/detach-audience/${campaign_id}`, body)
}

export const attachCreative = async (
  campaign_id: string,
  body: CreativeType[],
): Promise<RequestType<CampaignType>> => {
  return requests.patch(`/campaign/attach-creative/${campaign_id}`, body)
}

export const detachCreative = async (
  campaign_id: string,
  body: CreativeType[],
): Promise<RequestType<CampaignType>> => {
  return requests.patch(`/campaign/detach-creative/${campaign_id}`, body)
}
