import React, { FC } from 'react'
import { Text } from '@eltoro-ui/components'
import './ProgressIndicator.scss'

type ProgressIndicatorType = {
  current: number
  end: number
  prefix?: string
}

export const ProgressIndicator: FC<ProgressIndicatorType> = ({
  current,
  end,
  prefix = '',
}) => {
  const progressPercent = () => {
    if ((current / end) * 100 > 100) return 100
    if ((current / end) * 100 < 0) return 0
    return (current / end) * 100 || 0
  }
  const displayText = () => {
    if (!current || !end) return '---'
    return (
      <Text on="white" kind="subdued">
        <b>{`${prefix}${current.toLocaleString()}`}</b> of{' '}
        {`${prefix}${end.toLocaleString()}`}
      </Text>
    )
  }

  return (
    <div className="ProgressIndicator">
      <div className="ProgressIndicator__text">{displayText()}</div>
      <div className="ProgressIndicator__meter">
        <div
          className="ProgressIndicator__progress"
          style={{ width: `${progressPercent()}%` }}
        />
      </div>
    </div>
  )
}
