import React from 'react'
import { PageHeader } from 'Components'
import { Button } from '@eltoro-ui/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHeart,
  faPlus,
  faSearch,
  faUser,
} from '@fortawesome/pro-solid-svg-icons'

export const MyContacts = () => {
  return (
    <div className="MyContacts">
      <PageHeader
        icon={<FontAwesomeIcon icon={faUser} />}
        title="My Contacts"
        subTitle={
          <span>
            You have <b>843 Contacts</b>
          </span>
        }
        actions={[
          <Button iconLeft={<FontAwesomeIcon icon={faSearch} />} />,
          <Button iconLeft={<FontAwesomeIcon icon={faHeart} />}>
            Advertise To Your Contacts
          </Button>,
          <Button kind="primary" iconLeft={<FontAwesomeIcon icon={faPlus} />}>
            Add Contacts
          </Button>,
        ]}
      />
    </div>
  )
}
