import React from 'react'
import { GridBox, Text } from '@eltoro-ui/components'
import './PageHeader.scss'

export const PageHeader: React.FC<{
  icon?: JSX.Element
  title: JSX.Element | string
  subTitle: JSX.Element | string
  actions?: React.ReactNode
}> = ({ icon, title, subTitle, actions }) => {
  return (
    <div className="PageHeader">
      <div>
        <GridBox gap=".5rem" gridTemplateColumns="max-content max-content">
          {icon && (
            <Text size="xxxl" on="white">
              {icon}
            </Text>
          )}
          <Text size="xxxl" weight="bold" on="white">
            {title}
          </Text>
        </GridBox>
        <Text on="white">{subTitle}</Text>
      </div>
      <div className="PageHeader__actions">{actions}</div>
    </div>
  )
}
