import React from 'react'
import './LensSidebarHeader.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons'
import { Link } from 'react-router-dom'

export const LensSidebarHeader = ({
  heading,
  subHeading,
}: {
  heading: string
  subHeading?: string
}) => {
  return (
    <div className="LensSidebarHeader">
      <Link className="LensSidebarHeader__back-button" to="/prospects">
        <FontAwesomeIcon icon={faChevronLeft} /> Back
      </Link>
      <div className="LensSidebarHeader__divider" />
      <div className="LensSidebarHeader__heading">{heading}</div>
      {subHeading && (
        <div className="LensSidebarHeader__sub-heading">{subHeading}</div>
      )}
    </div>
  )
}
