import React, { FC, useState, useCallback } from 'react'
import { createPortal } from 'react-dom'
import './Tags.scss'

type TagsType = {
  tags: string[]
}

export const Tags: FC<TagsType> = ({ tags }) => {
  const [showOverflow, setShowOverflow] = useState(false)
  const [rect, setRect] = useState<DOMRect>()

  const measuredWrapperRef = useCallback(
    (node: HTMLDivElement) => {
      if (node !== null) {
        setRect(node.getBoundingClientRect())
      }
    },
    [showOverflow],
  )

  return (
    <div className="Tags" ref={measuredWrapperRef}>
      {tags.slice(0, 2).map((tag, i) => (
        <div key={tag + i} className="Tags__tag">
          {tag}
        </div>
      ))}
      {tags.slice(2, tags.length - 1).length > 0 && (
        <button
          type="button"
          className="Tags__overflow"
          onMouseEnter={() => setShowOverflow(true)}
          onMouseLeave={() => setShowOverflow(false)}
          onFocus={() => setShowOverflow(true)}
          onBlur={() => setShowOverflow(false)}
        >
          +{tags.slice(1, tags.length - 1).length}
          {showOverflow &&
            createPortal(
              <div
                className="Tags__tooltip"
                style={{
                  width: rect ? rect.width + 5 : undefined,
                  top: rect ? rect.top - 5 : undefined,
                  left: rect ? rect.left - 5 : undefined,
                }}
              >
                {tags.map((tag, i) => (
                  <div key={tag + i} className="Tags__tag">
                    {tag}
                  </div>
                ))}
              </div>,
              document.body,
            )}
        </button>
      )}
    </div>
  )
}
