import React, { FC } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import './App.scss'
import './theme.css'
import './data-colors.css'
import AuthorizedRoute from 'AuthorizedRoute'
import { PageNotFound } from './Pages'
import { AppContextProvider } from './AppContext'
import routes from './routes'

type RouteType = {
  path: string
  component: FC
  public: boolean
}

const App: FC = () => {
  return (
    <div className="App">
      <AppContextProvider>
        <Router>
          <Switch>
            {routes.map((publicRoute: RouteType, i: number) => {
              return publicRoute.public ? (
                <Route
                  key={i}
                  exact={publicRoute.path === '/'}
                  path={publicRoute.path}
                  component={publicRoute.component}
                />
              ) : (
                <AuthorizedRoute
                  exact={
                    publicRoute.path === '/' ||
                    publicRoute.path === '/listings' ||
                    publicRoute.path === '/saved-audiences' ||
                    publicRoute.path === '/campaigns'
                  }
                  key={i}
                  path={publicRoute.path}
                  component={publicRoute.component}
                />
              )
            })}
            <Route>
              <PageNotFound />
            </Route>
          </Switch>
        </Router>
      </AppContextProvider>
    </div>
  )
}

export default App
