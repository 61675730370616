import React, { useState, useEffect } from 'react'
import {
  MaxHeightContainer,
  Select,
  Spacer,
  SelectOptionType,
} from '@eltoro-ui/components'
import { faWalking } from '@fortawesome/free-solid-svg-icons'
import {
  MapMarker,
  AudienceResultsCard,
  AudienceResultsCount,
  SaveAudienceWizard,
  LocationLabels,
} from 'Pages/ProspectActivity/components'
import { Map, MapPopup, MapSearch, MapToolTip } from 'Components'
import { APIDataType, APIFeatureType } from 'types'
import { getMapListings } from 'Requests'
import { useLocations } from 'Hooks'
import { getIconBuzzColor } from 'Helpers'
import { browserLog } from 'Tools'
import {
  LensSectionHeading,
  LensSidebar,
  LensSidebarFooter,
  LensSidebarHeader,
} from '../components'
import './ComparableListingsLens.scss'

export const ComparableListingsLens = () => {
  const [features, setFeatures] = useState<APIFeatureType[]>([])
  const [radius, setRadius] = useState<SelectOptionType>()
  const [timeframe, setTimeframe] = useState<SelectOptionType>()
  const {
    locations,
    removeLocation,
    addLocation,
    hasLocations,
  } = useLocations()
  const totalProspects = features.reduce(
    (count, currFeature) => count + currFeature.unique_observations,
    0,
  )
  const hasFeatures = !!features && features.length > 0

  useEffect(() => {
    if (timeframe && radius && hasLocations) {
      getMapListings(locations).then((res: APIDataType) =>
        setFeatures(res.data.listings),
      )
    } else {
      setFeatures([])
    }
  }, [timeframe, hasLocations, locations, radius])

  return (
    <div className="ComparableListingsLens">
      <LensSidebar>
        <MaxHeightContainer
          fullHeight
          header={
            <LensSidebarHeader
              heading="Comparable Listings"
              subHeading="View all prospects seen in comparable listings to one of your listings."
            />
          }
          footer={
            hasFeatures && (
              <LensSidebarFooter>
                <AudienceResultsCard>
                  Potentially advertise to
                  <AudienceResultsCount faIcon={faWalking}>
                    {`${totalProspects.toLocaleString()} prospects`}
                  </AudienceResultsCount>
                  seen around <b>{features.length}</b> listings
                  <Spacer />
                  <SaveAudienceWizard
                    onSaveAndClose={(audienceDetails) =>
                      browserLog.info(audienceDetails)
                    }
                  />
                </AudienceResultsCard>
              </LensSidebarFooter>
            )
          }
        >
          <div className="ComparableListingsLens__sidebar-content">
            {!hasLocations ? (
              <MapSearch onSelect={addLocation} />
            ) : (
              <>
                <Spacer height="2rem" />
                <LensSectionHeading heading="Location:" />
                <LocationLabels
                  locations={locations}
                  onRemove={removeLocation}
                />
                <Spacer height="2rem" />
                <LensSectionHeading heading="Radius:" />
                <Select
                  options={[
                    { label: '5 miles' },
                    { label: '10 miles' },
                    { label: '15 miles' },
                  ]}
                  placeholder="Search radius from original location."
                  onChange={setRadius}
                  selectedOption={radius}
                  maxHeight={200}
                />
              </>
            )}
            {radius && (
              <>
                <Spacer height="2rem" />
                <LensSectionHeading heading="Timeframe:" />
                <Select
                  options={[
                    { label: '5 days' },
                    { label: '10 days' },
                    { label: '15 days' },
                  ]}
                  placeholder="Timeframe prospects visited you location."
                  onChange={setTimeframe}
                  selectedOption={timeframe}
                  maxHeight={200}
                />
              </>
            )}
          </div>
        </MaxHeightContainer>
      </LensSidebar>
      <div className="ComparableListingsLens__map">
        <Map
          features={features}
          locations={locations}
          marker={(f) => <MapMarker color={getIconBuzzColor(f)} />}
          popup={(f) => <MapPopup feature={f} />}
          tooltip={(f) => <MapToolTip feature={f} />}
        />
      </div>
    </div>
  )
}
