/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react'
import {
  Route,
  RouteProps,
  Redirect,
  RouteComponentProps,
} from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'
import {
  Header,
  HeaderLogo,
  HeaderNav,
  HeaderItem,
  HeaderDropdown,
  MaxHeightContainer,
} from '@eltoro-ui/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faUser, faPlus } from '@fortawesome/pro-solid-svg-icons'
import { ProfileDropdown } from 'Components'
import logo from './Images/Wood_FullLogo@2x.png'
import { DebugButtons } from './Tools'

type AuthorizedRouteProps = RouteProps & {
  component: FC<RouteComponentProps & any>
}

export const AuthorizedRoute = ({
  component: Component,
  ...routeProps
}: AuthorizedRouteProps) => {
  const { keycloak } = useKeycloak()
  const isLoggedIn = !!(
    keycloak.authenticated || localStorage.getItem('beewo_token')
  )

  const Bypass = () => {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: {
            redirectLink: routeProps?.location?.pathname,
          },
        }}
      />
    )
  }

  const prod = process.env.NODE_ENV === 'production'

  return (
    <Route
      {...routeProps}
      render={(props) =>
        isLoggedIn ? (
          <MaxHeightContainer
            fullHeight
            header={
              <Header>
                <HeaderLogo logo={logo} to="/dashboard" />
                <HeaderNav marginLeft="2rem" marginRight="2rem">
                  <HeaderItem to="/prospects">Prospect Activity</HeaderItem>
                  <HeaderDropdown name="My Campaigns">
                    <HeaderItem to="/campaign-dashboard">
                      Campaign Dashboard
                    </HeaderItem>
                    <HeaderItem to="/campaigns">Campaigns</HeaderItem>
                    <HeaderItem to="/saved-audiences">
                      Saved Audience
                    </HeaderItem>
                    <HeaderItem to="/create-campaign/setup">
                      <FontAwesomeIcon icon={faPlus} /> New Campaign
                    </HeaderItem>
                  </HeaderDropdown>
                  <HeaderItem to="/contacts">My Contacts</HeaderItem>
                  <HeaderItem to="/listings">My Listings</HeaderItem>
                </HeaderNav>
                <HeaderNav marginLeft="auto">
                  <HeaderItem
                    to="/alerts"
                    icon={<FontAwesomeIcon icon={faBell} />}
                  />
                  <HeaderDropdown
                    hasDropdownIcon={false}
                    icon={<FontAwesomeIcon icon={faUser} />}
                    position="left"
                  >
                    <ProfileDropdown />
                  </HeaderDropdown>
                  {!prod && <DebugButtons />}
                </HeaderNav>
              </Header>
            }
          >
            <Component {...props} />
          </MaxHeightContainer>
        ) : (
          <Bypass />
        )
      }
    />
  )
}

export default AuthorizedRoute
