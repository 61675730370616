import { AttachedCreativeType } from 'types'

export const creatives: AttachedCreativeType[] = [
  {
    _id: 1,
    name: 'sample_1',
    type: 'banner',
    src: 'https://via.placeholder.com/300x600',
  },
  {
    _id: 2,
    name: 'sample_2',
    type: 'directMail',
    src: 'https://via.placeholder.com/300x250',
  },
  {
    _id: 3,
    name: 'sample_3',
    type: 'video',
    src: 'https://via.placeholder.com/728x90',
  },
]
