import React, { FC } from 'react'
import { Button } from '@eltoro-ui/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import './SubscriptionCard.scss'

type SubscriptionCardType = {
  name: string
  description: string
  showButton?: boolean
  locked?: boolean // Gives locked appearance card and button within
  header?: string // For displaying another header above (current plan, upgrade, etc)
}

export const SubscriptionCard: FC<SubscriptionCardType> = ({
  name,
  description,
  showButton = true,
  locked = false,
  header,
}) => {
  return (
    <div className="SubscriptionCard">
      {header && <h3 className="SubscriptionCard__header">{header}</h3>}
      <div
        className={`SubscriptionCard__wrapper ${
          locked ? 'SubscriptionCard__wrapper--locked' : ''
        }`}
      >
        <h2 className="SubscriptionCard__title">{name}</h2>
        <p>{description}</p>
        {showButton && (
          <Button
            iconLeft={<FontAwesomeIcon icon={faLock} />}
            disabled={locked}
            UNSAFE_className="SubscriptionCard__button"
          >
            Select
          </Button>
        )}
      </div>
    </div>
  )
}
