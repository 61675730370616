import React, { useState } from 'react'
import { Button, FlexBox, GridBox, Spacer, Text } from '@eltoro-ui/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRight,
  faEnvelope,
  faImages,
  faStar,
  faVideo,
  faWalking,
} from '@fortawesome/free-solid-svg-icons'
import { CreditModal } from 'Components/CreditModal'
import { SummaryCreative, SummaryEditButton, SummaryTotal } from '.'
import './SummaryCart.scss'

type FakeCreativeType = {
  name: string
  cost: number
  impressions?: number
  households?: number
}

type FakeCampaignType = {
  prospects?: {
    name: string
    prospects: number
  }[]
  creatives: {
    video?: FakeCreativeType[]
    banner?: FakeCreativeType[]
    directMail?: FakeCreativeType
  }
}

export const SummaryCart: React.FC<{ campaign: FakeCampaignType }> = ({
  campaign,
}) => {
  const [creditModalOpen, setCreditModalOpen] = useState(false)

  const {
    creatives: { video, banner, directMail },
    prospects,
  } = campaign
  const reduceProspects = (p: { name: string; prospects: number }[]) => {
    return p.reduce((acc, cur) => acc + cur.prospects, 0)
  }
  const reduceImpressions = (creatives: FakeCreativeType[]) => {
    return creatives.reduce((acc, cur) => acc + (cur.impressions || 0), 0)
  }

  const reduceCost = (creatives: FakeCreativeType[]) => {
    return creatives.reduce((acc, cur) => acc + cur.cost, 0)
  }

  const hasCreatives = video || banner || directMail

  const totalCost =
    reduceCost(video || []) + reduceCost(banner || []) + (directMail?.cost || 0)

  // Temporary points balance
  const pointsBalance = 20300

  const getPointsNeeded = () => {
    if (totalCost > 0 && pointsBalance - totalCost < 0) {
      return Math.abs(pointsBalance - totalCost)
    }
    return undefined
  }

  return (
    <div className="SummaryCart">
      <FlexBox flexDirection="column" padding="1rem">
        <Text size="xxl" on="white" weight="bold">
          Summary
        </Text>
        <Text on="white" size="s">
          Donec id elit non porta gravida et eget metus
        </Text>
      </FlexBox>
      <div className="SummaryCart__header-item">
        <Text on="grey-100" weight="bold">
          Points Available
        </Text>
        <SummaryEditButton onClick={() => setCreditModalOpen(true)}>
          Buy Points
        </SummaryEditButton>
      </div>
      <div className="SummaryCart__body-item">
        <Text size="xl" on="grey-050" weight="bold">
          <FontAwesomeIcon icon={faStar} /> {pointsBalance.toLocaleString()}
        </Text>
      </div>
      {prospects && (
        <>
          <div className="SummaryCart__header-item">
            <Text on="grey-100" weight="bold">
              Saved Audience
            </Text>
            <SummaryEditButton onClick={() => {}}>Edit</SummaryEditButton>
          </div>
          <div className="SummaryCart__body-item">
            <Text on="grey-050">
              <FontAwesomeIcon icon={faWalking} />{' '}
              <b>{reduceProspects(prospects)} Prospects</b> - {prospects.length}{' '}
              audiences
            </Text>
          </div>
        </>
      )}
      {hasCreatives && (
        <>
          <div className="SummaryCart__header-item">
            <Text on="grey-100" weight="bold">
              Creative Type
            </Text>
            <SummaryEditButton onClick={() => {}}>Edit</SummaryEditButton>
          </div>
          <div className="SummaryCart__body-item">
            <GridBox
              gridTemplateColumns="3rem 1fr max-content"
              gap="1rem .5rem"
              alignItems="center"
            >
              {video && (
                <SummaryCreative icon={faVideo} cost={reduceCost(video)}>
                  <Text on="grey-050" weight="bold">
                    Video Ad ({video.length}x)
                  </Text>
                  <Text on="grey-050">
                    {reduceImpressions(video).toLocaleString()} impressions
                  </Text>
                  <Text on="grey-050" kind="subdued" size="s">
                    3x times per day x 14 days
                  </Text>
                </SummaryCreative>
              )}
              {banner && (
                <SummaryCreative icon={faImages} cost={reduceCost(banner)}>
                  <Text on="grey-050" weight="bold">
                    Digital Ad ({banner.length}x)
                  </Text>
                  <Text on="grey-050">
                    {reduceImpressions(banner).toLocaleString()} impressions
                  </Text>
                  <Text on="grey-050" kind="subdued" size="s">
                    3x times per day x 14 days
                  </Text>
                </SummaryCreative>
              )}
              {directMail && (
                <SummaryCreative icon={faEnvelope} cost={directMail.cost}>
                  <Text on="grey-050" weight="bold">
                    Direct Mail
                  </Text>
                  <Text on="grey-050">
                    {(directMail.households || 0).toLocaleString()} Households
                  </Text>
                </SummaryCreative>
              )}
            </GridBox>
          </div>
        </>
      )}
      {!!totalCost && (
        <FlexBox flexDirection="column" padding="1rem">
          <SummaryTotal total={totalCost} />
          <Spacer />
          <Button
            iconLeft={<FontAwesomeIcon icon={faArrowRight} />}
            size="xl"
            kind="primary"
            width="100%"
            onClick={() => (getPointsNeeded() ? setCreditModalOpen(true) : {})}
          >
            Checkout and Submit
          </Button>
          <Spacer />
          <Button kind="text">Set Your Price</Button>
        </FlexBox>
      )}
      {creditModalOpen && (
        <CreditModal
          offClick={() => setCreditModalOpen(false)}
          pointsNeeded={getPointsNeeded()}
        />
      )}
    </div>
  )
}
