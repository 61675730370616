import React from 'react'
import { GridBox, Spacer } from '@eltoro-ui/components'
import { Section, LabelValuePair, Notes } from 'Components'
import { APIFeatureType } from 'types'
import { browserLog } from 'Tools'

export const ListingDetails = ({ listing }: { listing?: APIFeatureType }) => {
  if (!listing) return null

  const { listing_price, size, year_built, beds, baths } = listing

  return (
    <div className="ListingDetails">
      <Section title="Information" dark>
        <div className="ListingDetails__body">
          <GridBox gap="2rem" gridTemplateColumns="1fr 1fr">
            <LabelValuePair
              label="PRICE"
              value={<b>{`$${listing_price?.toLocaleString()}`}</b>}
            />
            {size && (
              <LabelValuePair
                label="SIZE"
                value={`${size?.toLocaleString()} SQFT`}
              />
            )}
            <LabelValuePair label="YEAR BUILT" value={year_built} />
            <LabelValuePair label="BED" value={beds} />
            <LabelValuePair label="BATH" value={baths} />
          </GridBox>
          <Spacer height="2rem" />
          {/* TODO: Save notes to listing */}
          <Notes onSaveNotes={(notes) => browserLog.log(notes)} />
        </div>
      </Section>
    </div>
  )
}
