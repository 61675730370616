export const creditBundles = [
  {
    name: 'Bundle 1',
    stars: 1,
    credits: 30,
    price: 300,
  },
  {
    name: 'Bundle 2',
    stars: 4,
    credits: 112,
    price: 1000,
  },
  {
    name: 'Bundle 3',
    stars: 8,
    credits: 236,
    price: 2000,
    subText: '(Most popular)',
  },
  {
    name: 'Bundle 4',
    stars: 33,
    credits: 1000,
    price: 8000,
    subText: '(Best value)',
  },
]
