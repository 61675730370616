import React, { FC, useState } from 'react'
import { Modal, Form, FormItem, Button, TextInput } from '@eltoro-ui/components'
import { useForm, useValidatedMask } from '@eltoro-ui/hooks'
import { PaymentProfileType } from 'types'
import { browserLog } from 'Tools'
import { StateSelect } from 'Components'
import './EditCardModal.scss'

type EditCardModalType = {
  profile: PaymentProfileType
  offClick: () => void
}

export const EditCardModal: FC<EditCardModalType> = ({ profile, offClick }) => {
  const { cc_details, cc_address } = profile
  const [nick_name, setNickName] = useState(cc_details.nick_name || '')
  const [firstName, setFirstName] = useState(cc_details.firstName || '')
  const [lastName, setLastName] = useState(cc_details.lastName || '')
  const [street_address, setStreetAddress] = useState(cc_address.address || '')
  const [city, setCity] = useState(cc_address.city || '')
  const [state, setState] = useState(cc_address.state || '')
  const [zip, setZipCode, isValidZip] = useValidatedMask({
    initialState: cc_address.zip || '',
    validationMask: 'zip-code',
  })

  const { formData, isValidForm, touched } = useForm({
    nick_name,
    firstName,
    lastName,
    street_address,
    city,
    state,
    zip,
  })

  const onSubmit = () => {
    browserLog.log(formData)
    offClick()
  }

  const required = [
    'firstName',
    'lastName',
    'street_address',
    'city',
    'state',
    'zip',
  ]
  const { validForm, missingFields } = isValidForm([...required, isValidZip])

  return (
    <Modal
      header={`Editing ${profile?.cc_details?.nick_name}`}
      offClick={offClick}
    >
      <div className="EditCardModal">
        <Form
          onSubmit={onSubmit}
          required={required}
          valid={validForm}
          touched={touched}
          missingFields={missingFields}
        >
          <FormItem
            htmlFor="nick_name"
            label="Card nickname"
            errorMessage="Please enter a nickname"
            valid={!!nick_name}
          >
            <TextInput
              value={nick_name}
              onChange={(e) => setNickName(e.target.value)}
            />
          </FormItem>
          <FormItem
            htmlFor="firstName"
            label="First name"
            errorMessage="Please enter a first name"
            valid={!!firstName}
          >
            <TextInput
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </FormItem>
          <FormItem
            htmlFor="lastName"
            label="Last name"
            errorMessage="Please enter a last name"
            valid={!!lastName}
          >
            <TextInput
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </FormItem>
          <FormItem
            htmlFor="street_address"
            label="Street address"
            errorMessage="Please enter a street address"
            valid={!!street_address}
          >
            <TextInput
              value={street_address}
              onChange={(e) => setStreetAddress(e.target.value)}
            />
          </FormItem>
          <FormItem
            htmlFor="city"
            label="City"
            errorMessage="Please enter a city"
            valid={!!city}
          >
            <TextInput value={city} onChange={(e) => setCity(e.target.value)} />
          </FormItem>
          <FormItem
            htmlFor="state"
            label="State"
            errorMessage="Please enter a state"
            valid={!!state}
          >
            <StateSelect
              defaultValue={state}
              onChange={(value) => setState(value)}
            />
          </FormItem>
          <FormItem
            htmlFor="zip"
            label="Zip code"
            errorMessage="Please enter a valid zip code"
            valid={isValidZip}
          >
            <TextInput
              value={zip}
              onChange={(e) => setZipCode(e.target.value)}
            />
          </FormItem>
          <Button kind="danger">Delete card</Button>
          <Button
            type="submit"
            kind="primary"
            disabled={missingFields.length > 0}
          >
            Save changes
          </Button>
        </Form>
      </div>
    </Modal>
  )
}
