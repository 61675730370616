import React, { useState } from 'react'
import './SaveAudienceWizard.scss'
import { Button } from '@eltoro-ui/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import { AudienceDetailsType, SaveAudienceModal } from '..'

export const SaveAudienceWizard = ({
  onSaveAndClose,
}: {
  onSaveAndClose: (audienceDetails: AudienceDetailsType) => void
}) => {
  const [modalOpen, setModalOpen] = useState(false)

  const handleSaveAudience = (audienceDetails: AudienceDetailsType) => {
    onSaveAndClose(audienceDetails)
    // TODO: Call to save target
    setModalOpen(false)
  }
  return (
    <div className="SaveAudienceWizard">
      <Button
        size="xl"
        kind="primary"
        iconLeft={<FontAwesomeIcon icon={faHeart} />}
        onClick={() => setModalOpen(true)}
        width="100%"
      >
        Save Target Audience
      </Button>
      {modalOpen && (
        <SaveAudienceModal
          onClickOutside={() => setModalOpen(false)}
          onSave={handleSaveAudience}
        />
      )}
    </div>
  )
}
