import L from 'leaflet'
import { APIFeatureType } from 'types'
import { buzzFilters } from 'Pages/ProspectActivity/configs'
import { openStreepMapResolver } from 'Components'

export const getBounds = (lats: number[], longs: number[]) => {
  const upperCoords = L.latLng(Math.max(...lats), Math.max(...longs))
  const lowerCoords = L.latLng(Math.min(...lats), Math.min(...longs))
  return L.latLngBounds(upperCoords, lowerCoords)
}

export const getIconBuzzColor = (feature: APIFeatureType) => {
  let _color = ''
  buzzFilters.forEach((filter) => {
    if (filter.test(feature.activity_score)) {
      _color = filter.color
    }
  })
  return _color
}

export const getLocationByLatLong = async (
  longitude: number,
  latitude: number,
) => {
  const url = `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
  return fetch(encodeURI(url))
    .then((res) => res.json())
    .then((json) => openStreepMapResolver([json])[0])
}

export const getLocationSearchResults = async (query: string) => {
  const url = `https://nominatim.openstreetmap.org/search?countrycodes=us&format=json&q=${query}`
  return fetch(encodeURI(url))
    .then((res) => res.json())
    .then((json) => openStreepMapResolver(json))
}
