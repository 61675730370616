import { BuzzFilterType } from 'Components'

export const buzzFilters: BuzzFilterType[] = [
  {
    label: 'Super buzz',
    color: 'var(--color-data-warm-800)',
    test: (buzzScore) => buzzScore >= 0.75,
  },
  {
    label: 'High buzz',
    color: 'var(--color-data-warm-600)',
    test: (buzzScore) => buzzScore >= 0.5 && buzzScore < 0.75,
  },
  {
    label: 'Medium buzz',
    color: 'var(--color-data-warm-300)',
    test: (buzzScore) => buzzScore >= 0.25 && buzzScore < 0.5,
  },
  {
    label: 'Low buzz',
    color: 'var(--color-data-warm-200)',
    test: (buzzScore) => buzzScore < 0.25,
  },
]
