import React, { useState, useContext } from 'react'
import { GridBox, LargeIconButton, Text, Button } from '@eltoro-ui/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useKeycloak } from '@react-keycloak/web'
import { Section, OnboardingModal } from 'Components'
import { AppContext } from 'AppContext'
import {
  faGlobe,
  faLock,
  faExclamationTriangle,
  faHome,
  faMapPin,
  faUser,
} from '@fortawesome/pro-solid-svg-icons'
import { fixtureNotifications, fixtureCampaigns } from 'Requests/Fixtures'
import { Link } from 'react-router-dom'
import { fakeListings } from './fakeData'
import './Dashboard.scss'

const EmptySection: React.FC<{ message: string }> = ({ message }) => {
  return (
    <Text on="grey-100" textAlign="center">
      {message}
    </Text>
  )
}

const notifications = fixtureNotifications
const campaigns = fixtureCampaigns

export const DashboardPage = () => {
  // Modal currently only opens on button click...when we have a global user object can open if user is empty
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const { keycloak } = useKeycloak()
  const { setCurrentUser } = useContext(AppContext)
  if (keycloak.authenticated) {
    keycloak.loadUserProfile().then((data) => setCurrentUser(data))
  }
  const getIcon = (type: 'System' | 'My Listing' | 'Location' | 'Friends') => {
    if (type === 'System') return faExclamationTriangle
    if (type === 'My Listing') return faHome
    if (type === 'Location') return faMapPin
    if (type === 'Friends') return faUser
    return faExclamationTriangle
  }

  return (
    <div className="DashboardPage">
      {modalOpen && <OnboardingModal setModalOpen={setModalOpen} />}
      <div className="DashboardPage__header">
        <Text on="grey-050" weight="bold" size="xxxl">
          Dashboard
        </Text>
        <Button
          onClick={() => {
            setModalOpen(true)
          }}
        >
          Test onboarding flow
        </Button>
      </div>
      <GridBox
        gridTemplateColumns="1fr 2fr"
        gap="1rem"
        paddingTop="1rem"
        flexGrow={1}
      >
        <Section
          title="Notifications"
          actions={[<Link to="dashboard">View All Notifications</Link>]}
        >
          <GridBox gap=".5rem">
            {notifications.length === 0 ? (
              <EmptySection message="No new notifications" />
            ) : (
              notifications.map((notification) => (
                <LargeIconButton
                  key={notification._id}
                  title={notification.title}
                  subTitle={notification.details}
                  rightText={notification.type}
                  icon={<FontAwesomeIcon icon={getIcon(notification.type)} />}
                />
              ))
            )}
          </GridBox>
        </Section>
        <GridBox gap="1rem">
          <div className="LatestListings">
            <Section
              title="Your Latest Listings"
              actions={[<Link to="listings">View All Listings</Link>]}
            >
              <GridBox gap=".5rem">
                {fakeListings.length === 0 ? (
                  <EmptySection message="No New Listings" />
                ) : (
                  fakeListings.map((listing) => (
                    <LargeIconButton
                      key={listing.mls_id}
                      title={listing.street}
                      rightText={listing.city}
                      icon={<FontAwesomeIcon icon={faMapPin} />}
                    />
                  ))
                )}
              </GridBox>
            </Section>
            <div className="LatestListings__map">
              <Text on="grey-100" size="xxxl">
                <FontAwesomeIcon icon={faGlobe} />
              </Text>
              <Text on="grey-100">Coming Soon...</Text>
            </div>
          </div>
          <Section
            title="Sphere of Influence"
            actions={[<Link to="contacts">View CRM</Link>]}
          >
            <div className="SphereOfInfluence">
              <Text size="xxxl" on="grey-100">
                <FontAwesomeIcon icon={faLock} />
              </Text>
              <Text on="grey-100">Coming soon...</Text>
            </div>
          </Section>
          <Section
            title="Campaign Overview"
            actions={[<Link to="campaigns">View All Campaigns</Link>]}
          >
            <GridBox gap=".5rem">
              {campaigns.length === 0 ? (
                <EmptySection message="No recent campaigns" />
              ) : (
                <>
                  <Text on="grey-100">Latest Campaigns</Text>
                  {campaigns.slice(0, 2).map((campaign) => (
                    <LargeIconButton
                      key={campaign._id}
                      title={campaign.title}
                      subTitle={`${campaign.prospects} prospects`}
                      rightText={campaign.state}
                    />
                  ))}
                </>
              )}
            </GridBox>
          </Section>
        </GridBox>
      </GridBox>
    </div>
  )
}
