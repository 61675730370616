import React, { useState, useContext, useCallback, useEffect } from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { useLocation } from 'react-router-dom'
import { Splash } from 'Pages'
import {
  Button,
  Form,
  FormItem,
  Checkbox,
  TextInput,
} from '@eltoro-ui/components'
import { browserLog } from 'Tools'
import { useValidatedState, useForm } from '@eltoro-ui/hooks'
import { AppContext } from 'AppContext'
import logo from '../../Images/Icon_color@2x.png'
import './Login.scss'

export const Login = () => {
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const [email, setEmail, isValidEmail] = useValidatedState('', 'email')
  const [password, setPassword] = useState<string>('')
  const { login } = useContext(AppContext)

  const { formData, isValidForm, touched } = useForm({
    email,
    password,
  })

  const required = ['email', 'password']
  const { validForm, missingFields } = isValidForm([...required, isValidEmail])
  const location = useLocation()

  const onSubmit = () => {
    login(formData.email, formData.password)
  }
  const { keycloak, initialized } = useKeycloak()
  const loginKC = useCallback(() => {
    if (initialized) {
      keycloak?.login({
        redirectUri: 'https://portal.staging.beewo.com/dashboard',
      })
    }
  }, [initialized])
  useEffect(() => {
    loginKC()
  }, [loginKC])
  if (!initialized) {
    return <Splash />
  }

  return (
    <div className="Login">
      <div className="Login__logo">
        <img src={logo} alt="beewo-logo" />
      </div>
      {location.pathname === '/logout' && (
        <div className="Logout__content">
          <b>You’ve Successfully Logged Out</b>
        </div>
      )}
      <div className="Login__form">
        {/* <button type="submit" onClick={() => loginKC()}>
          Test
        </button> */}
        <Form
          onSubmit={onSubmit}
          required={required}
          valid={validForm}
          missingFields={missingFields}
          touched={touched}
        >
          <FormItem
            htmlFor="email"
            label="Email"
            errorMessage="Please enter a valid email"
            valid={isValidEmail}
          >
            <TextInput
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormItem>
          <FormItem htmlFor="password" label="Password">
            <TextInput
              password
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormItem>
          <div className="Login__options">
            {/* not functional until we get api...just toggles on and off right now */}
            <Checkbox
              checked={isChecked}
              onChange={() => {
                setIsChecked((prevIsChecked) => !prevIsChecked)
              }}
              label="Remember Me"
            />
            <Button
              size="s"
              type="button"
              onClick={() => browserLog.info('password forgotten')}
            >
              Forgot Password?
            </Button>
          </div>
          <Button type="submit" kind="primary">
            Sign In
          </Button>
        </Form>
      </div>
    </div>
  )
}
