import React, { useState } from 'react'
import './ListingsList.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSortAmountDownAlt,
  faSortAmountUp,
} from '@fortawesome/free-solid-svg-icons'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { Button, Checkbox, TextInput } from '@eltoro-ui/components'
import { APIFeatureType } from 'types'
import { ListingLabel } from '..'

export const ListingsList = ({
  listings,
  selectedListings,
  onSelectedListingsChange,
}: {
  listings: APIFeatureType[]
  selectedListings: APIFeatureType[]
  onSelectedListingsChange: (newSelectedListings: APIFeatureType[]) => void
}) => {
  const [searchTextValue, setSearchTextValue] = useState('')
  const [allChecked, setAllChecked] = useState(false)
  const [buzzSortOrder, setBuzzSortOrder] = useState<'asc' | 'desc'>('desc')

  const sortListingsByBuzz = (listingsToSort: APIFeatureType[]) => {
    if (buzzSortOrder === 'asc')
      return listingsToSort.sort((a, b) => a.activity_score - b.activity_score)
    if (buzzSortOrder === 'desc')
      return listingsToSort.sort((a, b) => b.activity_score - a.activity_score)
    return listingsToSort
  }

  const handleListingLabelOnClick = (listing: APIFeatureType) => {
    const matchingListing = selectedListings.find(
      (l) => l.mls_id === listing.mls_id,
    )
    if (matchingListing)
      onSelectedListingsChange(
        selectedListings.filter((l) => l.mls_id !== listing.mls_id),
      )
    else onSelectedListingsChange([...selectedListings, listing])
  }

  const handleAllCheckedChange = (checked: boolean) => {
    if (checked) {
      onSelectedListingsChange(listings)
      setAllChecked(true)
    } else {
      onSelectedListingsChange([])
      setAllChecked(false)
    }
  }

  const filteredListings = listings.filter(
    (listing) =>
      !!listing.street.toLowerCase().match(searchTextValue.toLowerCase()),
  )

  return (
    <div className="ListingsList">
      <div className="ListingsList__controls">
        <Checkbox
          checked={allChecked}
          onChange={handleAllCheckedChange}
          label="Select all"
        />
        <div className="ListingsList__search">
          <TextInput
            icon={<FontAwesomeIcon icon={faSearch} />}
            value={searchTextValue}
            onChange={(e) => setSearchTextValue(e.target.value)}
            size="s"
          />
        </div>
        <Button
          size="s"
          iconLeft={
            <FontAwesomeIcon
              icon={
                buzzSortOrder === 'asc' ? faSortAmountDownAlt : faSortAmountUp
              }
            />
          }
          onClick={() => {
            if (buzzSortOrder === 'asc') setBuzzSortOrder('desc')
            else setBuzzSortOrder('asc')
          }}
        >
          Buzz score
        </Button>
      </div>
      <div className="ListingsList__listings">
        {sortListingsByBuzz(filteredListings).map((listing) => (
          <ListingLabel
            key={listing.mls_id}
            isSelected={
              !!selectedListings.find(
                (selectedL) => selectedL.mls_id === listing.mls_id,
              )
            }
            listing={listing}
            onClick={handleListingLabelOnClick}
          />
        ))}
      </div>
    </div>
  )
}
