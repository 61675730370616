import React from 'react'
import './UploadAreaLayout.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/pro-solid-svg-icons'
import { Button, GridBox, Text } from '@eltoro-ui/components'

interface UploadRestrictions {
  fileSize: string
  fileTypes: string
}

interface UploadDetails {
  header: string
  creativeLibrary: boolean
  uploadRestrictions?: UploadRestrictions
}

/* eslint-disable-next-line no-shadow */
export enum UploadTypes {
  mailerFront,
  mailerBack,
  banner,
  video,
}

function uploadDetails(type: UploadTypes): UploadDetails {
  switch (type) {
    case UploadTypes.mailerFront: {
      return {
        header: 'Upload Front of Mailer',
        creativeLibrary: true,
      }
    }
    case UploadTypes.mailerBack: {
      return {
        header: 'Upload Back of Mailer',
        creativeLibrary: true,
      }
    }
    case UploadTypes.banner: {
      return {
        header: 'Upload your Banner Creative file(s) here',
        creativeLibrary: true,
        uploadRestrictions: {
          fileSize: '100KB',
          fileTypes: 'jpg, gif, png',
        },
      }
    }
    case UploadTypes.video: {
      return {
        header: 'Upload your Video Creative file(s) here',
        creativeLibrary: false,
        uploadRestrictions: {
          fileSize: '100MB',
          fileTypes: 'avi, mp4, quicktime',
        },
      }
    }
    default: {
      return {
        header: 'Upload Front of Mailer',
        creativeLibrary: true,
      }
    }
  }
}

export const UploadAreaLayout = ({
  uploadType,
}: {
  uploadType: UploadTypes
}) => {
  const details = uploadDetails(uploadType)
  return (
    <div className="UploadAreaLayout">
      <FontAwesomeIcon icon={faUpload} />
      <Text on="white" weight="bold">
        {details.header}
      </Text>
      {details.uploadRestrictions && (
        <GridBox justifyItems="center">
          <Text on="white" size="s">
            Max file size - <b>{details.uploadRestrictions.fileSize}</b>
          </Text>
          <Text on="white" size="s">
            Accepted file types - <b>{details.uploadRestrictions.fileTypes}</b>
          </Text>
        </GridBox>
      )}
      {details.creativeLibrary && (
        <Button onClick={(e) => e?.stopPropagation()} kind="text" size="s">
          or Access Creative Library
        </Button>
      )}
    </div>
  )
}
