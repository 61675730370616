export const removeKeyFromObject = (
  key: string,
  parentObject: { [key: string]: any },
) => {
  const filteredObject = Object.keys(parentObject)
    .filter((parentKey: string) => parentKey !== key)
    .reduce((acc: { [key: string]: any }, curr: string) => {
      return {
        ...acc,
        [curr]: parentObject[curr],
      }
    }, {})
  return filteredObject
}

// returns an abbreviated string without zeros
// Ex: basicAbbreviation(1, '1230000') returns 1.23
// useful for formatting larger numbers from user inputs
export const basicAbbreviation = (sliceAt: number, input: string) => {
  const startingNumber = input.slice(0, sliceAt)
  const remainder = input.slice(-1 * (input.length - startingNumber.length))
  return parseFloat(`${startingNumber}.${remainder}`.replace(/^0+/g, ''))
}

// returns large numbers into more readable strings
export const getAbbreviatedNumber = (input: string) => {
  const parsedNumber = parseInt(input, 10)
  // if greater than 1 mill, create mill abbreviation
  if (parsedNumber >= 1000000) {
    return `${basicAbbreviation(-6, input).toFixed(2)} mil`
  }
  // if greater than 1k, create thousands abbreviation
  if (parsedNumber >= 1000) {
    return `${basicAbbreviation(-3, input)}k`
  }
  return input
}
