import { APIFeatureType } from 'types'
import { MoverLifecycleType } from '../MoverLifecycleFilters'

export const filterMoverLifecycle = (
  features: APIFeatureType[],
  filters: MoverLifecycleType[],
) =>
  features.filter((feature) =>
    // will need to be updated from `street` once API returns proper fields
    filters.some((filter) => filter.test(feature.street)),
  )
