import React from 'react'
import { faWalking, faBullhorn } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FlexBox, Text } from '@eltoro-ui/components'
import './ProspectActivitySplash.scss'

export const ProspectActivitySplash = () => {
  return (
    <div className="ProspectActivitySplash">
      <LargeTitle
        title="Find Prospects"
        subTitle="Observe in-market foot traffic of residential properties listed for sale."
        titleIcon={<FontAwesomeIcon icon={faWalking} />}
        info="To get started, select a lens to identify and save target audiences."
      />
      <LargeTitle
        title="Additional Targets"
        subTitle="Raise Awareness"
        titleIcon={<FontAwesomeIcon icon={faBullhorn} />}
      />
    </div>
  )
}

const LargeTitle: React.FC<{
  title: string
  subTitle: string
  info?: string
  titleIcon: JSX.Element
}> = ({ title, subTitle, info, titleIcon }) => {
  return (
    <div className="ProspectActivitySplash__large-title">
      <FlexBox alignItems="center" justifyContent="center">
        <Text on="white" size="xxxl">
          {titleIcon}
        </Text>
      </FlexBox>
      <FlexBox flexDirection="column">
        <Text on="white" size="xxxl" weight="bold">
          {title}
        </Text>
        <Text on="white" size="xxl">
          {subTitle}
        </Text>
        {info && <Text on="white">{info}</Text>}
      </FlexBox>
    </div>
  )
}
