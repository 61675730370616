import { CreativeType, RequestType, getDataType } from 'types'
import requests from '../http'

export const getCreatives = async (
  skip?: number | null,
  limit?: number | null,
): Promise<RequestType<getDataType<CreativeType[]>>> =>
  requests.get(`/creatives`)

export const getCreativeById = async (
  creative_id: string,
): Promise<RequestType<CreativeType>> => {
  return requests.get(`/creative/${creative_id}`)
}

export const updateCreative = async (
  creative_id: string,
  body: Partial<CreativeType>,
): Promise<RequestType<CreativeType>> => {
  return requests.patch(`/creative/${creative_id}`, body)
}

export const createCreative = async (
  body: Partial<CreativeType>,
): Promise<RequestType<CreativeType>> => requests.post(`/creative`, body)
