import React, { useState } from 'react'
import { useForm, FormObjectType } from '@eltoro-ui/hooks'
import {
  Form,
  FormItem,
  Button,
  Text,
  RadioButton,
  RadioButtonGroup,
  Select,
  TextInput,
  SelectOptionType,
} from '@eltoro-ui/components'
import './RealEstateInfoForm.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouseUser } from '@fortawesome/pro-solid-svg-icons'
import { UserInfoType } from 'types'
import { ProgressBar } from '../ProgressBar'

export const RealEstateInfoForm = ({
  onSubmit,
  onBack,
  user,
}: {
  onSubmit: (value: FormObjectType) => void
  onBack: (value: FormObjectType) => void
  user: UserInfoType
}) => {
  const [territory, setTerritory] = useState(user.realEstateInfo.territory)
  const [experience, setExperience] = useState<SelectOptionType>({
    label: user.realEstateInfo.experience,
  })
  const [workStatus, setWorkStatus] = useState<string>(
    user.realEstateInfo.status,
  )

  const { formData, isValidForm, touched } = useForm({
    territory,
    experience,
    workStatus,
  })

  const required = ['territory', 'workStatus']
  const { validForm, missingFields } = isValidForm(required)

  return (
    <div className="RealEstateInfoForm">
      <div className="RealEstateInfoForm__description">
        <FontAwesomeIcon
          icon={faHouseUser}
          size="4x"
          className="RealEstateInfoForm__icon"
        />
        <div className="RealEstateInfoForm__progress">
          <ProgressBar totalSteps={2} activeStep={2} />
        </div>
        <Text on="white" size="xl" weight="bold">
          Real Estate Info
        </Text>
        <Text on="white">
          We are here to get you the most tailored experience. All we need from
          you is to answer a couple of simple questions.
        </Text>
      </div>
      <div className="RealEstateInfoForm__inputs">
        <Form
          onSubmit={() => onSubmit(formData)}
          required={required}
          valid={validForm}
          missingFields={missingFields}
          touched={touched}
        >
          <FormItem
            htmlFor="territory"
            label="Where is your primary real estate territory*"
            errorMessage="Please enter a valid name"
            valid={territory?.length > 1}
          >
            <TextInput
              value={territory}
              onChange={(e) => setTerritory(e.target.value)}
            />
          </FormItem>
          <FormItem
            htmlFor="experience"
            label="How long have you been in the real estate industry?"
          >
            <Select
              placeholder="Select time frame"
              options={[
                { label: '0-1 years' },
                { label: '2-5 years' },
                { label: '6-10 years' },
                { label: '10+ years' },
              ]}
              selectedOption={experience}
              onChange={(value) => !!value && setExperience(value)}
              maxHeight={200}
            />
          </FormItem>
          <FormItem
            htmlFor="workStatus"
            label="Do you work in the real estate industry full or part time?*"
            errorMessage="Please enter a selection"
            valid={!!workStatus}
          >
            <RadioButtonGroup
              name="workStatus"
              valueSelected={workStatus}
              onChange={(value) => {
                const val = value as string
                setWorkStatus(val)
              }}
            >
              <RadioButton key={1} value="full-time" label="Full Time" />
              <RadioButton key={2} value="false" label="Part Time" />
            </RadioButtonGroup>
          </FormItem>
          <div className="RealEstateInfoForm__submit">
            <Text on="white">*required</Text>
            <Button onClick={() => onBack(formData)}>Back</Button>
            <Button type="submit">Next</Button>
          </div>
        </Form>
      </div>
    </div>
  )
}
