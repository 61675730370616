import React, { FC, useState } from 'react'
import { UserType } from 'types'
import { browserLog } from 'Tools'

import {
  Form,
  FormItem,
  Button,
  Select,
  RadioButtonGroup,
  RadioButton,
  TextInput,
  SelectOptionType,
} from '@eltoro-ui/components'
import { useForm } from '@eltoro-ui/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { Section } from 'Components'
import './RealEstateInfoSection.scss'

type RealEstateInfoSectionType = {
  user: Partial<UserType>
}

export const RealEstateInfoSection: FC<RealEstateInfoSectionType> = ({
  user,
}) => {
  const [workingArea, setWorkingArea] = useState(user.working_area)
  const [experience, setExperience] = useState<SelectOptionType | undefined>({
    label: user.experience ? user.experience : '',
  })
  const [workType, setWorkType] = useState(user.work_type)
  const [workStatus, setWorkStatus] = useState(user.work_status)

  const { formData, isValidForm, touched } = useForm({
    workingArea,
    experience,
    workType,
    workStatus,
  })

  const required = ['workingArea', 'experience', 'workType', 'workStatus']
  const { validForm, missingFields } = isValidForm([...required])

  const onSubmit = () => {
    browserLog.log(formData)
  }

  return (
    <Section title="Real Estate Info" noBackground>
      <div className="RealEstateInfoSection">
        <Form
          onSubmit={onSubmit}
          required={required}
          valid={validForm}
          touched={touched}
          missingFields={missingFields}
        >
          <FormItem
            htmlFor="workingArea"
            label="Where is your primary real estate territory"
            errorMessage="Please enter a valid territory"
            valid={!!(workingArea && workingArea.length > 1)}
            required
          >
            <TextInput
              value={workingArea || ''}
              onChange={(e) => setWorkingArea(e.target.value)}
              icon={<FontAwesomeIcon icon={faMapMarkerAlt} />}
            />
          </FormItem>
          <FormItem
            htmlFor="experience"
            label="How long have you been in the real estate industry?"
          >
            <Select
              selectedOption={experience}
              placeholder="Select Time Frame"
              options={[
                { label: '0-1 years' },
                { label: '2-5 years' },
                { label: '6-10 years' },
                { label: '10+ years' },
              ]}
              onChange={setExperience}
              maxHeight={200}
            />
          </FormItem>
          <FormItem
            htmlFor="agency"
            label="Do you work for a real estate agency, or are you self represented?"
            errorMessage="Please enter a selection"
            valid={!!workType}
            required
          >
            <RadioButtonGroup
              name="workType"
              valueSelected={workType || null}
              onChange={(value) => {
                const val = value as string
                setWorkType(val)
              }}
            >
              <RadioButton key={1} value="agency" label="Real Estate Agency" />
              <RadioButton key={2} value="self" label="Self Represented" />
            </RadioButtonGroup>
          </FormItem>
          <FormItem
            htmlFor="workStatus"
            label="Do you work in the real estate industry full or part time?"
            errorMessage="Please enter a selection"
            valid={!!workStatus}
          >
            <RadioButtonGroup
              name="workStatus"
              valueSelected={workStatus || null}
              onChange={(value) => {
                const val = value as string
                setWorkStatus(val)
              }}
            >
              <RadioButton key={1} value="full-time" label="Full Time" />
              <RadioButton key={2} value="part-time" label="Part Time" />
            </RadioButtonGroup>
          </FormItem>
          <Button
            type="submit"
            kind="primary"
            UNSAFE_className="RealEstateInfoSection__save-btn"
            disabled={
              workingArea === user.working_area &&
              experience?.label === user.experience &&
              workType === user.work_type &&
              workStatus === user.work_status
            }
          >
            Save changes
          </Button>
        </Form>
      </div>
    </Section>
  )
}
