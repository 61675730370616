import React, { useState, useEffect } from 'react'
import { UserType, AppContextType } from 'types'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import * as methods from './Requests'
import {
  clearSession,
  setSessionUser,
  setAuthHeader,
  browserLog,
  terminalLog,
} from './Tools'
import keycloak from './keycloakConf'

export type AuthClient = {
  idToken?: string
  refreshToken?: string
  token?: string
}

const baseState = {
  logout: () => {},
  validSession: false,
  setValidSession: () => false,
  currentUser: {},
  setCurrentUser: () => {},
  isLoggedin: () => false,
  login: () => Promise.resolve(),
  /* clearSession: () => undefined, */
  /* updateAndSetUser: () => Promise.resolve(), */
}

export const AppContext = React.createContext<AppContextType>(baseState)

export const AppContextProvider: React.FC<{ children: object }> = ({
  children,
}) => {
  const [validSession, setValidSession] = useState<boolean>(false)
  const [currentUser, setCurrentUser] = useState<Partial<UserType>>({})
  useEffect(() => {
    const user = localStorage.getItem('beewo_user')
    if (user) {
      setCurrentUser(JSON.parse(user))
    }
  }, [])
  const tokenSetter = (
    tokens: Pick<AuthClient, 'idToken' | 'refreshToken' | 'token'>,
  ) => {
    if (tokens?.token) {
      setAuthHeader(tokens.token)
    }
  }
  const isLoggedIn = (): boolean => {
    const authorized = !!(
      localStorage.getItem('Beewo_token') && localStorage.getItem('Beewo_user')
    )

    return validSession && authorized
  }

  const setIsLoggedIn = (state: boolean) => {
    if (!state) {
      sessionStorage.clear()
      localStorage.clear()
    }
    setValidSession(state)
  }

  const login = async (email: string, password: string) => {
    const tokenRes = await methods.getAuth(email, password)
    if (tokenRes.error) {
      browserLog.error(tokenRes.error)
      return tokenRes.error
    }
    const userRes = await methods.getUser()
    if (userRes.error) {
      terminalLog.error(userRes.error)
      return userRes.error
    }
    if (userRes.data) {
      setCurrentUser(userRes.data)
      setSessionUser(userRes.data)
      setIsLoggedIn(true)
    }
    return userRes.data
  }

  const logout = () => {
    setCurrentUser({})
    clearSession()
    setIsLoggedIn(false)
  }
  const contextValues = {
    currentUser,
    setCurrentUser,
    validSession,
    setValidSession,
    isLoggedIn,
    setIsLoggedIn,
    login,
    logout,
  }
  return (
    children && (
      <AppContext.Provider value={contextValues}>
        <ReactKeycloakProvider
          authClient={keycloak}
          onTokens={tokenSetter}
          onEvent={(event, error) =>
            console.log('event', event, error, 'success')
          }
          initOptions={{ enableLogging: true, onLoad: 'login-required' }}
        >
          {children}
        </ReactKeycloakProvider>
      </AppContext.Provider>
    )
  )
}
