/*
  This mock api uses the miragejs mock api library.
  For an overview and Docmentation, check https://miragejs.com/docs/getting-started/overview/.
*/
import { createHash } from 'crypto'
import { createServer, Model, Response } from 'miragejs'
import { v4 as uuid } from 'uuid'
import fixtures from 'Requests/Fixtures'
import {
  AudienceType,
  CampaignType,
  CreativeType,
  NotificationType,
} from 'types'

const root =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_PROXY
    : process.env.REACT_APP_API

const baseUrl = `${root}/api/v1`

const hasher = (data: string) => {
  const hash = createHash('sha256').update(data)
  return hash.digest('hex')
}
// creates model list for the mirage server with the list of fixtures to be seeded
const modelIndexer = (fixtureSet: object) => {
  return Object.keys(fixtureSet).reduce((modelIndex: object, key: string) => {
    return { ...modelIndex, [key]: Model }
  }, {})
}

const getValidUser = (token: string, schema: any) => {
  const tkn = token.replace('Bearer ', '')
  const validUser = schema.users.findBy({ current_token: tkn })
  if (validUser) return validUser
  return { error: 'invalid token' }
}

export const mockApi = () => {
  return createServer({
    models: modelIndexer(fixtures),
    seeds(server) {
      server.db.loadData({ ...fixtures })
    },
    routes() {
      // allows requests to openstreetmap to not be intercepted
      this.passthrough('https://nominatim.openstreetmap.org/***')

      // Auth endpoint

      this.post(`${baseUrl}/token`, (schema: any, request) => {
        const token = uuid()
        const body = JSON.parse(request.requestBody) as any
        const user = schema.users.findBy({ email: body.email })
        const verified =
          user &&
          body?.password &&
          hasher(body.password) === user.attrs.password_hash
        if (verified) {
          user.update('current_token', token)
          return { _id: user.attrs._id, token }
        }
        return new Response(
          403,
          { some: 'header' },
          { error: ['incorrect email or password'] },
        )
      })

      // User endpoints

      this.post(`${baseUrl}/user`, (schema: any, request) => {
        // should id for user be generated here?
        const validUser = getValidUser(
          request.requestHeaders.Authorization,
          schema,
        )
        if (validUser && !validUser.error) {
          const body = JSON.parse(request.requestBody)
          schema.users.create(body)
          return body
        }
        return new Response(
          401,
          { some: 'header' },
          { error: [`${validUser.error}`] },
        )
      })
      // // not sure this is ever needed?
      // this.get(`${baseUrl}/users`, (schema: any, request) => {
      //   return schema.users.all()
      // })
      this.get(`${baseUrl}/user`, (schema: any, request) => {
        const validUser = getValidUser(
          request.requestHeaders.Authorization,
          schema,
        )
        if (validUser && !validUser.error) return validUser
        return new Response(
          401,
          { some: 'header' },
          { error: [`${validUser.error}`] },
        )
      })
      this.patch(`${baseUrl}/user`, (schema: any, request) => {
        const body = JSON.parse(request.requestBody)
        const validUser = getValidUser(
          request.requestHeaders.Authorization,
          schema,
        )
        if (validUser && !validUser.error) {
          validUser.update(body)
          return validUser
        }
        return new Response(
          401,
          { some: 'header' },
          { error: [`${validUser.error}`] },
        )
      })

      // Listings endpoints

      this.post(`${baseUrl}/listings`, (schema: any, request) => {
        // Its is currently unknown how this will be implemented
        // const body = JSON.parse(request.requestBody) as any
        return new Response(
          400,
          { some: 'header' },
          { error: ['sorry, this endpoint does not work in the mock api yet'] },
        )
      })
      this.get(`${baseUrl}/listings/layer`, (schema: any, request) => {
        const { north, south, east, west, skip, limit } = request.queryParams
        const validLocation = schema.mapListings.findBy({
          north: parseInt(north, 10).toString(),
          south: parseInt(south, 10).toString(),
          east: parseInt(east, 10).toString(),
          west: parseInt(west, 10).toString(),
        }).attrs
        if (validLocation) {
          if (limit && skip) {
            const skipInt = parseInt(skip, 10)
            const limitInt = parseInt(limit, 10)
            validLocation.skip = skipInt
            validLocation.limit = limitInt
            validLocation.listings = validLocation.listings.slice(
              skipInt,
              limitInt + skipInt,
            )
          }
          return validLocation
        }
        return new Response(
          404,
          { some: 'header' },
          { error: ['no data on the queried location'] },
        )
      })
      this.get(`${baseUrl}/listings/by-id/:mls_id`, (schema: any, request) => {
        const { mls_id } = request.params
        const rawListings = schema.mapListings.all()

        const matchedListing = rawListings.models[0].attrs.listings.find(
          (listing: any) => listing.mls_id === mls_id,
        )

        if (matchedListing) return matchedListing

        return new Response(
          404,
          { some: 'header' },
          { error: ['sorry, no listing was found with the provided mls id'] },
        )
      })
      this.get(`${baseUrl}/listings`, (schema: any, request) => {
        const rawListings = schema.mapListings.all()
        const { skip, limit } = request.queryParams
        if (limit && skip) {
          const skipInt = parseInt(skip, 10)
          const limitInt = parseInt(limit, 10)
          return {
            listings: rawListings.models[0].attrs.listings.slice(
              skipInt,
              limitInt + skipInt,
            ),
            total: limit || rawListings.length,
            limit,
            skip,
          }
        }
        return {
          listings: rawListings.models[0].attrs.listings,
          total: limit || rawListings.length,
          limit,
          skip,
        }
      })
      this.get(`${baseUrl}/listings/comps`, (schema: any, request) => {
        return new Response(
          400,
          { some: 'header' },
          { error: ['sorry, this endpoint does not work in the mock api yet'] },
        )
      })

      // Prospects endpoints

      this.get(`${baseUrl}/prospects/canvas`, (schema: any, request) => {
        // return prospect count
        return new Response(
          400,
          { some: 'header' },
          { error: ['sorry, this endpoint does not work in the mock api yet'] },
        )
      })
      this.get(`${baseUrl}/prospects/movers`, (schema: any, request) => {
        return new Response(
          400,
          { some: 'header' },
          { error: ['sorry, this endpoint does not work in the mock api yet'] },
        )
      })

      // Audience endpoints

      this.post(`${baseUrl}/audience`, (schema: any, request) => {
        const validUser = getValidUser(
          request.requestHeaders.Authorization,
          schema,
        )
        if (validUser && !validUser.error) {
          const body = JSON.parse(request.requestBody)
          const date = new Date().toISOString()
          // make location a field on audience
          const audience = {
            ...body,
            _id: uuid(),
            user_id: validUser._id,
            date_captured: date,
            last_updated: date,
          }
          schema.audiences.create(audience)
          return audience
        }
        return new Response(
          401,
          { some: 'header' },
          { error: [`${validUser.error}`] },
        )
      })
      this.get(`${baseUrl}/audiences`, (schema: any, request) => {
        // gets audiences by user with provided token
        const { skip, limit } = request.queryParams
        const validUser = getValidUser(
          request.requestHeaders.Authorization,
          schema,
        )
        if (validUser && !validUser.error) {
          const audienceCollection = schema.audiences.where(
            (audience: AudienceType) => audience.user_id === validUser._id,
          )
          const audienceArray = audienceCollection.models.map(
            (child: any) => child.attrs,
          )
          if (limit && skip) {
            const skipInt = parseInt(skip, 10)
            const limitInt = parseInt(limit, 10)
            return {
              audiences: audienceArray.slice(skipInt, limitInt + skipInt),
              total: limit || audienceArray.length,
              limit,
              skip,
            }
          }
          return {
            audiences: audienceArray,
            total: limit || audienceArray.length,
            limit,
            skip,
          }
        }
        // unsure about error handling
        return new Response(
          401,
          { some: 'header' },
          { error: [`${validUser.error}`] },
        )
      })
      this.get(`${baseUrl}/audience/:_id`, (schema: any, request) => {
        // gets audience by audience id
        const { _id } = request.params
        const audience = schema.audiences.findBy({ _id }).attrs
        if (audience) return audience
        return new Response(
          404,
          { some: 'header' },
          { error: [`no audience found with the given id`] },
        )
      })
      this.patch(`${baseUrl}/audience/:_id`, (schema: any, request) => {
        const { _id } = request.params
        const body = JSON.parse(request.requestBody)
        const audience = schema.audiences.findBy({ _id })
        if (audience) {
          audience.update(body)
          return audience.attrs
        }
        return new Response(
          404,
          { some: 'header' },
          { error: [`no audience found with the given id`] },
        )
      })

      // Campaign endpoints

      this.post(`${baseUrl}/campaign`, (schema: any, request) => {
        const validUser = getValidUser(
          request.requestHeaders.Authorization,
          schema,
        )
        if (validUser && !validUser.error) {
          const body = JSON.parse(request.requestBody)
          const date = new Date().toISOString()
          const campaign = {
            ...body,
            _id: uuid(),
            user_id: validUser._id,
            created: date,
          }
          schema.campaigns.create(campaign)
          return campaign
        }
        return new Response(
          401,
          { some: 'header' },
          { error: [`${validUser.error}`] },
        )
      })
      this.get(`${baseUrl}/campaigns`, (schema: any, request) => {
        // gets campaigns by user with provided token
        const { skip, limit } = request.queryParams
        const validUser = getValidUser(
          request.requestHeaders.Authorization,
          schema,
        )
        if (validUser && !validUser.error) {
          const campaignCollection = schema.campaigns.where(
            (campaign: CampaignType) => campaign.user_id === validUser._id,
          )
          const campaignArray = campaignCollection.models.map(
            (child: any) => child.attrs,
          )
          if (limit && skip) {
            const skipInt = parseInt(skip, 10)
            const limitInt = parseInt(limit, 10)
            return {
              campaigns: campaignArray.slice(skipInt, limitInt + skipInt),
              total: limit || campaignArray.length,
              limit,
              skip,
            }
          }
          return {
            campaigns: campaignArray,
            total: limit || campaignArray.length,
            limit,
            skip,
          }
        }
        return new Response(
          401,
          { some: 'header' },
          { error: [`${validUser.error}`] },
        )
      })
      this.get(`${baseUrl}/campaign/:_id`, (schema: any, request) => {
        // gets campaign by campaign id
        const { _id } = request.params
        return schema.campaigns.findBy({ _id }).attrs
      })
      this.patch(`${baseUrl}/campaign/:_id`, (schema: any, request) => {
        const { _id } = request.params
        const body = JSON.parse(request.requestBody)
        const campaign = schema.campaigns.findBy({ _id })
        if (campaign) {
          campaign.update(body)
          return campaign
        }
        return new Response(
          404,
          { some: 'header' },
          { error: [`no campaign found with the given id`] },
        )
      })
      this.patch(
        `${baseUrl}/campaign/attach-audience/:campaign_id`,
        (schema: any, request) => {
          const { campaign_id } = request.params
          const body = JSON.parse(request.requestBody)
          const campaign = schema.campaigns.findBy({ _id: campaign_id })
          if (campaign) {
            const updatedAudiences = [...campaign.audiences, ...body]
            campaign.update({ audiences: updatedAudiences })
            return campaign
          }
          return new Response(
            404,
            { some: 'header' },
            { error: [`no campaign found with the given id`] },
          )
        },
      )
      this.patch(
        `${baseUrl}/campaign/detach-audience/:campaign_id`,
        (schema: any, request) => {
          const { campaign_id } = request.params
          const body = JSON.parse(request.requestBody)
          const campaign = schema.campaigns.findBy({ _id: campaign_id })
          if (campaign) {
            const updatedAudiences = campaign.audiences.filter(
              (audience: AudienceType) => {
                return !body.some(
                  (bodyAudience: AudienceType) =>
                    bodyAudience._id === audience._id,
                )
              },
            )
            campaign.update({ audiences: updatedAudiences })
            return campaign
          }
          return new Response(
            404,
            { some: 'header' },
            { error: [`no campaign found with the given id`] },
          )
        },
      )
      this.patch(
        `${baseUrl}/campaign/attach-creative/:campaign_id`,
        (schema: any, request) => {
          const { campaign_id } = request.params
          const body = JSON.parse(request.requestBody)
          const campaign = schema.campaigns.findBy({ _id: campaign_id })
          if (campaign) {
            const updatedCreatives = [...campaign.creatives, ...body]
            campaign.update({ creatives: updatedCreatives })
            return campaign
          }
          return new Response(
            404,
            { some: 'header' },
            { error: [`no campaign found with the given id`] },
          )
        },
      )
      this.patch(
        `${baseUrl}/campaign/detach-creative/:campaign_id`,
        (schema: any, request) => {
          const { campaign_id } = request.params
          const body = JSON.parse(request.requestBody)
          const campaign = schema.campaigns.findBy({ _id: campaign_id })
          if (campaign) {
            const updatedCreatives = campaign.creatives.filter(
              (creative: CreativeType) => {
                return !body.some(
                  (reqCreative: CreativeType) =>
                    reqCreative._id === creative._id,
                )
              },
            )
            campaign.update({ creatives: updatedCreatives })
            return campaign
          }
          return new Response(
            404,
            { some: 'header' },
            { error: [`no campaign found with the given id`] },
          )
        },
      )

      // Creative endpoints

      this.post(`${baseUrl}/creative`, (schema: any, request) => {
        const validUser = getValidUser(
          request.requestHeaders.Authorization,
          schema,
        )
        if (validUser && !validUser.error) {
          const body = JSON.parse(request.requestBody)
          const creative = {
            ...body,
            id: uuid(),
            user_id: validUser._id,
          }
          schema.creatives.create(creative)
          return schema.creatives.all()
        }
        return new Response(
          401,
          { some: 'header' },
          { error: [`${validUser.error}`] },
        )
      })
      this.get(`${baseUrl}/creatives`, (schema: any, request) => {
        // gets creatives by user with provided token
        const validUser = getValidUser(
          request.requestHeaders.Authorization,
          schema,
        )
        if (validUser && !validUser.error) {
          const creativeCollection = schema.creatives.where(
            (creative: CreativeType) =>
              creative.user_id === '580fuej9320n1ji2552d',
          )
          return creativeCollection.models.map((child: any) => child.attrs)
        }
        // unsure about error handling
        return new Response(
          401,
          { some: 'header' },
          { error: [`${validUser.error}`] },
        )
      })
      this.get(`${baseUrl}/creative/:_id`, (schema: any, request) => {
        // gets creative by creative id
        // error handling
        const { _id } = request.params
        return schema.creatives.findBy({ _id }).attrs
      })
      this.patch(`${baseUrl}/creative/:_id`, (schema: any, request) => {
        const { _id } = request.params
        const body = JSON.parse(request.requestBody)
        const creative = schema.creatives.findBy({ _id })
        if (creative) {
          creative.update(body)
          return creative
        }
        return new Response(
          404,
          { some: 'header' },
          { error: [`no creative found with the given id`] },
        )
      })

      // Misc  endpoints

      this.get(`${baseUrl}/filters`, (schema: any, request) => {
        // gets filters for listings
        // filters.json
        return new Response(
          400,
          { some: 'header' },
          { error: ['sorry, this endpoint does not work in the mock api yet'] },
        )
      })
      this.get(`${baseUrl}/notifications`, (schema: any, request) => {
        const validUser = getValidUser(
          request.requestHeaders.Authorization,
          schema,
        )
        if (validUser && !validUser.error) {
          const notificationCollection = schema.notifications.where(
            (notification: NotificationType) =>
              notification.user_id === validUser._id,
          )
          return notificationCollection.models.map((child: any) => child.attrs)
        }
        return new Response(
          401,
          { some: 'header' },
          { error: [`${validUser.error}`] },
        )
      })
    },
  })
}
