import React from 'react'
import {
  Checkbox,
  Text,
  Select,
  MinMaxSlider,
  FlexBox,
} from '@eltoro-ui/components'
import { ProspectFilterType } from 'types'
import { getAbbreviatedNumber } from 'Helpers'
import './ProspectsFilter.scss'

export const ProspectsFilter: React.FC<{
  filter: ProspectFilterType
  onChange: (filter: { [key: string]: ProspectFilterType }) => void
}> = ({ filter, onChange }) => {
  // returns kind (UI component type) and options (correctly typed) of the current filter
  const typedOptions = (options: any) => {
    if (Array.isArray(options)) {
      if (typeof options[0] === 'string') {
        return {
          kind: 'multi',
          options: options as string[],
        }
      }
      if (typeof options[0] === 'number') {
        return {
          kind: 'select',
          options: options as number[],
        }
      }
    }
    if (typeof options === 'boolean')
      return {
        kind: 'checkbox',
        options: options as boolean,
      }
    if (typeof options === 'object')
      return {
        kind: 'minMax',
        options: options as { min: number; max: number },
      }
    return {
      kind: '',
      options,
    }
  }

  const { options, kind } = typedOptions(filter.options)
  const data = typedOptions(filter.data).options

  const handleMulti = (checked: boolean, option: string) => {
    const dataCheck = data ? [...data, option] : [option]
    const newData = checked
      ? dataCheck
      : data.filter((d: string) => d !== option)
    _onChange(newData)
  }

  const _onChange = (incomingData: any) => {
    onChange({
      [filter.id]: {
        ...filter,
        data: incomingData,
      },
    })
  }

  return (
    <div className="ProspectsFilter">
      {kind !== 'checkbox' && (
        <Text on="white" size="s">
          {filter.label}
        </Text>
      )}
      {kind === 'select' && (
        <Select
          placeholder={filter.label}
          options={[
            { label: 'Any' },
            ...options.map((option: number) => {
              return {
                label: `${option}+`,
              }
            }),
          ]}
          selectedOption={data}
          onChange={_onChange}
          maxHeight={200}
        />
      )}
      {kind === 'multi' &&
        options.map((option: string) => {
          return (
            <Checkbox
              key={option}
              checked={data?.some((item: string) => item === option) || false}
              onChange={(checked) => handleMulti(checked, option)}
              label={option}
            />
          )
        })}
      {kind === 'checkbox' && (
        <Checkbox
          checked={data || false}
          onChange={_onChange}
          label={filter.label}
        />
      )}
      {kind === 'minMax' && (
        <FlexBox flexDirection="column" alignItems="stretch">
          <FlexBox flexDirection="column">
            <Text weight="bold" on="white">
              {filter.id === 'price' ? '$' : ''}
              {getAbbreviatedNumber(data?.min?.toString() || '') ||
                'min'} - {filter.id === 'price' ? '$' : ''}
              {getAbbreviatedNumber(data?.max?.toString() || '') || 'max'}
              {filter.id === 'size' ? ' sq/ft' : ''}
            </Text>
          </FlexBox>
          <MinMaxSlider
            minMax={[data?.min || options.min, data?.max || options.max]}
            range={[options.min, options.max]}
            step={options.max > 10000 ? 10000 : 100}
            onChange={(minMax) =>
              onChange({
                [filter.id]: {
                  ...filter,
                  data: {
                    min: minMax[0],
                    max: minMax[1],
                  },
                },
              })
            }
          />
        </FlexBox>
      )}
    </div>
  )
}
