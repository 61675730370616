import React from 'react'
import { FlexBox, Text, Button, Spacer } from '@eltoro-ui/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons'
import { CreditBundleCard } from 'Components/CreditModal/CreditBundleCard'
import { CreditBundleType } from 'Components/CreditModal/CreditModal'
import { PaymentProfileType } from 'types'
import './ConfirmPurchase.scss'

export const ConfirmPurchase = ({
  bundle,
  paymentProfile,
  onSubmitPayment,
}: {
  bundle: CreditBundleType
  paymentProfile: PaymentProfileType
  onSubmitPayment: (profile: PaymentProfileType) => void
}) => {
  return (
    <div className="ConfirmPurchase">
      <FlexBox padding="1rem" alignItems="flex-end">
        <FlexBox flexDirection="column" alignItems="center">
          <Text on="white" weight="bold" size="l">
            Selected Bundle
          </Text>
          <CreditBundleCard bundle={bundle} />
        </FlexBox>
        <Spacer />
        <FlexBox flexDirection="column" alignItems="center">
          <Text on="white" weight="bold" size="l">
            Card Selected
          </Text>
          <div className="ConfirmPurchase__cc-title">
            <Text on="white" kind="subdued" weight="bold">
              {paymentProfile.cc_details.nick_name}
            </Text>
          </div>
          <div className="ConfirmPurchase__card-selected">
            <FontAwesomeIcon
              icon={faCreditCard}
              size="4x"
              className="ConfirmPurchase__cc-icon"
            />
            <b>Card ending in {paymentProfile.cc_details.last_four}</b>
          </div>
        </FlexBox>
      </FlexBox>
      <Spacer />
      <FlexBox justifyContent="center">
        <Button kind="primary" onClick={() => onSubmitPayment(paymentProfile)}>
          Complete Purchase
        </Button>
      </FlexBox>
    </div>
  )
}
