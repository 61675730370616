import { APIFeatureType } from 'types'

export const fakeListings: APIFeatureType[] = [
  {
    mls_id: 'S5042522',
    data_source: 'stellar',
    ethash: 'A32819731465FE244A28A0DF80B815CE2A29980DA3DD896C943C88',
    street: '7511 SEURAT ST #10207',
    city: 'ORLANDO',
    zip: '32819',
    sub_divison: 'SANDLAKE PRIVATE RESIDENCES',
    listing_price: 1199,
    size: null,
    beds: 2,
    baths: 2,
    year_built: 1994,
    created: '2020-12-28T14:15:27.437000',
    updated: '2021-01-12T11:15:36.863000',
    status: 'Active',
    geojson: {
      coordinates: [
        [
          [
            [-81.477404, 28.435611],
            [-81.477405, 28.435538],
            [-81.477423, 28.435538],
            [-81.477423, 28.435523],
            [-81.477443, 28.435523],
            [-81.477443, 28.435541],
            [-81.477452, 28.435542],
            [-81.477481, 28.435541],
            [-81.477482, 28.435524],
            [-81.477502, 28.435524],
            [-81.477502, 28.435539],
            [-81.477521, 28.435539],
            [-81.47752, 28.435599],
            [-81.477509, 28.435598],
            [-81.477509, 28.435611],
            [-81.477404, 28.435611],
          ],
        ],
      ],
      type: 'MultiPolygon',
    },
    latitude: 28.43557170048144,
    longitude: -81.47746150176022,
    activity_score: 0.7883332499922121,
    unique_observations: 184,
  },
  {
    mls_id: 'O5911228',
    data_source: 'stellar',
    ethash: 'A3280836141023760BB3A8D22FD17DC8EED7E45436C43CB0150000',
    street: '3619 BLARNEY DR',
    city: 'ORLANDO',
    zip: '32808',
    sub_divison: 'LONDONDERRY HILLS SEC 02',
    listing_price: 215000,
    size: null,
    beds: 4,
    baths: 2,
    year_built: 1972,
    created: '2020-12-15T10:58:14.260000',
    updated: '2021-01-04T12:31:07.350000',
    status: 'Active',
    geojson: {
      coordinates: [
        [
          [
            [-81.444887, 28.588419],
            [-81.444889, 28.588407],
            [-81.44489, 28.588397],
            [-81.444891, 28.588389],
            [-81.444892, 28.588382],
            [-81.444893, 28.588373],
            [-81.444895, 28.588363],
            [-81.444896, 28.588354],
            [-81.444898, 28.588346],
            [-81.444899, 28.588339],
            [-81.4449, 28.588332],
            [-81.444901, 28.588324],
            [-81.444903, 28.588316],
            [-81.444904, 28.588309],
            [-81.444905, 28.588301],
            [-81.444907, 28.588294],
            [-81.444908, 28.588286],
            [-81.444911, 28.588275],
            [-81.444913, 28.588264],
            [-81.444915, 28.588253],
            [-81.444917, 28.588244],
            [-81.444919, 28.588236],
            [-81.444921, 28.588229],
            [-81.444923, 28.588219],
            [-81.444926, 28.58821],
            [-81.444927, 28.588203],
            [-81.444929, 28.588196],
            [-81.444932, 28.588187],
            [-81.445236, 28.588248],
            [-81.445234, 28.588258],
            [-81.445231, 28.588267],
            [-81.445228, 28.588278],
            [-81.445226, 28.588287],
            [-81.445225, 28.588294],
            [-81.445223, 28.588302],
            [-81.44522, 28.588313],
            [-81.445218, 28.588322],
            [-81.445216, 28.588332],
            [-81.445215, 28.58834],
            [-81.445213, 28.588347],
            [-81.445212, 28.588356],
            [-81.44521, 28.588367],
            [-81.445208, 28.588377],
            [-81.445206, 28.588386],
            [-81.445205, 28.588397],
            [-81.445203, 28.588405],
            [-81.445202, 28.588412],
            [-81.445201, 28.58842],
            [-81.4452, 28.58843],
            [-81.445199, 28.588441],
            [-81.444887, 28.588419],
          ],
        ],
      ],
      type: 'MultiPolygon',
    },
    latitude: 28.588322687819492,
    longitude: -81.44505608831535,
    activity_score: 0.3699164825232043,
    unique_observations: 94,
  },
]
