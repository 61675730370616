import React from 'react'
import { Spacer, Text } from '@eltoro-ui/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWalking } from '@fortawesome/pro-solid-svg-icons'
import { AudienceType } from 'types'
import { Tags } from 'Pages/SavedAudiences/components'
import './CompactSavedAudiences.scss'
import { faMap } from '@fortawesome/free-solid-svg-icons'
import { logger } from 'Tools'
import { fixtureAudiences } from 'Requests/Fixtures'

const handleAudienceHover = (audience: AudienceType) => {
  logger.log(audience)
}

export const CompactSavedAudiences = () => {
  return (
    <div className="CompactSavedAudiences">
      {fixtureAudiences.map((audience) => {
        return (
          <div
            key={audience._id}
            onMouseEnter={() => handleAudienceHover(audience)}
          >
            <div className="CompactSavedAudiences__audience-container">
              <div className="CompactSavedAudiences__image">
                <FontAwesomeIcon icon={faMap} size="3x" />
              </div>
              <div className="CompactSavedAudiences__body">
                <Text weight="bold" size="xl" on="white">
                  {audience.name}
                </Text>
                <Text size="l" on="white">
                  {audience.type}
                </Text>
                <Tags tags={audience.tags} />
              </div>
              <div className="CompactSavedAudiences__description">
                {audience._id} - This is a audience ID to talk about
              </div>
              <div className="CompactSavedAudiences__prospects">
                <div style={{ paddingRight: '1rem' }}>
                  <FontAwesomeIcon icon={faWalking} size="2x" />{' '}
                </div>
                <div className="CompactSavedAudiences__prospects-text">
                  <div>{audience.prospects}</div>
                  <div>prospects</div>
                </div>
              </div>
            </div>
            <Spacer />
          </div>
        )
      })}
    </div>
  )
}
