import React, { useState } from 'react'
import './CampaignCheckout.scss'
import { Button, MaxHeightContainer, Spacer } from '@eltoro-ui/components'
import { PageHeader, SummaryCart } from 'Components'
import { fixtureAudiences } from 'Requests/Fixtures'
import { browserLog } from 'Tools'
import {
  CampaignCreationWrapper,
  CampaignCreationFooter,
  ImpressionSelector,
  DateRangeSelector,
} from '..'

// sample campaign to give to summary cart
const campaign = {
  prospects: fixtureAudiences.map((audience) => ({
    name: audience.name,
    prospects: audience.prospects,
  })),
  creatives: {
    // video: [{ name: 'string', cost: 456, impressions: 768, households: 456 }],
    // banner: [
    //   { name: 'string', cost: 456, impressions: 768, households: 456 },
    // ],
    // directMail: {
    //   name: 'string',
    //   cost: 456,
    //   impressions: 768,
    //   households: 456,
    // },
  },
  frequency: 0,
}

export const CampaignCheckout = () => {
  // This should be the full campaign but no good standard for it yet
  const [campaignFrequency, setCampaignFrequency] = useState<
    number | undefined
  >(campaign.frequency)

  const now = new Date()
  const [startDate, setStartDate] = useState(
    new Date(now.getFullYear(), now.getMonth(), now.getDate()),
  )

  const [endDate, setEndDate] = useState(
    new Date(now.getFullYear(), now.getMonth(), now.getDate() + 14),
  )

  return (
    <div className="CampaignCheckout">
      <MaxHeightContainer
        fullHeight
        header={<PageHeader title="Create Campaign" subTitle="Checkout" />}
        footer={
          <CampaignCreationFooter>
            <Button size="l" onClick={() => browserLog.info('back')}>
              Back
            </Button>
            <Spacer />
            <Button size="l" kind="primary" onClick={() => {}}>
              Next
            </Button>
          </CampaignCreationFooter>
        }
      >
        <CampaignCreationWrapper>
          <div className="CampaignCheckout__content">
            <DateRangeSelector
              startDate={startDate}
              endDate={endDate}
              onStartDateChange={setStartDate}
              onEndDateChange={setEndDate}
              message={(duration) =>
                `Your ads will display for these ${duration} days`
              }
            />
            <ImpressionSelector
              frequency={campaignFrequency}
              onFrequencyChange={setCampaignFrequency}
            />
          </div>
          <SummaryCart campaign={campaign} />
        </CampaignCreationWrapper>
      </MaxHeightContainer>
    </div>
  )
}
