import React, { FC } from 'react'
import { Text, Select, SelectOptionType } from '@eltoro-ui/components'
import './ImpressionSelector.scss'

type ImpressionSelectorType = {
  frequency?: number
  onFrequencyChange: (frequency?: number) => void
}

export const ImpressionSelector: FC<ImpressionSelectorType> = ({
  frequency,
  onFrequencyChange,
}) => {
  const impressionFrequency = frequency
    ? { label: `${frequency}x per day`, value: frequency.toString() }
    : undefined

  const handleOnChange = (freq?: SelectOptionType) => {
    const value = freq ? Number(freq.value) : undefined
    onFrequencyChange(value)
  }

  return (
    <div className="ImpressionSelector">
      <Text on="white" size="xxl" weight="bold">
        Select Your Impression Package
      </Text>
      {frequency ? (
        <Text on="white" kind="subdued" size="s" tag="div">
          Your ads will be served <b>{frequency} times per day</b> to each
          household
        </Text>
      ) : (
        <Text on="white" kind="subdued" size="s" tag="div">
          Choose the number of impressions to be seen in each household per day
        </Text>
      )}
      <div className="ImpressionSelector__selection">
        <Select
          placeholder="Select frequency"
          options={[
            { label: '2x per day', value: '2' },
            { label: '3x per day', value: '3' },
            { label: '5x per day', value: '5' },
          ]}
          selectedOption={impressionFrequency}
          onChange={handleOnChange}
          maxHeight={200}
        />
      </div>
    </div>
  )
}
