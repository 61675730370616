import React from 'react'
import logo from '../../Images/Icon_color@2x.png'
import './Splash.scss'

export const Splash = () => {
  return (
    <div className="Splash">
      <div className="Splash__logo">
        <img src={logo} alt="Beewo Logo" />
      </div>
    </div>
  )
}
