import React, { Fragment } from 'react'
import './ProgressBar.scss'

export const ProgressBar = ({
  totalSteps,
  activeStep,
}: {
  totalSteps: number
  activeStep: number
}) => {
  let i = 0
  const steps = []
  while (i < totalSteps) {
    steps.push(
      <Fragment key={i}>
        {i > 0 && (
          <div
            className={`ProgressBar__line ${
              activeStep > i ? 'ProgressBar__line--is-filled' : ''
            }`}
          />
        )}
        <div
          className={`ProgressBar__step ${
            activeStep > i ? 'ProgressBar__step--is-filled' : ''
          }`}
        />
      </Fragment>,
    )
    i += 1
  }

  return (
    <div className="ProgressBar">
      <div className="ProgressBar__steps">{steps}</div>
    </div>
  )
}
