import { useState } from 'react'
import { MapLocationType } from 'types'

export const useLocations = () => {
  const [locations, setLocations] = useState<MapLocationType[]>([])

  const addLocation = (suggestedLocation: MapLocationType) => {
    setLocations((prevLocations) => {
      const newLocations = prevLocations.some(
        (location) => location.raw.place_id === suggestedLocation.raw.place_id,
      )
        ? prevLocations
        : [...prevLocations, suggestedLocation]

      return newLocations
    })
  }

  const removeLocation = (id: number) => {
    setLocations((prevLocations) =>
      prevLocations.filter((location) => location.raw.place_id !== id),
    )
  }

  const hasLocations = locations?.length > 0

  return {
    locations,
    addLocation,
    removeLocation,
    hasLocations,
  }
}
