import React from 'react'
import { Text, Spacer, Button } from '@eltoro-ui/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/pro-solid-svg-icons'
import { CreditBundleType, CreditBundleCard } from '..'
import './SelectBundle.scss'

export const SelectBundle: React.FC<{
  bundles: CreditBundleType[]
  pointsNeeded?: number
  onChange: (bundle: CreditBundleType) => void
}> = ({ bundles, pointsNeeded, onChange }) => {
  return (
    <div className="SelectBundle">
      {pointsNeeded && (
        <>
          <Text on="white" kind="warning" tag="div">
            You don&apos;t have enough points to buy this, set your own price or
            buy more points.
          </Text>
          <Spacer />
          <div className="SelectBundle__points-display">
            You need{' '}
            <FontAwesomeIcon icon={faStar} className="SelectBundle__star" />{' '}
            <Text on="white" weight="bold">
              {pointsNeeded.toLocaleString()}
            </Text>
          </div>
        </>
      )}
      <Spacer />
      <div className="SelectBundle__bundle-list">
        {bundles.map((bundle) => (
          <CreditBundleCard
            key={bundle.name}
            bundle={bundle}
            onClick={() => onChange(bundle)}
          />
        ))}
      </div>
      <Spacer />
      {pointsNeeded && <Button kind="text">Set Your Price</Button>}
    </div>
  )
}
