import React, { useState } from 'react'
import { PageHeader, TableSelectionWrapper } from 'Components'
import { Button, Table, Text, TextInput } from '@eltoro-ui/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faSearch, faWalking } from '@fortawesome/pro-solid-svg-icons'
import { AudienceType } from 'types'
import { fixtureAudiences } from 'Requests/Fixtures'
import { getLocalDateTime, getTimePassed } from 'Helpers'
import { Tags } from './components'
import './SavedAudiences.scss'

export const SavedAudiences = () => {
  const [selections, setSelections] = useState<AudienceType[]>([])
  const [search, setSearch] = useState('')

  return (
    <div className="SavedAudiences">
      <PageHeader
        title="Saved Audience"
        subTitle="Last Updated 11/04/20 - 1:45 PM"
        actions={[
          <TextInput
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            icon={<FontAwesomeIcon icon={faSearch} />}
          />,
          <Button
            kind="primary"
            to="/create-campaign/setup"
            iconLeft={<FontAwesomeIcon icon={faPlus} />}
          >
            New Campaign
          </Button>,
        ]}
      />
      <TableSelectionWrapper
        selectionType="audience"
        selections={selections}
        actions={[
          <button type="button" className="SavedAudiences__select-action">
            <Text on="tertiary-300" weight="bold">
              New Campaign
            </Text>
          </button>,
          <button type="button" className="SavedAudiences__select-action">
            <Text on="tertiary-300" weight="bold">
              Archive
            </Text>
          </button>,
          <button type="button" className="SavedAudiences__select-action">
            <Text on="tertiary-300" weight="bold">
              Delete
            </Text>
          </button>,
        ]}
      >
        <Table
          rows={fixtureAudiences}
          columns={[
            { path: 'name', label: 'Audience Name', width: '20%' },
            {
              path: 'prospects',
              label: 'Prospects',
              RowCell: (audience) => (
                <Text on="white" size="l" weight="bold">
                  <FontAwesomeIcon icon={faWalking} /> {audience.prospects}
                </Text>
              ),
            },
            {
              path: 'type',
              label: 'Source',
              RowCell: (audience) => <div>{audience.type}</div>,
            },
            {
              path: 'tags',
              label: 'Tags',
              RowCell: (audience) => {
                return <Tags tags={audience.tags} />
              },
            },
            {
              path: 'date_captured',
              label: 'Date Captured',
              RowCell: (audience) => getLocalDateTime(audience.date_captured),
              alignment: 'right',
            },
            {
              path: 'last_updated',
              label: 'Last Updated',
              RowCell: (audience) => getTimePassed(audience.last_updated),
              alignment: 'right',
            },
            {
              path: '',
              RowCell: (audience) => (
                <Button to={`saved-audiences/${audience._id}`}>View</Button>
              ),
              alignment: 'right',
            },
          ]}
          onSelect={setSelections}
        />
      </TableSelectionWrapper>
    </div>
  )
}
