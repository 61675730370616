import React, { useState } from 'react'
import './CampaignSetup.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers, faWalking } from '@fortawesome/pro-solid-svg-icons'
import {
  Button,
  MaxHeightContainer,
  Table,
  Text,
  TextInput,
} from '@eltoro-ui/components'
import { AudienceType } from 'types'
import { getLocalDateTime } from 'Helpers'
import { browserLog } from 'Tools'
import { fixtureAudiences } from 'Requests/Fixtures'
import { PageHeader, SummaryCart } from 'Components'
import { Tags } from 'Pages/SavedAudiences/components'
import { CampaignCreationFooter, CampaignCreationWrapper } from '..'

export const CampaignSetup = () => {
  const [campaignName, setCampaignName] = useState<string>('')

  const [selectedAudiences, setSelectedAudiences] = useState<AudienceType[]>([])

  // sample campaign to give to summary cart
  const campaign = {
    prospects: selectedAudiences.map((audience) => ({
      name: audience.name,
      prospects: audience.prospects,
    })),
    creatives: {
      // video: [{ name: 'string', cost: 456, impressions: 768, households: 456 }],
      // banner: [
      //   { name: 'string', cost: 456, impressions: 768, households: 456 },
      // ],
      // directMail: {
      //   name: 'string',
      //   cost: 456,
      //   impressions: 768,
      //   households: 456,
      // },
    },
  }

  return (
    <div className="CampaignSetup">
      <MaxHeightContainer
        fullHeight
        header={<PageHeader title="Create Campaign" subTitle="Setup" />}
        footer={
          <CampaignCreationFooter>
            <Button size="l" onClick={() => browserLog.info('back')}>
              Back
            </Button>
            <div className="CampaignSetup__next-button">
              <Button
                size="l"
                kind="primary"
                onClick={() =>
                  browserLog.info({
                    name: campaignName,
                    audiences: selectedAudiences,
                  })
                }
                disabled={campaignName.length < 3 || !selectedAudiences.length}
              >
                Next
              </Button>
            </div>
          </CampaignCreationFooter>
        }
      >
        <CampaignCreationWrapper>
          <div>
            <div className="CampaignSetup__name">
              <div>
                <Text on="white" size="xxl" weight="bold">
                  You&apos;re creating a new ad campaign
                </Text>
              </div>
              <div>
                <Text on="white" size="s">
                  Let&apos;s give your campaign a name
                </Text>
              </div>
              <div className="CampaignSetup__input-label">
                <Text on="white" weight="bold" size="s">
                  Campaign Name
                </Text>
              </div>
              <div className="CampaignSetup__name-input">
                <TextInput
                  size="l"
                  placeholder="Enter campaign name"
                  value={campaignName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setCampaignName(e.target.value)
                  }
                />
              </div>
            </div>
            <div className="CampaignSetup__audiences">
              <div>
                <Text on="white" size="xxl" weight="bold">
                  Choose your saved audiences
                </Text>
              </div>
              <div>
                <Text on="white" size="s">
                  Your campaign needs an audience, choose one or more audience
                  libraries
                </Text>
              </div>
              <div className="CampaignSetup__audience-list">
                {fixtureAudiences.length ? (
                  <Table
                    rows={fixtureAudiences}
                    columns={[
                      { path: 'name', label: 'Audience Name' },
                      {
                        path: 'prospects',
                        label: 'Prospects',
                        RowCell: (audience: AudienceType) => (
                          <Text on="white" size="l" weight="bold">
                            <FontAwesomeIcon icon={faWalking} />{' '}
                            {audience.prospects}
                          </Text>
                        ),
                      },
                      {
                        path: 'type',
                        label: 'Source',
                        RowCell: (audience: AudienceType) => (
                          <div>{audience.type}</div>
                        ),
                      },
                      {
                        path: 'tags',
                        label: 'Tags',
                        RowCell: (audience: AudienceType) => {
                          return <Tags tags={audience.tags} />
                        },
                      },
                      {
                        path: 'date_captured',
                        label: 'Date Captured',
                        RowCell: (audience: AudienceType) =>
                          getLocalDateTime(audience.date_captured),
                        alignment: 'right',
                      },
                    ]}
                    onSelect={(audienceList: AudienceType[]) =>
                      setSelectedAudiences(audienceList)
                    }
                  />
                ) : (
                  <div className="CampaignSetup__empty">
                    <FontAwesomeIcon size="4x" icon={faUsers} />
                    <div>
                      <Text on="grey-050" weight="bold">
                        You don&apos;t have a saved audience.
                      </Text>
                    </div>
                    <div>
                      <Text on="grey-050" size="s">
                        Use the prospect activity tool or upload your own
                        audience to get started.
                      </Text>
                    </div>
                    <Button to="/prospects" kind="primary">
                      Use prospect finder
                    </Button>
                    <div>
                      {/* Just plain text until we get upload functionality */}
                      <Text on="grey-050" size="s">
                        Or upload excel or CSV
                      </Text>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <SummaryCart campaign={campaign} />
        </CampaignCreationWrapper>
      </MaxHeightContainer>
    </div>
  )
}
