export type FakeCreativeType = {
  name: string
  cost: number
  impressions?: number
  clicks?: number
}

export const fakeCreatives: FakeCreativeType[] = [
  {
    name: 'Creative 1',
    cost: 123,
  },
  {
    name: 'Creative 2',
    cost: 123,
  },
  {
    name: 'Creative 3',
    cost: 123,
  },
  {
    name: 'Creative 4',
    cost: 123,
  },
  {
    name: 'Creative 5',
    cost: 123,
  },
  {
    name: 'Creative 6',
    cost: 123,
  },
]
