import React, { FC, useState } from 'react'
import { UserType } from 'types'
import { browserLog } from 'Tools'
import {
  Form,
  FormItem,
  Button,
  TextInput,
  FlexBox,
} from '@eltoro-ui/components'
import { useForm } from '@eltoro-ui/hooks'
import { Section } from 'Components'
import { ManageAvatar } from '..'
import './PersonalInfoSection.scss'

type PersonalInfoSectionType = {
  user: UserType
}

export const PersonalInfoSection: FC<PersonalInfoSectionType> = ({ user }) => {
  const [email, setEmail] = useState(user.email)
  const [firstName, setFirstName] = useState(user.firstName)
  const [lastName, setLastName] = useState(user.lastName)
  const { formData, isValidForm, touched } = useForm({
    firstName,
    lastName,
    email,
  })

  const required = ['firstName', 'lastName', 'email']
  const { validForm, missingFields } = isValidForm([...required])

  const onSubmit = () => {
    browserLog.log('on submit')
    browserLog.log(formData)
  }

  return (
    <Section title="Personal Info" noBackground>
      <FlexBox
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap-reverse"
      >
        <div className="PersonalInfoSection">
          <Form
            onSubmit={onSubmit}
            required={required}
            valid={validForm}
            touched={touched}
            missingFields={missingFields}
          >
            <FormItem
              htmlFor="firstName"
              label="First name"
              errorMessage="Please enter a first name"
              valid={!!firstName}
            >
              <TextInput
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </FormItem>
            <FormItem
              htmlFor="lastName"
              label="Last name"
              errorMessage="Please enter a last name"
              valid={!!lastName}
            >
              <TextInput
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </FormItem>
            <FormItem
              htmlFor="email"
              label="Email"
              errorMessage="Please enter a valid email"
              valid={email.length > 6}
            >
              <TextInput
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormItem>
            <Button
              type="submit"
              kind="primary"
              disabled={
                email === user.email &&
                firstName === user.firstName &&
                lastName === user.lastName
              }
            >
              Save changes
            </Button>
          </Form>
        </div>
        <div className="PersonalInfoSection__avatar">
          <ManageAvatar user={user} />
        </div>
      </FlexBox>
    </Section>
  )
}
