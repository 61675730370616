import React, { useEffect, useState } from 'react'
import { Button, MaxHeightContainer, Spacer } from '@eltoro-ui/components'
import { faWalking } from '@fortawesome/pro-solid-svg-icons'
import { faFileImport, faTrash } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  AudienceResultsCard,
  AudienceResultsCount,
  ListingsList,
  LocationLabels,
  MapMarker,
  SaveAudienceWizard,
} from 'Pages/ProspectActivity/components'
/* import { mockLocation } from 'Requests/Fixtures/listings' */
import { browserLog } from 'Tools'
import { Map, MapPopup, MapSearch, MapToolTip } from 'Components'
import { APIFeatureType } from 'types'
import { useLocations } from 'Hooks'
import { getIconBuzzColor } from 'Helpers'
import './DigitalCanvassingLens.scss'
import {
  LensSectionHeading,
  LensSidebar,
  LensSidebarFooter,
  LensSidebarHeader,
} from '../components'

export const DigitalCanvassingLens = () => {
  const [features, setFeatures] = useState<APIFeatureType[]>([])
  const [listings, setListings] = useState<APIFeatureType[]>()
  const [selectedListings, setSelectedListings] = useState<APIFeatureType[]>([])
  const {
    locations,
    removeLocation,
    addLocation,
    hasLocations,
  } = useLocations()

  const addListings = () => {
    /* getMapListings(mockLocation).then((res: APIFeatureType[]) => {
     *   setListings(res)
     *   setSelectedListings(res.slice(0, 4))
     * }) */
  }

  useEffect(() => {
    if (hasLocations) {
      /* getMapListings(locations).then((res: APIFeatureType[]) =>
       *   setFeatures(res),
       * ) */
      setFeatures([])
    } else {
      setFeatures([])
    }
  }, [hasLocations, locations])

  const totalTargets = selectedListings
    .concat(features)
    .reduce((total, listing) => total + listing.unique_observations, 0)

  return (
    <div className="DigitalCanvassingLens">
      <LensSidebar>
        <MaxHeightContainer
          fullHeight
          header={
            <LensSidebarHeader
              heading="Digital Canvassing"
              subHeading="Identify targets that reside around a specific area"
            />
          }
          footer={
            <LensSidebarFooter>
              {(selectedListings || locations) && (
                <AudienceResultsCard>
                  Potentially advertise to
                  <AudienceResultsCount faIcon={faWalking}>
                    {`${totalTargets.toLocaleString()} targets`}
                  </AudienceResultsCount>
                  seen around{' '}
                  <b>
                    {selectedListings.length} of your listing
                    {selectedListings.length > 1 ? 's' : ''}
                  </b>{' '}
                  <br />
                  and{' '}
                  <b>
                    {locations.length} other location
                    {locations.length > 1 ? 's' : ''}
                  </b>
                  <Spacer />
                  <SaveAudienceWizard
                    onSaveAndClose={(audienceDetails) =>
                      browserLog.info(audienceDetails)
                    }
                  />
                </AudienceResultsCard>
              )}
            </LensSidebarFooter>
          }
        >
          <div className="DigitalCanvassingLens__sidebar-content">
            <LensSectionHeading
              heading={`My Listings: ${
                listings
                  ? `${listings.length} (${selectedListings.length} selected)`
                  : ''
              }`}
            />
            {!!listings?.length && (
              <>
                <ListingsList
                  listings={listings}
                  selectedListings={selectedListings}
                  onSelectedListingsChange={setSelectedListings}
                />
              </>
            )}
            <div className="DigitalCanvassingLens__listings-button-wrapper">
              {listings?.length ? (
                <Button
                  kind="text"
                  iconLeft={<FontAwesomeIcon icon={faTrash} />}
                  onClick={() => {
                    setListings([])
                    setSelectedListings([])
                  }}
                >
                  Delete my listings (fake)
                </Button>
              ) : (
                <Button
                  kind="text"
                  iconLeft={<FontAwesomeIcon icon={faFileImport} />}
                  onClick={addListings}
                >
                  Import my listings (fake)
                </Button>
              )}
            </div>
            <LensSectionHeading heading="Other Locations" />
            <MapSearch onSelect={addLocation} />
            <Spacer />
            {hasLocations && (
              <LocationLabels locations={locations} onRemove={removeLocation} />
            )}
          </div>
        </MaxHeightContainer>
      </LensSidebar>
      <div className="DigitalCanvassingLens__map">
        <Map
          features={selectedListings}
          locations={[]}
          marker={(f) => <MapMarker color={getIconBuzzColor(f)} />}
          popup={(f) => <MapPopup feature={f} />}
          tooltip={(f) => <MapToolTip feature={f} />}
        />
      </div>
    </div>
  )
}
