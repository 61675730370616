import Keycloak from 'keycloak-js'

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const KeycloakConfig = Keycloak({
  url: 'https://hive.staging.beewo.com/auth',
  realm: 'beewo',
  clientId: 'portal-staging',
})

export default KeycloakConfig
