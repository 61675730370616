import React, { FC, useState } from 'react'
import { Select, Button, Modal, Spacer } from '@eltoro-ui/components'
import { PaymentProfileType } from 'types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons'
import { Section, AddCardForm } from 'Components'
import { browserLog } from 'Tools'
import { EditCardModal } from '..'
import './BillingSection.scss'

type SubscriptionType = {
  profiles: PaymentProfileType[]
}

export const BillingSection: FC<SubscriptionType> = ({ profiles }) => {
  const [currentProfile, setCurrentProfile] = useState<PaymentProfileType>()
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [addCardModalOpen, setAddCardModalOpen] = useState(false)

  const profileNames = profiles.map((profile) => {
    return { label: profile.cc_details.nick_name }
  })

  return (
    <Section title="Billing" noBackground>
      <div className="BillingSection">
        <div className="BillingSection__cards">
          <div className="BillingSection__select">
            <Select
              placeholder="Card on file"
              options={profileNames}
              selectedOption={{
                label: currentProfile?.cc_details?.nick_name || 'Card on file',
              }}
              onChange={(option) => {
                const profile = profiles.find(
                  (name) => name?.cc_details?.nick_name === option?.label,
                )
                setCurrentProfile(profile)
              }}
              maxHeight={200}
            />
          </div>
          <div className="BillingSection__buttons">
            <Button
              onClick={() => setEditModalOpen(true)}
              disabled={currentProfile === undefined}
            >
              View / Edit card
            </Button>
            <Spacer />
            <Button kind="primary" onClick={() => setAddCardModalOpen(true)}>
              Add new card
            </Button>
          </div>
        </div>
        {currentProfile && (
          <>
            <h3>Transactions</h3>
            <div className="BillingSection__transactions">
              <FontAwesomeIcon icon={faCreditCard} size="3x" />
              <p>No transactions for {currentProfile?.cc_details?.nick_name}</p>
            </div>
          </>
        )}
        {currentProfile && editModalOpen && (
          <EditCardModal
            offClick={() => setEditModalOpen(false)}
            profile={currentProfile}
          />
        )}
        {addCardModalOpen && (
          <Modal
            header="Add new card"
            offClick={() => setAddCardModalOpen(false)}
            className="BillingSection__add-card"
          >
            <AddCardForm onSave={(ccInfo) => browserLog.info(ccInfo)} />
          </Modal>
        )}
      </div>
    </Section>
  )
}
