import React from 'react'
import { FlexBox, Text } from '@eltoro-ui/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons'
import { faStar } from '@fortawesome/free-solid-svg-icons'

export const SummaryCreative: React.FC<{
  icon: IconDefinition
  cost: number
}> = ({ icon, cost, children }) => {
  return (
    <>
      <FlexBox alignItems="center" justifyContent="center">
        <Text on="grey-050" size="xxl">
          <FontAwesomeIcon icon={icon} />
        </Text>
      </FlexBox>
      <FlexBox flexDirection="column">{children}</FlexBox>
      <Text on="grey-050">
        <FontAwesomeIcon icon={faStar} /> {cost.toLocaleString()}
      </Text>
    </>
  )
}
