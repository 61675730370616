import React, { FC } from 'react'
import { PageNav, PageRoutes, FlexBox } from '@eltoro-ui/components'
import { BrowserRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { fixturePaymentProfiles, fixtureUsers } from 'Requests/Fixtures'
import {
  TabWrapper,
  BillingSection,
  SubscriptionSection,
  PersonalInfoSection,
  RealEstateInfoSection,
} from './components'
import './MyProfilePage.scss'

export const MyProfilePage: FC = () => {
  const profilePages = [
    {
      path: '/profile/',
      title: 'General Info',
      exactPath: true,
      component: () => (
        <TabWrapper>
          <PersonalInfoSection user={fixtureUsers[1]} />
          <RealEstateInfoSection user={fixtureUsers[1]} />
        </TabWrapper>
      ),
    },
    {
      path: '/profile/billing',
      title: 'Billing',
      exactPath: false,
      component: () => (
        <TabWrapper>
          <SubscriptionSection />
          <BillingSection profiles={fixturePaymentProfiles} />
        </TabWrapper>
      ),
    },
    {
      path: '/profile/goals',
      title: 'Goals',
      exactPath: false,
      component: () => (
        <TabWrapper>
          <FlexBox justifyContent="center">
            <FontAwesomeIcon icon={faLock} size="2x" />
          </FlexBox>
        </TabWrapper>
      ),
    },
    {
      path: '/profile/notifications',
      title: 'Notification Settings',
      exactPath: false,
      component: () => (
        <TabWrapper>
          <FlexBox justifyContent="center">
            <FontAwesomeIcon icon={faLock} size="2x" />
          </FlexBox>
        </TabWrapper>
      ),
    },
  ]
  return (
    <div className="MyProfilePage">
      <BrowserRouter>
        <nav className="MyProfilePage__nav">
          <h1 className="MyProfilePage__header">Account Settings</h1>
          <PageNav pages={profilePages} />
        </nav>

        <main className="MyProfilePage__main">
          <PageRoutes pages={profilePages} />
        </main>
      </BrowserRouter>
    </div>
  )
}
