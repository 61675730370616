import React, { useState } from 'react'
import { Button, MaxHeightContainer, Spacer } from '@eltoro-ui/components'
import { faWalking } from '@fortawesome/pro-solid-svg-icons'
import { faFileImport, faTrash } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  AudienceResultsCard,
  AudienceResultsCount,
  ListingsList,
  MapMarker,
  SaveAudienceWizard,
} from 'Pages/ProspectActivity/components'
/* import { mockLocation } from 'Requests/Fixtures/listings'
 * import { getMapListings } from 'Requests/Live/listings' */
import { browserLog } from 'Tools'
import { Map, MapPopup, MapToolTip } from 'Components'
import { APIFeatureType } from 'types'
import './MyListingsLens.scss'
import { getIconBuzzColor } from 'Helpers'
import {
  LensSectionHeading,
  LensSidebar,
  LensSidebarFooter,
  LensSidebarHeader,
} from '../components'

export const MyListingsLens = () => {
  const [listings, setListings] = useState<APIFeatureType[]>()
  const [selectedListings, setSelectedListings] = useState<APIFeatureType[]>([])

  const addListings = () => {
    /* getMapListings(mockLocation).then((res: APIFeatureType[]) => {
     *   setListings(res)
     *   setSelectedListings(res.slice(0, 4))
     * }) */
  }

  const totalProspects = selectedListings.reduce(
    (total, listing) => total + listing.unique_observations,
    0,
  )

  return (
    <div className="MyListingsLens">
      <LensSidebar>
        <MaxHeightContainer
          fullHeight
          header={
            <LensSidebarHeader
              heading="My Listings"
              subHeading="View prospects that have been seen in one or all of your listings."
            />
          }
          footer={
            <LensSidebarFooter>
              <AudienceResultsCard>
                Potentially advertise to
                <AudienceResultsCount faIcon={faWalking}>
                  {`${totalProspects.toLocaleString()} prospects`}
                </AudienceResultsCount>
                seen around <b>{selectedListings.length}</b> listings
                <Spacer />
                <SaveAudienceWizard
                  onSaveAndClose={(audienceDetails) =>
                    browserLog.info(audienceDetails)
                  }
                />
              </AudienceResultsCard>
            </LensSidebarFooter>
          }
        >
          <div className="MyListingsLens__sidebar-content">
            {!!listings?.length && (
              <>
                <LensSectionHeading
                  heading={`Listings: ${listings.length} (${selectedListings.length} selected)`}
                />
                <ListingsList
                  listings={listings}
                  selectedListings={selectedListings}
                  onSelectedListingsChange={setSelectedListings}
                />
              </>
            )}
            <div className="MyListingsLens__button-wrapper">
              {listings?.length ? (
                <Button
                  kind="text"
                  iconLeft={<FontAwesomeIcon icon={faTrash} />}
                  onClick={() => {
                    setListings([])
                    setSelectedListings([])
                  }}
                >
                  Delete listings (fake)
                </Button>
              ) : (
                <Button
                  kind="text"
                  iconLeft={<FontAwesomeIcon icon={faFileImport} />}
                  onClick={addListings}
                >
                  Import listings (fake)
                </Button>
              )}
            </div>
          </div>
        </MaxHeightContainer>
      </LensSidebar>
      <div className="MyListingsLens__map">
        <Map
          features={selectedListings}
          locations={[]}
          marker={(f) => <MapMarker color={getIconBuzzColor(f)} />}
          popup={(f) => <MapPopup feature={f} />}
          tooltip={(f) => <MapToolTip feature={f} />}
        />
      </div>
    </div>
  )
}
